import Vue from 'vue'
import Vuex from 'vuex'

import Moment from 'moment-timezone'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'en',
    languages: {},
    translation: {},
    user: {
      data: {
        merchant: {
          currency: 'USD',
          currency_sign: '$'
        },
        games_favourite: []
      }
    },
    promo_statuses: [],
    promo_templates: [],
  },
  mutations: {
    setLang(state, value) {
      state.lang = value
    },
    setLanguages(state, value) {
      state.languages = value
    },
    setTranslation(state, value) {
    	state.translation = value
    },
    setUser(state, data) {
      if(data && 'auth' in data) {
        if('data' in data) {
          data.data.balance = parseFloat(data.data.balance)
          data.data.balance_bonus = parseFloat(data.data.balance_bonus)


          /** jivo data */

          var jivo_content = [{
            content: `Email: ${data.data.email}`
          }]

          if (('payments' in data.data) && data.data.payments.length) {
            
            var deposits = 'Депозиты: '

            for (let item of data.data.payments) {
              deposits += `${parseFloat(item.amount).toFixed(2)} ${Moment(item.created_at).format('Y-MM-DD HH:mm:ss')};`
            }

            jivo_content.push({
              content: deposits
            })
          }


          if (('withdrawal' in data.data) && data.data.withdrawal.length) {
            
            var withdrawal = 'Выплаты: '
            var withdrawal_canceled = 'Отмененные Выплаты: '

            for (let item of data.data.withdrawal) {
              if (item.status == 3) {
                withdrawal_canceled += `${parseFloat(item.amount).toFixed(2)} ${Moment(item.created_at).format('Y-MM-DD HH:mm:ss')};`
              } else {
                withdrawal += `${parseFloat(item.amount).toFixed(2)} ${Moment(item.created_at).format('Y-MM-DD HH:mm:ss')};`
              }
            }

            jivo_content.push({
              content: withdrawal
            })
            
            jivo_content.push({
              content: withdrawal_canceled
            })
          }

          var jivo_check = setInterval(function () {
            if (typeof jivo_api !== 'undefined') {
              clearInterval(jivo_check)
              
              jivo_api.setContactInfo({
                  email: data.data.email,
                  //description: jivo_data
              })

              jivo_api.setCustomData(jivo_content)
            }
          }, 1000)

        }
        state.user = data

      } else {
        state.user = {}
      }
    },
    setPromoStatuses(state, data) {
      state.promo_statuses = data
    },
    setPromoTemplates(state, data) {
      state.promo_templates = data
    }
  }
})