<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>

            <main>
                <cards-sidebar/>

                <section class="section section--game">
                    
                    <div class="section__content">
                        <div class="section__game">
                            
                            <div class="game-iframe__header">
                                <h2>{{ game.name }}</h2>
                                <div class="game-iframe__icon-wrapper">
                                    <svg 
                                        @click="fullscreen"
                                        class="game-iframe__header-icon" 
                                        width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512; fill: #fff" xml:space="preserve">
                                        <g>
                                            <path d="M369.8,56.9h85.3v85.3c0,15.7,12.7,28.4,28.4,28.4s28.4-12.7,28.4-28.4V28.4c0-7.5-3-14.8-8.3-20.1S491,0,483.6,0H369.8
                                                c-15.7,0-28.4,12.7-28.4,28.4C341.3,44.2,354.1,56.9,369.8,56.9z"/>
                                            <path d="M333,219.2L503.7,48.6c11.1-11.1,11.1-29.1,0-40.2s-29.1-11.1-40.2,0L292.8,179c-11.1,11.1-11.1,29.1,0,40.2
                                                C303.9,230.3,321.9,230.3,333,219.2"/>
                                            <path d="M142.2,455.1H56.9v-85.3c0-15.7-12.7-28.4-28.4-28.4S0,354.1,0,369.8v113.8c0,7.5,3,14.8,8.3,20.1
                                                C13.6,509,21,512,28.4,512h113.8c15.7,0,28.4-12.7,28.4-28.4C170.7,467.8,157.9,455.1,142.2,455.1z"/>
                                            <path d="M48.6,503.7L219.2,333c11.1-11.1,11.1-29.1,0-40.2c-11.1-11.1-29.1-11.1-40.2,0L8.3,463.4c-11.1,11.1-11.1,29.1,0,40.2
                                                C19.4,514.8,37.4,514.8,48.6,503.7"/>
                                            <path d="M369.8,512h113.8c7.5,0,14.8-3,20.1-8.3c5.3-5.3,8.3-12.6,8.3-20.1V369.8c0-15.7-12.7-28.4-28.4-28.4s-28.4,12.7-28.4,28.4
                                                v85.3h-85.3c-15.7,0-28.4,12.7-28.4,28.4C341.3,499.3,354.1,512,369.8,512z"/>
                                            <path d="M292.8,333l170.7,170.7c11.1,11.1,29.1,11.1,40.2,0c11.1-11.1,11.1-29.1,0-40.2L333,292.8c-11.1-11.1-29.1-11.1-40.2,0
                                                C281.7,303.9,281.7,321.9,292.8,333"/>
                                            <path d="M142.2,0H28.4C21,0,13.6,3,8.3,8.3C3,13.6,0,21,0,28.4v113.8c0,15.7,12.7,28.4,28.4,28.4s28.4-12.7,28.4-28.4V56.9h85.3
                                                c15.7,0,28.4-12.7,28.4-28.4C170.7,12.7,157.9,0,142.2,0z"/>
                                            <path d="M8.3,48.6L179,219.2c11.1,11.1,29.1,11.1,40.2,0s11.1-29.1,0-40.2L48.6,8.3C37.4-2.8,19.4-2.8,8.3,8.3
                                                C-2.8,19.4-2.8,37.4,8.3,48.6"/>
                                        </g>
                                    </svg>
                                    <svg 
                                        @click="closeGame"
                                        class="game-iframe__header-icon" 
                                        xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 60 60">
                                        <path fill="currentColor" fill-rule="evenodd" d="M54.7 5.3a3.5 3.5 0 0 0-5 0L30 25.1 10.2 5.3a3.5 3.5 0 1 0-4.9 5L25.1 30 5.3 49.8a3.5 3.5 0 0 0 5 4.9L30 34.9l19.8 19.8a3.5 3.5 0 1 0 4.9-5L34.9 30l19.8-19.8a3.5 3.5 0 0 0 0-4.9Z"></path>
                                    </svg>
                                </div>
                            </div>

                            <iframe ref="game_frame" class="game_frame" id="game_frame"></iframe>

                        </div>

                        <h3 class="section__title-2">{{ $store.state.translation.game.games_enjoy }}</h3>

                        <div class="section__cards-list">
                            <article class="card card--game" v-for="game in games">
                                
                                <img :src="`${$config.ApplicationStaticURL}/icos/${game.image}`" :alt="game.name" class="card__image" />

                                <div class="card__content">
                                    <header class="card__header">
                                        <svg 
                                            v-if="'auth' in $store.state.user && $store.state.user.auth"
                                            :class="{'active': $store.state.user.data.games_favourite.includes(game.id)}"
                                            @click="favourite(game.id)"
                                            width="21" 
                                            height="21" 
                                            viewBox="0 0 21 21" 
                                            fill="none" 
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20.8126 6.92259C20.7275 6.74611 20.5945 6.59717 20.4287 6.49289C20.2628 6.3886 20.071 6.33319 19.8751 6.33301H14.2678L11.424 0.658008C11.3374 0.485202 11.2045 0.339897 11.04 0.238348C10.8755 0.1368 10.6861 0.0830143 10.4928 0.0830078C10.2994 0.083401 10.11 0.137589 9.94575 0.239508C9.78147 0.341427 9.64879 0.487054 9.56257 0.660091L6.73027 6.33301H1.12507C0.928783 6.33293 0.736474 6.38832 0.570297 6.49278C0.404121 6.59724 0.27084 6.74653 0.185814 6.92344C0.100788 7.10035 0.0674761 7.29769 0.0897173 7.49271C0.111958 7.68773 0.188848 7.87249 0.311525 8.02572L4.14173 12.8174L2.20632 19.5882C2.14857 19.7879 2.15191 20.0004 2.21591 20.1982C2.2799 20.396 2.40163 20.5701 2.56542 20.6982C2.72921 20.8262 2.92757 20.9023 3.13497 20.9167C3.34238 20.9311 3.54934 20.8831 3.72923 20.7788L10.5001 16.907L17.2709 20.7788C17.4292 20.8701 17.609 20.9176 17.7917 20.9163C17.9529 20.9164 18.1119 20.879 18.2562 20.8072C18.4005 20.7354 18.5262 20.631 18.6233 20.5024C18.7204 20.3737 18.7864 20.2243 18.816 20.0659C18.8456 19.9074 18.838 19.7443 18.7938 19.5893L16.8584 12.8184L20.6886 8.02676C20.8114 7.87317 20.8882 7.68797 20.9101 7.49258C20.932 7.29719 20.8982 7.09958 20.8126 6.92259Z"
                                                fill="#17483B"
                                            />
                                        </svg>
                                        <div class="card__header-text">
                                            <h3 class="card__title">{{ game.name }}</h3>
                                            <span class="card__category">{{ game.provider_name }}</span>
                                        </div>
                                    </header>

                                    <footer class="card__footer">
                                        <template v-if="'auth' in $store.state.user && $store.state.user.auth">
                                            <router-link class="btn btn--purple card__btn" :to="{name: 'Game', params: {slug: game.slug}}">
                                                {{ $store.state.translation.game.play }}
                                            </router-link>
                                        </template>
                                        <template v-else>
                                            <button type="button" class="btn btn--purple card__btn" @click="togglePopup('login')">
                                                {{ $store.state.translation.game.play }}
                                            </button>
                                        </template>
                                        
                                        <template v-if="'auth' in $store.state.user && $store.state.user.auth">
                                            <router-link class="btn btn--outline-white card__btn" :to="{name: 'GameDemo', params: {slug: game.slug}}">
                                                {{ $store.state.translation.game.demo }}
                                            </router-link>
                                        </template>
                                        <template v-else>
                                            <button class="btn btn--outline-white card__btn" @click="togglePopup('login')">
                                                {{ $store.state.translation.game.demo }}
                                            </button>
                                        </template>
                                    </footer>
                                </div>
                            </article>
                        </div>

                        <button 
                            v-if="!nomore" 
                            :disabled="loader_button"
                            @click="getGames"
                            type="button" 
                            class="btn btn--fill-rose section__btn more_games">
                            <preloader v-if="loader_button"/>
                            <template v-if="loader_button">
                                {{ $store.state.translation.more.loading }}
                            </template>
                            <template v-else>
                                {{ $store.state.translation.game.more }}
                            </template>
                        </button>
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$sprintf(this.$store.state.translation.game.meta.title, this.game.name),
                meta: [
                    {
                        name: 'title', 
                        content: this.$sprintf(this.$store.state.translation.game.meta.title, this.game.name),
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.game.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$sprintf(this.$store.state.translation.game.meta.description, this.game.name)
                    }
                ]
            }
        },
        props: ['slug'],
        data() {
            return {
                loader_button: false,
                nomore: false,
                games: [],
                game: {
                    id: null,
                    name: null,
                    image: null
                },
                data: {
                    limit: 8,
                    offset: 0
                }
            }
        },
        methods: {
            async initGame () {
                this.appLoader('create', 100)
                await this.$axios.post(this.$config.ApplicationGamesAPI + (this.$route.name == 'Game' ? '/init' : '/init-demo'), JSON.stringify({
                    slug: this.$props.slug,
                    return_url: 'https://' + window.location.hostname,
                    language: this.$store.state.lang
                }), {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    withCredentials: false
                })
                .then((response) => {
                    this.appLoader('remove')
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            this.$router.push({name: 'Home'})
                            break
                        case 'success':

                            this.game = response.game

                            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                                window.location = response.url
                            } else {
                                this.$refs.game_frame.setAttribute('src', response.url)
                            }
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                            this.$router.push({name: 'Home'})
                    }
                }).catch((error) => {
                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.appLoader('remove')
                        this.$toast.error(response.message)
                        this.$router.push({name: 'Home'})
                    }
                })
            },
            getGames () {
                this.nomore = false
                this.loader_button = true
                this.$axios.post('/games/search', JSON.stringify({
                    type: 'search',
                    query: null,
                    provider: null,
                    not_games: [this.game.id],
                    limit: this.data.limit,
                    offset: this.data.offset
                }))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            break
                        case 'success':

                            if(response.data.length) {
                                this.games = this.games.concat(response.data)
                            } else {
                                this.games = []
                            }

                            this.data.offset ++

                            this.nomore = response.data.length < this.data.limit ? true : false

                            this.setUser(response.user)
                            
                            break
                        default:
                    }
                })
            },
            fullscreen () {
                var frame = document.querySelector('#game_frame')

                if (frame.requestFullscreen) {
                    frame.requestFullscreen()
                } else if (frame.webkitRequestFullscreen) {
                    frame.webkitRequestFullscreen()
                } else if (frame.msRequestFullscreen) {
                    frame.msRequestFullscreen()
                }
            },
            closeGame () {
                this.$router.push({name: 'Home'})
            }
        },
        async beforeMount () {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                window.addEventListener('popstate', function(event) {
                    window.location = '/'
                }, false)

                var perfEntries = performance.getEntriesByType('navigation')
                if (perfEntries.length && perfEntries[0].type === 'back_forward') {
                    window.location = '/'
                }
            }

            await this.initGame()
            this.getGames()
        },
        watch: {
            '$props.slug' (value, old) {
                this.$refs.game_frame.setAttribute('src', null)

                this.initGame()
            }
        }
    }
</script>
