import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from './events/Store'

import Http from './events/Http'
import Config from './events/Config'

import Common from './common'

import Home from './components/Home'
import Game from './components/Game'
import Vip from './components/Vip'
import Bonus from './components/Bonus'

import Tournaments from './components/tournaments/Tournaments'
import Tournament from './components/tournaments/Tournament'

import AccountProfile from './components/account/Profile'
import AccountVip from './components/account/Vip'
import AccountWallet from './components/account/Wallet'
import AccountBonus from './components/account/Bonus'
import AccountVerification from './components/account/Verification'
import AccountRestriction from './components/account/Restriction'

import About from './components/informations/About'
import TermsConditions from './components/informations/TermsConditions'
import PolicyKyc from './components/informations/PolicyKyc'
import PrivacyPolicy from './components/informations/PrivacyPolicy'
import CookiePolicy from './components/informations/CookiePolicy'
import RegistrationPayoutsBonuses from './components/informations/RegistrationPayoutsBonuses'
import HonestyGames from './components/informations/HonestyGames'
import ResponsibleGame from './components/informations/ResponsibleGame'
import SelfRestraint from './components/informations/SelfRestraint'
import DisputeResolution from './components/informations/DisputeResolution'
import AffiliateProgram from './components/informations/AffiliateProgram'
import Partners from './components/informations/Partners'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: '',
    linkExactActiveClass: '',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/play/:slug',
            name: 'Game',
            component: Game,
            props: true,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/play/demo/:slug',
            name: 'GameDemo',
            component: Game,
            props: true,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/vip',
            name: 'Vip',
            component: Vip,
            meta: {
                auth: 'guest'
            }
        },
        {
            path: '/bonus',
            name: 'Bonus',
            component: Bonus,
            meta: {
                auth: 'guest'
            }
        },
        {
            path: '/tournaments',
            name: 'Tournaments',
            component: Tournaments,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/tournament/:id',
            name: 'Tournament',
            component: Tournament,
            props: true,
            meta: {
                auth: 'all'
            }
        },

        {
            path: '/account/profile',
            name: 'AccountProfile',
            component: AccountProfile,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/account/vip',
            name: 'AccountVip',
            component: AccountVip,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/account/wallet/:page?',
            name: 'AccountWallet',
            component: AccountWallet,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/account/bonus/:page?',
            name: 'AccountBonus',
            component: AccountBonus,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/account/verification',
            name: 'AccountVerification',
            component: AccountVerification,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/account/restriction',
            name: 'AccountRestriction',
            component: AccountRestriction,
            meta: {
                auth: 'auth'
            }
        },

        {
            path: '/about',
            name: 'About',
            component: About,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/terms',
            name: 'TermsConditions',
            component: TermsConditions,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/policy-aml-kyc',
            name: 'PolicyKyc',
            component: PolicyKyc,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/privacy-policy',
            name: 'PrivacyPolicy',
            component: PrivacyPolicy,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/cookie-policy',
            name: 'CookiePolicy',
            component: CookiePolicy,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/registration-payouts-bonuses',
            name: 'RegistrationPayoutsBonuses',
            component: RegistrationPayoutsBonuses,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/honesty-games',
            name: 'HonestyGames',
            component: HonestyGames,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/responsible-game',
            name: 'ResponsibleGame',
            component: ResponsibleGame,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/self-restraint',
            name: 'SelfRestraint',
            component: SelfRestraint,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/dispute-resolution',
            name: 'DisputeResolution',
            component: DisputeResolution,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/affiliate-program',
            name: 'AffiliateProgram',
            component: AffiliateProgram,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/partners',
            name: 'Partners',
            component: Partners,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/:popup?',
            name: 'Home',
            component: Home,
            props: true,
            meta: {
                auth: 'all'
            }
        },
    ]
})

router.beforeEach(async (to, from, next) => {

    var user = Store.state.user

    if (to.query.lang) {
        localStorage.setItem('lang', to.query.lang)
        Store.commit('setLang', to.query.lang)
        Http.defaults.headers.common.Lang = to.query.lang
    }

    if(!('auth' in user)) {
        await Http.post(`${Config.ApplicationAPI}/auth/check`)
        .then((response) => {
            response = response.data
            switch (response.status) {
                case 'error':
                    console.log('check auth error')
                    break
                case 'success':

                    document.body.classList.remove('overflow-hidden')
                    Store.commit('setUser', response.user)
                    Store.commit('setLang', response.language.lang)
                    Store.commit('setLanguages', response.language.languages)
                    Store.commit('setTranslation', response.translation)

                    localStorage.setItem('lang', response.language.lang)
                    Http.defaults.headers.common.Lang = response.language.lang
                    
                    break
                default:
                    console.log('Unknown error')
            }
        }).catch((error) => {
            document.body.classList.remove('overflow-hidden')
        })

        user = Store.state.user


        /** Load Chat **/
        /*window.chaportConfig = {
            appId: '65031df16f9c6f073962c519',
            language: {
                use: localStorage.getItem('lang')
            },
            appearance: {
                windowColor: '#ff00e5',
                position: ['right', 20, 20],
                textStatuses: true
            }
        }

        if (user.auth) {
            window.chaportConfig.visitor = {
                name: user.data.personal.first_name && user.data.personal.last_name ? `${user.data.personal.first_name} ${user.data.personal.last_name}` : user.data.username,
                email: user.data.email,
                custom: {
                    status: user.data.rating.name,
                    balance: user.data.balance
                }
            }
        }

        if (window.innerWidth <= 1300) {
            window.chaportConfig.appearance.position = ['right', 20, 65]
        }

        if (!window.chaport) {
            var v3 = window.chaport = {}
            v3._q = []
            v3._l = {}

            v3.q = function(){
                v3._q.push(arguments)
            }

            v3.on = function(e,fn) {
                if(!v3._l[e])
                {
                    v3._l[e] = []
                    v3._l[e].push(fn)
                }
            }

            var s = document.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://app.chaport.com/javascripts/insert.js'

            var ss = document.getElementsByTagName('script')[0]
            ss.parentNode.insertBefore(s,ss)
        }*/
    }

    if((!('auth' in user) || !user.auth) && to.meta.auth == 'auth' && from.meta.auth != to.meta.auth) {
        router.push({name: 'Home', params: {popup: 'signup'}})
    } else if((('auth' in user) && user.auth) && to.meta.auth == 'guest' && from.meta.auth != to.meta.auth) {
        router.push({name: 'Home'})
    }

    next()

})


export default router