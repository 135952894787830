<template>
	<section class="section section--profile section--deposit section--withdraw">
        <h4 class="section__title">{{ $store.state.translation.wallet.withdraw_title }}</h4>

        <div class="section__content">
            <div class="progress_wallet">
                <div class="progress__item progress__item--paysys" :class="{'active': page >= 1}">
                    <div class="progress__item-bar"></div>
                    <span class="progress__item-text">
                        {{ $store.state.translation.wallet.withdraw.step1 }}
                    </span>
                    <span class="progress__item-text-sm">
                        {{ $store.state.translation.wallet.step }} 1
                    </span>
                </div>
                <div class="progress__item progress__item--amount" :class="{'active': page >= 2}">
                    <div class="progress__item-bar"></div>
                    <span class="progress__item-text">
                        {{ $store.state.translation.wallet.withdraw.step2 }}
                    </span>
                    <span class="progress__item-text-sm">
                        {{ $store.state.translation.wallet.step }} 2
                    </span>
                </div>
                <div class="progress__item progress__item--account" :class="{'active': page >= 3}">
                    <div class="progress__item-bar"></div>
                    <span class="progress__item-text">
                        {{ $store.state.translation.wallet.withdraw.step3 }}
                    </span>
                    <span class="progress__item-text-sm">
                        {{ $store.state.translation.wallet.step }} 3
                    </span>
                </div>
            </div>

            <section class="section section--wallet-paysys" :class="{'active': page == 1}">
                <h4 class="section__title">{{ $store.state.translation.wallet.withdraw.select_method }}</h4>

                <div class="section__content">
                    <div class="card card--paysys" v-for="item in paysystems" @click="page = 2, paysystem = item, maskWallet()">
                        <img :src="`${$config.ApplicationStaticURL}/paysys/${item.image}`" class="card__image" />
                    </div>
                </div>
            </section>

            <section class="section section--profile section--wallet-amount" :class="{'active': page == 2}">
                <h4 class="section__title">{{ $store.state.translation.wallet.withdraw.amount }}</h4>
                <div class="section__content">
                    <p class="section__text">{{ $store.state.translation.wallet.withdraw.insert_amount }}</p>
                    <div class="form form--wallet-amount">
                        <div class="form__group">
                            <label for="" class="form__label">
                                <span>{{ $store.state.translation.wallet.withdraw.sum_withdraw }}</span>
                                <div class="form__input-wrapper">
                                    <input
                                        v-model="amount"
                                        @input="errors.amount = false"
                                        :placeholder="parseFloat(paysystem.min_payment) * 2"
                                        class="form__input"/>
                                </div>
                                <span class="error_input" v-if="errors.amount">{{ errors.amount }}</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="section__btns">
                    <button type="button" class="btn btn--outline-rose btn-amount-back" @click="page = 1">
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.722183 9.27782C0.292606 8.84824 0.292606 8.15176 0.722183 7.72218L7.72254 0.721825C8.15212 0.292249 8.8486 0.292249 9.27817 0.721825C9.70775 1.1514 9.70775 1.84788 9.27817 2.27746L3.05564 8.5L9.27817 14.7225C9.70775 15.1521 9.70775 15.8486 9.27817 16.2782C8.8486 16.7078 8.15212 16.7078 7.72254 16.2782L0.722183 9.27782ZM21.5 9.6H1.5V7.4H21.5V9.6Z"
                                fill="#D3A229"
                            ></path>
                        </svg>

                        {{ $store.state.translation.wallet.back }}
                    </button>
                    <button type="button" class="btn btn--fill-rose btn-amount-next" @click="checkAmount">
                        {{ $store.state.translation.wallet.next }}
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                        </svg>
                    </button>
                </div>
            </section>

            <section class="section section--profile section--wallet-account" :class="{'active': page == 3}">
                <h4 class="section__title">{{ $store.state.translation.wallet.withdraw.select_wallet }}</h4>
                <div class="section__content">
                    <p class="section__text">
                        {{ $store.state.translation.wallet.withdraw.attention }}
                    </p>

                    <div class="form form--wallet-account">
                        <div class="form__group">
                            <label for="" class="form__label">
                                <span>{{ wallet_mask.text }}</span>
                                <div class="form__input-wrapper">
                                    <input
                                        v-model="wallet"
                                        @input="errors.wallet = false, inputCard()"
                                        @change="validateWallet"
                                        v-mask="wallet_mask.mask"
                                        :placeholder="wallet_mask.placeholder"
                                        class="form__input"/>
                                </div>
                                <span class="error_input" v-if="errors.wallet">{{ errors.wallet }}</span>
                            </label>

                            <label class="form__label" v-if="account_name_fields.includes(paysystem.slug)">
                                <span>{{ $store.state.translation.wallet.withdraw.account_name }}</span>
                                <div class="form__input-wrapper">
                                    <input
                                        v-model="account_name"
                                        @input="errors.account_name = false"
                                        class="form__input"/>
                                </div>
                                <span class="error_input" v-if="errors.account_name">{{ errors.account_name }}</span>
                            </label>

                            <label class="form__label" v-if="bank_code_fields.includes(paysystem.slug)">
                                <span>{{ $store.state.translation.wallet.withdraw.bank_code }}</span>
                                <div class="form__input-wrapper">
                                    <input
                                        v-model="bank_code"
                                        @input="errors.bank_code = false"
                                        class="form__input"/>
                                </div>
                                <span class="error_input" v-if="errors.bank_code">{{ errors.bank_code }}</span>
                            </label>

                            <ul class="section__list">
                                <li>
                                    <span class="section__list-caption">{{ $store.state.translation.wallet.withdraw.sum_withdraw }}:</span>
                                    <span class="section__list-value">{{ (parseFloat(amount)).toFixed(2) }} {{ $store.state.user.data.merchant.currency }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="section__btns">
                    <button type="button" class="btn btn--outline-rose btn-account-back" @click="page = 2">
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.722183 9.27782C0.292606 8.84824 0.292606 8.15176 0.722183 7.72218L7.72254 0.721825C8.15212 0.292249 8.8486 0.292249 9.27817 0.721825C9.70775 1.1514 9.70775 1.84788 9.27817 2.27746L3.05564 8.5L9.27817 14.7225C9.70775 15.1521 9.70775 15.8486 9.27817 16.2782C8.8486 16.7078 8.15212 16.7078 7.72254 16.2782L0.722183 9.27782ZM21.5 9.6H1.5V7.4H21.5V9.6Z"
                                fill="#D3A229"
                            ></path>
                        </svg>

                        {{ $store.state.translation.wallet.back }}
                    </button>
                    <button 
                        @click="createPayment"
                        :disabled="loader_button"
                        type="button" 
                        class="btn btn--fill-rose btn-account-next">
                        <preloader v-if="loader_button"/>
                        {{ $store.state.translation.wallet.next }}
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                        </svg>
                    </button>
                </div>
            </section>

            <section class="section section--profile section--wallet-success" :class="{'active': page == 4}">
                <h4 class="section__title">{{ $store.state.translation.wallet.withdraw.success }}</h4>

                <router-link class="btn btn--fill-rose" :to="{name: 'Home'}">{{ $store.state.translation.wallet.withdraw.to_home }}</router-link>
            </section>
        </div>
    </section>
</template>


<script>
    export default {
        data() {
            return {
                loader_button: false,
                page: 1,
                paysystems: [],
                paysystem: {
                    min_payment: 0
                },
                amount: null,
                wallet: null,
                account_name: null,
                bank_code: null,
                errors: {
                    amount: false,
                    wallet: false,
                    account_name: false,
                    bank_code: false
                },
                wallet_mask: {
                    mask: null,
                    placeholder: null,
                    text: null
                },
                account_name_fields: ['imps_ib'],
                bank_code_fields: ['imps_ib']
            }
        },
        methods: {
            createPayment () {
                if(!this.amount) {
                    this.errors.amount = this.$store.state.translation.axios.filed_required
                    return
                }

                if(this.amount < parseFloat(this.paysystem.min_payment)) {
                    this.errors.amount = this.$sprintf(this.$store.state.translation.wallet.withdraw.min_withdraw, this.paysystem.min_payment, this.$store.state.user.data.merchant.currency)
                    return
                }

                if(!this.wallet) {
                    this.errors.wallet = this.$store.state.translation.axios.filed_required
                    return
                }

                if(this.errors.wallet) {
                    return
                }

                if(this.account_name_fields.includes(this.paysystem.slug) && !this.account_name) {
                    this.errors.account_name = this.$store.state.translation.axios.filed_required
                    return
                }

                if(this.bank_code_fields.includes(this.paysystem.slug) && !this.bank_code) {
                    this.errors.bank_code = this.$store.state.translation.axios.filed_required
                    return
                }

                this.loader_button = true

                this.$axios.post('/cashier/withdrawal/create', JSON.stringify({
                    paysystem: {
                        id: this.paysystem.id,
                        slug: this.paysystem.slug
                    },
                    amount: this.amount,
                    wallet: this.wallet,
                    account_name: this.account_name,
                    bank_code: this.bank_code
                }))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.amount = null
                            this.wallet = null
                            this.account_name = null

                            this.errors.amount = false
                            this.errors.wallet = false
                            this.errors.account_name = false

                            this.page = 4

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)

                        if('field' in response) {
                            this.errors[response.field] = response.message
                        }
                    }
                })
            },
            getPaysystems () {
                this.appLoader('create', 100)
                this.$axios.post('/cashier/paysystems/items', JSON.stringify({
                    type: 'withdrawal'
                }))
                .then((response) => {
                    this.appLoader('remove')
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.paysystems = response.data

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            maskWallet() {
                if(['visa', 'mastercard', 'maestro', 'mir', 'cards', 'card'].includes(this.paysystem.slug)) {
                    this.wallet_mask = {
                        mask: '####-####-####-####',
                        placeholder: '0000 0000 0000 0000',
                        text: this.$store.state.translation.wallet.withdraw.card_number
                    }
                } else if(this.paysystem.slug == 'yoomoney') {
                    this.wallet_mask = {
                        mask: '4##############',
                        placeholder: '400000000000000',
                        text: this.$store.state.translation.wallet.withdraw.wallet_number
                    }
                } else if(this.paysystem.slug == 'trc20_usdt') {
                    this.wallet_mask = {
                        mask: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
                        placeholder: '1KFzzGtDdnq5hrwxXGjwVnKzRbvf8WVxck',
                        text: this.$sprintf(this.$store.state.translation.wallet.withdraw.custom_wallet, 'TRC20')
                    }
                } else if(this.paysystem.slug == 'erc20_usdt') {
                    this.wallet_mask = {
                        mask: '',
                        placeholder: '0x00000000219ab540356cBB839Cbe05303d7705Fa',
                        text: this.$store.state.translation.wallet.withdraw.wallet_number
                    }
                } else if(this.paysystem.slug == 'btc') {
                    this.wallet_mask = {
                        mask: '',
                        placeholder: '34xp4vRoCGJym3xR7yCVPFHoCNxv4Twseo',
                        text: this.$store.state.translation.wallet.withdraw.wallet_number
                    }
                } else if(this.paysystem.slug == 'eth') {
                    this.wallet_mask = {
                        mask: '',
                        placeholder: '0x00000000219ab540356cBB839Cbe05303d7705Fa',
                        text: this.$store.state.translation.wallet.withdraw.wallet_number
                    }
                } else if(this.paysystem.slug == 'bch') {
                    this.wallet_mask = {
                        mask: '',
                        placeholder: '1PUwPCNqKiC6La8wtbJEAhnBvtc8gdw19h',
                        text: this.$store.state.translation.wallet.withdraw.wallet_number
                    }
                } else if(this.paysystem.slug == 'bep20_bnb') {
                    this.wallet_mask = {
                        mask: '',
                        placeholder: '0x00000000219ab540356cBB839Cbe05303d7705Fa',
                        text: this.$store.state.translation.wallet.withdraw.wallet_number
                    }
                } else if(this.paysystem.slug == 'trx') {
                    this.wallet_mask = {
                        mask: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
                        placeholder: '1KFzzGtDdnq5hrwxXGjwVnKzRbvf8WVxck',
                        text: this.$store.state.translation.wallet.withdraw.wallet_number
                    }
                } else if(this.paysystem.slug == 'ltc') {
                    this.wallet_mask = {
                        mask: '',
                        placeholder: 'MQd1fJwqBJvwLuyhr17PhEFx1swiqDbPQS',
                        text: this.$store.state.translation.wallet.withdraw.wallet_number
                    }
                } else if(this.paysystem.slug == 'paytm') {
                    this.wallet_mask = {
                        mask: '##########',
                        placeholder: '1234567890',
                        text: this.$store.state.translation.wallet.withdraw.wallet_number
                    }
                } else if(this.paysystem.slug == 'imps_ib') {
                    this.wallet_mask = {
                        mask: '',
                        placeholder: '123456789012345',
                        text: this.$store.state.translation.wallet.withdraw.wallet_number
                    }
                }
            },
            validateWallet() {
                if(['visa', 'mastercard', 'maestro', 'mir', 'cards', 'card'].includes(this.paysystem.slug)) {
                    if(!this.validateCard(this.wallet)) {
                        this.errors.wallet = this.$store.state.translation.wallet.withdraw.invalid_card
                    } else {
                        this.errors.wallet = false
                    }
                }
            },
            inputCard() {
                if(['visa', 'mastercard', 'maestro', 'mir', 'cards', 'card'].includes(this.paysystem.slug)) {
                    if(this.wallet.length >= 20) {
                        this.wallet_mask = {
                            mask: '####-####-####-####-##',
                            placeholder: '0000 0000 0000 0000 00',
                            text: this.$store.state.translation.wallet.withdraw.card_number
                        }
                    } else {
                        this.wallet_mask = {
                            mask: '####-####-####-####',
                            placeholder: '0000 0000 0000 0000',
                            text: this.$store.state.translation.wallet.withdraw.card_number
                        }
                    }
                }
            },
            validateCard(number) {
                number = number.replace(/\s/g, '')
                number = number.replaceAll('-', '')

                var regex = new RegExp("^[0-9]{16}$")
                if (!regex.test(number)) {
                    return false;
                }

                var sum = 0

                for (var i = 0; i < number.length; i++) {
                    var intVal = parseInt(number.substr(i, 1))
                    if (i % 2 == 0) {
                        intVal *= 2
                        if (intVal > 9) {
                            intVal = 1 + (intVal % 10)
                        }
                    }
                    sum += intVal
                }

                return (sum % 10) == 0
            },
            checkAmount () {
                if(!this.amount) {
                    this.errors.amount = this.$store.state.translation.axios.filed_required
                    return
                }
                if(this.amount < parseFloat(this.paysystem.min_payment)) {
                    this.errors.amount = this.$sprintf(this.$store.state.translation.wallet.withdraw.min_withdraw, this.paysystem.min_payment, this.$store.state.user.data.merchant.currency)
                    return
                }
                this.page++
            }
        },
        beforeMount() {
            this.getPaysystems()
        }
    }
</script>