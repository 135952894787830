export default {
  ApplicationNAME: 'PureGold',
  ApplicationAPI: `https://${window.location.hostname}/api/v1`,
  ApplicationGamesAPI: `https://${window.location.hostname}/games-app/api/v1`,
  ApplicationURL: `https://${window.location.hostname}`,
  ApplicationStaticURL: 'https://static-pure-gold.life',
  ApplicationHost: window.location.hostname,
  RecaptchaV3: {
    token: '',
    action: 'casino'
  },
  RecaptchaV2: {
    token: ''
  },
  Timezone: 'Europe/Moscow',
  contacts: {
    email: 'support@puregold.casino',
    telegram: 'PureGoldSupport',
    phone: {
      national: '+79000000000',
      international: '+7 (900) 000-00-00'
    }
  }
}
