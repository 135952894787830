<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            

            <main>
                <cards-sidebar/>

                <section class="section section--profile">
                    
                    <profile-mobile/>

                    <h2 class="section__title">{{ $store.state.translation.wallet.title }}</h2>

                    <div class="section__content">
                        <div class="tabs">
                            <ul class="tabs__nav">
                                <li class="tabs__nav-item">
                                    <router-link class="btn btn--fill-purple tabs__nav-btn" :class="{'active': !$props.page}" :to="{name: 'AccountWallet'}">
                                        {{ $store.state.translation.wallet.balance_title }}
                                    </router-link>
                                </li>
                                <li class="tabs__nav-item">
                                    <router-link class="btn btn--fill-purple tabs__nav-btn" :class="{'active': $props.page == 'deposit'}" :to="{name: 'AccountWallet', params: {page: 'deposit'}}">
                                        {{ $store.state.translation.wallet.deposit_title }}
                                    </router-link>
                                </li>
                                <li class="tabs__nav-item">
                                    <router-link class="btn btn--fill-purple tabs__nav-btn" :class="{'active': $props.page == 'withdraw'}" :to="{name: 'AccountWallet', params: {page: 'withdraw'}}">
                                        {{ $store.state.translation.wallet.withdraw_title }}
                                    </router-link>
                                </li>
                                <li class="tabs__nav-item">
                                    <router-link class="btn btn--fill-purple tabs__nav-btn" :class="{'active': $props.page == 'history'}" :to="{name: 'AccountWallet', params: {page: 'history'}}">
                                        {{ $store.state.translation.wallet.history_title }}
                                    </router-link>
                                </li>
                            </ul>

                            <div class="tabs__content">
                                <div class="tabs__pane active" v-if="!$props.page">
                                    <wallet-balance/>
                                </div>
                                <div class="tabs__pane active" v-if="$props.page == 'deposit'">
                                    <wallet-deposit/>
                                </div>
                                <div class="tabs__pane active" v-if="$props.page == 'withdraw'">
                                    <wallet-withdraw/>
                                </div>
                                <div class="tabs__pane active" v-if="$props.page == 'history'">
                                    <wallet-history/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.wallet.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.wallet.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.wallet.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.wallet.meta.description
                    }
                ]
            }
        },
        props: ['page']
    }
</script>
