<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <main>
                <cards-sidebar/>

                <section class="section section--profile section--restrictions">
                    
                    <profile-mobile/>

                    <div class="section__content">
                        <!-- section banner -->
                        <section class="section section--banner section--banner-vip">
                            <div class="section__content">
                                <div class="section__title">
                                    <p>{{ $store.state.translation.vip.title }}</p>
                                    <span>{{ $store.state.translation.vip.exclusive }}</span>
                                </div>

                                <a class="btn btn--purple section__btn" @click="$refs.about_vip.scrollIntoView()">{{ $store.state.translation.vip.discover }}</a>
                            </div>
                        </section>
                        <!-- end of section banner -->

                        <!-- section vip programm -->
                        <section class="section section--profile section--vip-programm">
                            <h2 class="section__title">{{ $store.state.translation.vip.program }}</h2>
                            <div class="section__content">
                                <section class="section section--vip-lvl">
                                    <div class="profile-preview profile-preview--vip">
                                        <div class="profile-preview__user">
                                            <img :src="`${$config.ApplicationStaticURL}/avatar/${$store.state.user.data.avatar}`" class="profile-preview__user-image" />
                                            <div>
                                                <div class="profile-preview__user-name">{{ $store.state.user.data.username }}</div>
                                                <div class="rating">
                                                    <span class="rating__item" :class="{'bronze': status.id == 1}"></span>
                                                    <span class="rating__item" :class="{'silver': status.id == 2}"></span>
                                                    <span class="rating__item" :class="{'gold': status.id == 3}"></span>
                                                    <span class="rating__item" :class="{'platinum': status.id == 4}"></span>
                                                    <span class="rating__item" :class="{'diamond': status.id == 5}"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="exp">
                                        <div class="exp__items-list">
                                            <div class="exp__item active" v-for="(item, index) in $store.state.promo_statuses" v-if="status.id">
                                                <div class="exp__item-caption">
                                                    <template v-if="item.id == 1">
                                                        <img src="../../assets/img/icons/vip-bronze.svg" :alt="item.name" class="exp__item-icon" />
                                                    </template>
                                                    <template v-else-if="item.id == 2">
                                                        <img src="../../assets/img/icons/vip-silver.svg" :alt="item.name" class="exp__item-icon" />
                                                    </template>
                                                    <template v-else-if="item.id == 3">
                                                        <img src="../../assets/img/icons/vip-gold.svg" :alt="item.name" class="exp__item-icon" />
                                                    </template>
                                                    <template v-else-if="item.id == 4">
                                                        <img src="../../assets/img/icons/vip-platinum.svg" :alt="item.name" class="exp__item-icon" />
                                                    </template>
                                                    <template v-else-if="item.id == 5">
                                                        <img src="../../assets/img/icons/vip-diamond.svg" :alt="item.name" class="exp__item-icon" />
                                                    </template>
                                                    
                                                    {{ item.id }}. {{ item.name }}
                                                </div>

                                                <div class="exp__item-bar">
                                                    <template v-if="(index + 0) < status.id">
                                                        <div class="exp__item-bar-progress" style="width: 100%;"></div>
                                                    </template>
                                                    <template v-if="(index + 0) == status.id">
                                                        <div class="exp__item-bar-progress" :style="`width: ${(index + 1) < status.id ? '100%' : status.percent + '%'}`"></div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="exp__item-bar-progress" style="width: 0%;"></div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="exp__bottom">
                                            <div class="exp__bottom-current">
                                                <div>
                                                    <span class="exp__bottom-caption">
                                                        {{ $store.state.translation.vip.your_xp }}:
                                                    </span>
                                                    <span class="exp__bottom-value">
                                                        {{ parseFloat(status.deposits / 10).toFixed(2) }}
                                                    </span>
                                                </div>

                                                <div>
                                                    <span class="highlight">/</span>

                                                    <span class="exp__bottom-caption">
                                                        {{ $store.state.translation.vip.sum_deposits }}:
                                                    </span>
                                                    <span class="exp__bottom-value">
                                                        {{ parseFloat(status.deposits).toFixed(2) }} {{ $store.state.user.data.merchant.currency }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="exp__bottom-next">
                                                <span class="exp__bottom-caption">
                                                    {{ $sprintf($store.state.translation.vip.before_level, status.next.id, status.next.name) }}:
                                                </span>

                                                <span class="exp__bottom-value">
                                                    {{ parseFloat(status.need_deposits).toFixed(2) }} {{ $store.state.user.data.merchant.currency }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="section section--profile section--about-vip">
                                    <div class="section__image-block">
                                        <article class="card card--vip">

                                            <template v-if="status.id == 1">
                                                <img src="../../assets/img/icons/vip-bronze.svg" :alt="status.name" class="card__badge" />
                                            </template>
                                            <template v-else-if="status.id == 2">
                                                <img src="../../assets/img/icons/vip-silver.svg" :alt="status.name" class="card__badge" />
                                            </template>
                                            <template v-else-if="status.id == 3">
                                                <img src="../../assets/img/icons/vip-gold.svg" :alt="status.name" class="card__badge" />
                                            </template>
                                            <template v-else-if="status.id == 4">
                                                <img src="../../assets/img/icons/vip-platinum.svg" :alt="status.name" class="card__badge" />
                                            </template>
                                            <template v-else-if="status.id == 5">
                                                <img src="../../assets/img/icons/vip-diamond.svg" :alt="status.name" class="card__badge" />
                                            </template>

                                            <h6 class="card__title">{{ status.name }} {{ $store.state.translation.vip.vip_card }}</h6>
                                        </article>
                                    </div>
                                    <div class="section__text-block">
                                        <h3 class="section__title">{{ $store.state.translation.vip.about }}</h3>
                                        <div class="section__text">
                                            {{ $store.state.translation.vip.desc1 }}
                                            <br />
                                            {{ $store.state.translation.vip.desc2 }}
                                        </div>
                                    </div>
                                </section>

                                <section class="section section--profile section--advantages-vip" ref="about_vip">
                                    <h2 class="section__title">{{ $store.state.translation.vip.benefits }}</h2>
                                    <div class="section__content">
                                        <div class="table__wrapper">
                                            <table class="table table--exp">
                                                <thead>
                                                    <tr>
                                                        <th>{{ $store.state.translation.vip.level }}</th>
                                                        <th>{{ $store.state.translation.vip.amount_xp }}</th>
                                                        <th>{{ $store.state.translation.vip.cashback }}</th>
                                                        <th>{{ $store.state.translation.vip.privilege }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in $store.state.promo_statuses">
                                                        <td>
                                                            <div class="table__cell-inner">
                                                                <template v-if="item.id == 1">
                                                                    <img src="../../assets/img/icons/vip-bronze.svg" :alt="item.name" class="table__image" />
                                                                </template>
                                                                <template v-else-if="item.id == 2">
                                                                    <img src="../../assets/img/icons/vip-silver.svg" :alt="item.name" class="table__image" />
                                                                </template>
                                                                <template v-else-if="item.id == 3">
                                                                    <img src="../../assets/img/icons/vip-gold.svg" :alt="item.name" class="table__image" />
                                                                </template>
                                                                <template v-else-if="item.id == 4">
                                                                    <img src="../../assets/img/icons/vip-platinum.svg" :alt="item.name" class="table__image" />
                                                                </template>
                                                                <template v-else-if="item.id == 5">
                                                                    <img src="../../assets/img/icons/vip-diamond.svg" :alt="item.name" class="table__image" />
                                                                </template>

                                                                {{ item.id }}. {{ item.name }}
                                                            </div>
                                                        </td>
                                                        <td>{{ parseFloat(item.deposits) / 10 }}+</td>
                                                        <td>{{ item.cashback }}%</td>
                                                        <td>
                                                            {{ $sprintf($store.state.translation.vip.max_payout, item.max_withdrawal, $store.state.user.data.merchant.currency) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="acc-history acc-history--exp">
                                            <div class="acc-history__item" v-for="(item, index) in $store.state.promo_statuses">
                                                <ul>
                                                    <li>
                                                        <span class="acc-history__item-caption">{{ $store.state.translation.vip.level }}</span>
                                                        <span class="acc-history__item-value">
                                                            <template v-if="item.id == 1">
                                                                <img src="../../assets/img/icons/vip-bronze.svg" :alt="item.name" class="table__image" />
                                                            </template>
                                                            <template v-else-if="item.id == 2">
                                                                <img src="../../assets/img/icons/vip-silver.svg" :alt="item.name" class="table__image" />
                                                            </template>
                                                            <template v-else-if="item.id == 3">
                                                                <img src="../../assets/img/icons/vip-gold.svg" :alt="item.name" class="table__image" />
                                                            </template>
                                                            <template v-else-if="item.id == 4">
                                                                <img src="../../assets/img/icons/vip-platinum.svg" :alt="item.name" class="table__image" />
                                                            </template>
                                                            <template v-else-if="item.id == 5">
                                                                <img src="../../assets/img/icons/vip-diamond.svg" :alt="item.name" class="table__image" />
                                                            </template>

                                                            {{ item.id }}. {{ item.name }}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span class="acc-history__item-caption">{{ $store.state.translation.vip.amount_xp }}</span>
                                                        <span class="acc-history__item-value">{{ parseFloat(item.deposits) / 10 }}+</span>
                                                    </li>
                                                    <li>
                                                        <span class="acc-history__item-caption">{{ $store.state.translation.vip.cashback }}</span>
                                                        <span class="acc-history__item-value">{{ item.cashback }}%</span>
                                                    </li>
                                                    <li>
                                                        <span class="acc-history__item-caption">{{ $store.state.translation.vip.privilege }}</span>
                                                        <span class="acc-history__item-value">
                                                            {{ $sprintf($store.state.translation.vip.max_payout, item.max_withdrawal, $store.state.user.data.merchant.currency) }}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                status: {
                    id: null,
                    name: null,
                    percent: 0,
                    deposits: 0,
                    need_deposits: 0,
                    next: {
                        id: null,
                        name: null,
                    }
                }
            }
        },
        methods: {
            async getStatus () {
                this.appLoader('create', 100)
                await this.$axios.post('/users/status/next')
                .then((response) => {
                      response = response.data
                      switch (response.status) {
                          case 'error':
                              break
                          case 'success':

                              this.status = response.data

                              this.setUser(response.user)

                              this.appLoader('remove')

                              break
                          default:
                          //Error
                      }
                })
            }
        },
        async beforeMount () {
            if(!this.$store.state.promo_statuses.length) {
                this.appLoader('create', 100)
                await this.getVipStatuses()
                this.appLoader('remove')
            }

            await this.getStatus()
        }
    }
</script>

