<template>
	<section class="section section--profile section--auth" v-if="!is_2fa">
        <section class="section section--profile section--auth-app-key" :class="{'active': tab == 'get_token'}">
            <h2 class="section__title">{{ $store.state.translation.profile.two_fa.setting }}</h2>
            <div class="section__content">
                <p class="section__text">{{ $store.state.translation.profile.two_fa.on_login }}</p>

                <h4 class="section__title-2">{{ $store.state.translation.profile.two_fa.step1 }}</h4>
                <p class="section__text">{{ $store.state.translation.profile.two_fa.step1_desc }}</p>

                <div class="section__app-store-list">
                    <a class="section__link" target="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605">
                        <img src="../../../assets/img/icons/appStore.png" alt="" />
                    </a>
                    <a class="section__link" target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
                        <img src="../../../assets/img/icons/googlePlay.png" alt="" />
                    </a>
                </div>

                <h4 class="section__title-2">{{ $store.state.translation.profile.two_fa.step2 }}</h4>
                <p class="section__text">
                    {{ $store.state.translation.profile.two_fa.step2_desc }}
                </p>

                <form class="form form--app-key">
                    <div class="form__group">
                        <div class="form__input-wrapper">
                            <input 
                                disabled 
                                v-model="google.token"
                                type="text" 
                                class="form__input"/>

                            <button type="button" class="btn btn-copy" @click="copyText(google.token)">
                                <img src="../../../assets/img/icons/copy.svg" alt="" />
                            </button>
                        </div>
                    </div>
                </form>

                <p class="section__text">
                    {{ $store.state.translation.profile.two_fa.scan_qr }}
                </p>

                <div class="form--app-qr" v-html="google.qr"></div>

                <div class="section__btns">
                    <button type="button" class="btn btn--fill-rose btn-app-key-next" @click="tab = 'set_token'">
                        {{ $store.state.translation.profile.two_fa.next }}
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
        </section>
        <section class="section section--profile section--auth-app-confirm" :class="{'active': tab == 'set_token'}">
            <h2 class="section__title">{{ $store.state.translation.profile.two_fa.setting }}</h2>
            <div class="section__content">
                <p class="section__text">{{ $store.state.translation.profile.two_fa.on_login }}</p>

                <h4 class="section__title-2">{{ $store.state.translation.profile.two_fa.insert_code }}</h4>

                <form class="form form--app-confirm">
                    <div class="form__group form__pincode">
                        <label for="wc-input-pincode" class="form__label">
                            <span>{{ $store.state.translation.profile.two_fa.insert_pass }} <span class="red">*</span></span>

                            <input
                                @input="errors.google.code = false"
                                v-model="google.code"
                                class="google__2fa_code" 
                                type="text" 
                                maxlength="6"/>
                            <span class="error_input" v-if="errors.google.code">{{ errors.google.code }}</span>
                        </label>
                    </div>
                </form>

                <div class="section__btns">
                    <button type="button" class="btn btn--outline-rose btn-app-confirm-back" @click="tab = 'get_token'">
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.722183 9.27782C0.292606 8.84824 0.292606 8.15176 0.722183 7.72218L7.72254 0.721825C8.15212 0.292249 8.8486 0.292249 9.27817 0.721825C9.70775 1.1514 9.70775 1.84788 9.27817 2.27746L3.05564 8.5L9.27817 14.7225C9.70775 15.1521 9.70775 15.8486 9.27817 16.2782C8.8486 16.7078 8.15212 16.7078 7.72254 16.2782L0.722183 9.27782ZM21.5 9.6H1.5V7.4H21.5V9.6Z"
                                fill="#D3A229"
                            ></path>
                        </svg>

                        {{ $store.state.translation.profile.two_fa.back }}
                    </button>
                    <button 
                        :disabled="loader_button"
                        @click="googleSet"
                        type="button" 
                        class="btn btn--fill-rose btn-app-confirm-next">
                        <preloader v-if="loader_button"/>
                        {{ $store.state.translation.profile.two_fa.ready }}
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
        </section>
    </section>

    <section class="section section--profile section--auth" v-else>
        <section class="section section--profile section--auth-app-key disable_2fa active">
            <h2 class="section__title">{{ $store.state.translation.profile.two_fa.enabled }}</h2>
            
             <div class="section__btns">
                <button 
                    :disabled="loader_button"
                    @click="googleDisable"
                    type="button" 
                    class="btn btn--fill-rose btn-app-confirm-next">
                    <preloader v-if="loader_button"/>
                    {{ $store.state.translation.profile.two_fa.disable }}
                </button>
            </div>
        </section>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                loader_button: false,
                is_2fa: false,
                tab: 'get_token',
                google: {
                    token: null,
                    qr: null,
                    code: null
                },
                errors: {
                    google: {
                        code: false
                    }
                }
            }
        },
        methods: {
            googleGet () {
                this.appLoader('create', 100)
                this.$axios.post('/users/2fa/google/get')
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.google.token = response.data.token
                            this.google.qr = response.data.qr

                            this.appLoader('remove')

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            googleSet () {
                if(!this.google.code) {
                    this.errors.google.code = this.$store.state.translation.axios.filed_required
                    return
                }
                if(isNaN(this.google.code)) {
                    this.errors.google.code = this.$store.state.translation.profile.two_fa.number_code
                    return
                }
                if(this.google.code.length != 6) {
                    this.errors.google.code = this.$store.state.translation.profile.two_fa.code_length
                    return
                }

                this.loader_button = true
                this.$axios.post('/users/2fa/google/set', JSON.stringify({
                    token: this.google.token,
                    code: this.google.code
                }))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.$toast.success(response.message)

                            this.google.code = null

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false

                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                        if('field' in response) {
                            this.errors.google[response.field] = response.message
                        }
                    }
                })
            },
            googleDisable () {
                this.loader_button = true
                this.$axios.post('/users/2fa/google/disable', JSON.stringify({
                    token: this.google.token,
                    code: this.google.code
                }))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.$toast.success(response.message)

                            this.googleGet()
                            this.tab = 'get_token'

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false

                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                        if('field' in response) {
                            this.errors.google[response.field] = response.message
                        }
                    }
                })
            },
            copyText (text) {
                navigator.clipboard.writeText(text)
                this.$toast.success(this.$store.state.translation.profile.two_fa.copied)
            }
        },
        beforeMount () {
            this.is_2fa = this.$store.state.user.data.two_fa
            if(!this.$store.state.user.data.two_fa) {
                this.googleGet()
            }
        },
        watch: {
            '$store.state.user': function() {
                this.is_2fa = this.$store.state.user.data.two_fa
            }
        }
    }
</script>