<template>

	<div class="sidebar__cards-list" v-if="$store.state.user.auth">
        <article class="card card--sidebar" :class="{ 'active': $route.name == 'AccountProfile' }">
            <router-link class="card__link" :to="{name: 'AccountProfile'}"></router-link>

            <div class="card__image-wrapper">
                <img src="../../assets/img/icons/sidebar/user-circle.svg" class="card__image" alt="">
            </div>

            <div class="card__content">
                <h4 class="card__title">{{ $store.state.translation.sidebar.profile.name }}</h4>
                <p class="card__text">{{ $store.state.translation.sidebar.profile.desc }}</p>
            </div>
        </article>
        <article class="card card--sidebar" :class="{ 'active': $route.name == 'AccountWallet' }">
            <router-link class="card__link" :to="{name: 'AccountWallet'}"></router-link>

            <div class="card__image-wrapper">
                <img src="../../assets/img/icons/sidebar/coin.png" class="card__image" alt="">
            </div>

            <div class="card__content">
                <h4 class="card__title">{{ $store.state.translation.sidebar.wallet.name }}</h4>
                <p class="card__text">{{ $store.state.translation.sidebar.wallet.desc }}</p>
            </div>
        </article>
        <article class="card card--sidebar" :class="{ 'active': $route.name == 'AccountBonus' }">
            <router-link class="card__link" :to="{name: 'AccountBonus'}"></router-link>

            <div class="card__image-wrapper">
                <img src="../../assets/img/icons/sidebar/sale.png" class="card__image" alt="">
            </div>

            <div class="card__content">
                <h4 class="card__title">{{ $store.state.translation.sidebar.bonus.name }}</h4>
                <p class="card__text">{{ $store.state.translation.sidebar.bonus.desc }}</p>
            </div>
        </article>
        <article class="card card--sidebar" :class="{ 'active': $route.name == 'AccountVip' }">
            <router-link class="card__link" :to="{name: 'AccountVip'}"></router-link>

            <div class="card__image-wrapper">
                <img src="../../assets/img/icons/sidebar/vip.png" class="card__image" alt="">
            </div>

            <div class="card__content">
                <h4 class="card__title">{{ $store.state.translation.sidebar.vip.name }}</h4>
                <p class="card__text">{{ $store.state.translation.sidebar.vip.desc }}</p>
            </div>
        </article>
        <article class="card card--sidebar" :class="{ 'active': $route.name == 'AccountVerification' }">
            <router-link class="card__link" :to="{name: 'AccountVerification'}"></router-link>

            <div class="card__image-wrapper">
                <img src="../../assets/img/icons/sidebar/fingerprint.png" class="card__image" alt="">
            </div>

            <div class="card__content">
                <h4 class="card__title">{{ $store.state.translation.sidebar.verification.name }}</h4>
                <p class="card__text">{{ $store.state.translation.sidebar.verification.desc }}</p>
            </div>
        </article>
        <article class="card card--sidebar" :class="{ 'active': $route.name == 'AccountRestriction' }">
            <router-link class="card__link" :to="{name: 'AccountRestriction'}"></router-link>

            <div class="card__image-wrapper">
                <img src="../../assets/img/icons/sidebar/restriction.png" class="card__image" alt="">
            </div>

            <div class="card__content">
                <h4 class="card__title">{{ $store.state.translation.sidebar.restriction.name }}</h4>
                <p class="card__text">{{ $store.state.translation.sidebar.restriction.desc }}</p>
            </div>
        </article>
    </div>

	<div class="sidebar__cards-list" v-else>
        <article class="card card--sidebar" :class="{ 'active': $route.name == 'Vip' }">
            <router-link class="card__link" :to="{name: 'Vip'}"></router-link>

            <div class="card__image-wrapper">
                <img src="../../assets/img/icons/sidebar/vip.png" class="card__image" alt="" />
            </div>

            <div class="card__content">
                <h4 class="card__title"><span>{{ $store.state.translation.sidebar.vip_not_auth.name1 }}</span> {{ $store.state.translation.sidebar.vip_not_auth.name2 }}</h4>
                <p class="card__text">{{ $store.state.translation.sidebar.vip_not_auth.desc }}</p>
            </div>
        </article>
        <template v-if="$store.state.user.auth">
            <article class="card card--sidebar" :class="{ 'active': $route.name == 'AccountBonus' }">
                <router-link class="card__link" :to="{name: 'AccountBonus'}"></router-link>

                <div class="card__image-wrapper">
                    <img src="../../assets/img/icons/sidebar/sale.png" class="card__image" alt="" />
                </div>

                <div class="card__content">
                    <h4 class="card__title">{{ $store.state.translation.sidebar.bonus.name }}</h4>
                    <p class="card__text">{{ $store.state.translation.sidebar.bonus.desc }}</p>
                </div>
            </article>
        </template>
        <template v-else>
            <article class="card card--sidebar" :class="{ 'active': $route.name == 'Bonus' }">
                <router-link class="card__link" :to="{name: 'Bonus'}"></router-link>

                <div class="card__image-wrapper">
                    <img src="../../assets/img/icons/sidebar/sale.png" class="card__image" alt="" />
                </div>

                <div class="card__content">
                    <h4 class="card__title">{{ $store.state.translation.sidebar.bonus.name }}</h4>
                    <p class="card__text">{{ $store.state.translation.sidebar.bonus.desc }}</p>
                </div>
            </article>
        </template>
        <article class="card card--sidebar" :class="{ 'active': $route.name == 'Tournaments' }">
            <router-link class="card__link" :to="{name: 'Tournaments'}"></router-link>

            <div class="card__image-wrapper">
                <img src="../../assets/img/icons/sidebar/top.png" class="card__image" alt="" />
            </div>

            <div class="card__content">
                <h4 class="card__title">{{ $store.state.translation.sidebar.tournaments.name }}</h4>
                <p class="card__text">{{ $store.state.translation.sidebar.tournaments.desc }}</p>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        mounted () {
            var elements = document.querySelectorAll('.card__link')

            for (let el of elements) {
                el.addEventListener('click', function(e){
                    document.body.classList.remove('fixed')   
                })
            }
        }
    }
</script>