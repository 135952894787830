<template>
	<section class="section section--profile section--balance">
        <h4 class="section__title">{{ $store.state.translation.wallet.balance_title }}</h4>

        <div class="section__content">
            <article class="card card--balance">
                <h3 class="card__title">
                    <span class="card__icon">
                        <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25 4.95898V3.64062C25 2.13047 23.7758 0.90625 22.2656 0.90625H2.73438C1.22422 0.90625 0 2.13047 0 3.64062V4.95898C0 5.0938 0.109326 5.20312 0.244141 5.20312H24.7559C24.8907 5.20312 25 5.0938 25 4.95898Z"
                                fill="#D3A229"
                            ></path>
                            <path
                                d="M0 7.00977V15.3594C0 16.8695 1.22422 18.0938 2.73438 18.0938H22.2656C23.7758 18.0938 25 16.8695 25 15.3594V7.00977C25 6.87495 24.8907 6.76562 24.7559 6.76562H0.244141C0.109326 6.76562 0 6.87495 0 7.00977ZM6.25 13.4062C6.25 13.8377 5.9002 14.1875 5.46875 14.1875H4.6875C4.25605 14.1875 3.90625 13.8377 3.90625 13.4062V12.625C3.90625 12.1936 4.25605 11.8438 4.6875 11.8438H5.46875C5.9002 11.8438 6.25 12.1936 6.25 12.625V13.4062Z"
                                fill="#D3A229"
                            ></path>
                        </svg>
                    </span>
                    {{ $store.state.translation.wallet.balance.master_balance }}
                </h3>

                <div class="card__balance">{{ $store.state.user.data.balance.toFixed(2) }} {{ $store.state.user.data.merchant.currency }}</div>

                <router-link class="btn card__btn btn--deposit" :to="{name: 'AccountWallet', params: {page: 'deposit'}}">
                    {{ $store.state.translation.wallet.balance.topup }}
                </router-link>
            </article>
            <article class="card card--balance">
                <h3 class="card__title">
                    <span class="card__icon">
                        <svg width="25" height="19" viewBox="0 0 25 19" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25 4.95898V3.64062C25 2.13047 23.7758 0.90625 22.2656 0.90625H2.73438C1.22422 0.90625 0 2.13047 0 3.64062V4.95898C0 5.0938 0.109326 5.20312 0.244141 5.20312H24.7559C24.8907 5.20312 25 5.0938 25 4.95898Z"
                                fill="#287962" />
                            <path
                                d="M0 7.00977V15.3594C0 16.8695 1.22422 18.0938 2.73438 18.0938H22.2656C23.7758 18.0938 25 16.8695 25 15.3594V7.00977C25 6.87495 24.8907 6.76562 24.7559 6.76562H0.244141C0.109326 6.76562 0 6.87495 0 7.00977ZM6.25 13.4062C6.25 13.8377 5.9002 14.1875 5.46875 14.1875H4.6875C4.25605 14.1875 3.90625 13.8377 3.90625 13.4062V12.625C3.90625 12.1936 4.25605 11.8438 4.6875 11.8438H5.46875C5.9002 11.8438 6.25 12.1936 6.25 12.625V13.4062Z"
                                fill="#287962" />
                        </svg>
                    </span>
                    {{ $store.state.translation.wallet.balance.active_bonus }}
                </h3>

                <div class="card__balance">{{ $store.state.user.data.balance_bonus.toFixed(2) }} {{ $store.state.user.data.merchant.currency }}</div>

                <router-link class="btn card__btn btn--deposit" :to="{name: 'AccountBonus', params: {page: 'history'}}">
                    {{ $store.state.translation.wallet.balance.bonus_history }}
                </router-link>
            </article>
            <article class="card card--balance">
                <h3 class="card__title">
                    <span class="card__icon">
                        <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25 4.95898V3.64062C25 2.13047 23.7758 0.90625 22.2656 0.90625H2.73438C1.22422 0.90625 0 2.13047 0 3.64062V4.95898C0 5.0938 0.109326 5.20312 0.244141 5.20312H24.7559C24.8907 5.20312 25 5.0938 25 4.95898Z"
                                fill="#00BC78"
                            ></path>
                            <path
                                d="M0 7.00977V15.3594C0 16.8695 1.22422 18.0938 2.73438 18.0938H22.2656C23.7758 18.0938 25 16.8695 25 15.3594V7.00977C25 6.87495 24.8907 6.76562 24.7559 6.76562H0.244141C0.109326 6.76562 0 6.87495 0 7.00977ZM6.25 13.4062C6.25 13.8377 5.9002 14.1875 5.46875 14.1875H4.6875C4.25605 14.1875 3.90625 13.8377 3.90625 13.4062V12.625C3.90625 12.1936 4.25605 11.8438 4.6875 11.8438H5.46875C5.9002 11.8438 6.25 12.1936 6.25 12.625V13.4062Z"
                                fill="#00BC78"
                            ></path>
                        </svg>
                    </span>
                    {{ $store.state.translation.wallet.balance.cashback }}
                </h3>

                <div class="card__balance">{{ cachback.toFixed(2) }} {{ $store.state.user.data.merchant.currency }}</div>

                <button 
                    @click="claim"
                    :disabled="loader_button || !claimable"
                    type="button" 
                    class="btn card__btn btn--deposit">
                    <preloader v-if="loader_button"/>
                    {{ $store.state.translation.wallet.balance.get_cashback }}
                </button>
            </article>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                loader_button: false,
                cachback: 0,
                claimable: false
            }
        },
        methods: {
            getCachback () {
                this.appLoader('create', 100)
                this.$axios.post('/cashback/amount')
                .then((response) => {
                    this.appLoader('remove')
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.cachback = response.amount
                            this.claimable = response.claimable

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            claim () {
                this.loader_button = true

                this.$axios.post('/cashback/claim')
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.$toast.success(response.message)

                            this.getCachback()

                            this.claimable = false

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            }
        },
        beforeMount() {
            this.getCachback()
        }
    }
</script>