<template>
	<section class="section section--profile section--confirm-email">

        <div class="modal modal--default pfixed top__zero" id="confirm-email">
            <div class="modal__inner">
                <button type="button" class="btn modal__btn-close" @click="togglePopup('confirm-email')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2L12 12M22 22L12 12M12 12L22 2L2 22" stroke="#5200FF" stroke-width="4" />
                    </svg>
                </button>
                <h2 class="modal__title">{{ $store.state.translation.profile.email.success }}</h2>
                <div class="modal__content text__center">
                    {{ $store.state.translation.profile.email.success_sent }}
                </div>
            </div>
        </div>


        <h4 class="section__title" v-if="!is_confirmed">{{ $store.state.translation.profile.email.confirm_email }}</h4>
        <h4 class="section__title" v-else>{{ $store.state.translation.profile.email.email_confirmed }}</h4>

        <div class="section__email active" v-if="!is_confirmed">
            <form class="form form--profile form--confirm-email" v-on:submit.prevent="resendEmailConfirm">
                <div class="form__group">
                    <label for="ce-input-email" class="form__label">
                        <span>{{ $store.state.translation.profile.email.email }} <span class="red">*</span></span>
                        <input 
                            disabled 
                            :value="$store.state.user.data.email"
                            type="email" 
                            class="form__input" 
                            placeholder="e-mail" />
                    </label>
                </div>

                <button 
                    :disabled="loader_button"
                    type="submit" 
                    class="btn btn--fill-rose form__submit btn-email-next">
                    <preloader v-if="loader_button"/>
                    {{ $store.state.translation.profile.email.next }}
                </button>
            </form>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                loader_button: false,
                is_confirmed: false
            }
        },
        methods: {
            resendEmailConfirm () {
                this.loader_button = true
                this.$axios.post('/users/email/confirm/resend')
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.togglePopup('confirm-email')

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false

                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            }
        },
        beforeMount () {
            this.is_confirmed = this.$store.state.user.data.confirmations.email
        },
        watch: {
            '$store.state.user': function() {
                this.is_confirmed = this.$store.state.user.data.confirmations.email
            }
        }
    }
</script>