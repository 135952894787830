<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>

                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>

            <main>
                <cards-sidebar/>

                <section class="section section--text section--about-us">
                    <h1>{{ $store.state.translation.info.terms.title }}</h1>

                    <ol>
                        <li>
                            {{ $store.state.translation.info.terms.desc1 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc2 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc3 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc4 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc5 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc6 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc7 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc8 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc9 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc10 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc11 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc12 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc13 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc14 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc15 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc16 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc17 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc18 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc19 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc20 }}
                            <p>
                                {{ $store.state.translation.info.terms.desc21 }}
                            </p>

                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc22 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc23 }}

                                    <p>
                                        {{ $store.state.translation.info.terms.desc24 }}
                                    </p>
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc25 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc26 }}
                                </li>
                                <p>
                                    {{ $store.state.translation.info.terms.desc27 }}
                                </p>

                                <p>
                                    {{ $store.state.translation.info.terms.desc28 }}
                                </p>
                                <p>
                                    {{ $store.state.translation.info.terms.desc29 }}
                                </p>
                                <li>
                                    {{ $store.state.translation.info.terms.desc30 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc31 }}
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc33 }}
                            <p>
                                {{ $store.state.translation.info.terms.desc34 }}
                            </p>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc35 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc36 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc37 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc38 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc39 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc40 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc41 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc42 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc43 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc44 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc45 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc46 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc47 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc48 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc49 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc50 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc51 }}
                                </li>

                                <ul>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc52 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc53 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc54 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc55 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc56 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc57 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc58 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc59 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc60 }}
                                    </li>
                                </ul>

                                <li>
                                    {{ $store.state.translation.info.terms.desc61 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc62 }}
                                </li>

                                <ul>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc63 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc64 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc65 }}
                                    </li>
                                </ul>

                                <li>
                                    {{ $store.state.translation.info.terms.desc66 }}
                                </li>

                                <p>
                                    <a :href="`mailto:${$config.contacts.email}`">{{ $config.contacts.email }}</a>
                                </p>

                                <li>
                                    {{ $store.state.translation.info.terms.desc67 }}
                                </li>

                                <ul>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc68 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc69 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc70 }}
                                    </li>
                                </ul>

                                <li>
                                    {{ $store.state.translation.info.terms.desc72 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc73 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc73_1 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc74 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc75 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc76 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc77 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc78 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc79 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc80 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc81 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc82 }}
                                </li>

                                <ul>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc83 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc84 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc85 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc86 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc87 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc88 }}
                                    </li>
                                </ul>

                                <li>
                                    {{ $store.state.translation.info.terms.desc89 }}
                                </li>

                                <ul>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc90 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc91 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc92 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc93 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc94 }}
                                    </li>
                                </ul>

                                <li>
                                    {{ $store.state.translation.info.terms.desc95 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc96 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc97 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc98 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc99 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc100 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc101 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc102 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc103 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc104 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc106 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc107 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc108 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc109 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc110 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc111 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc112 }}
                                </li>

                                <template v-if="1==2">
                                    <li>
                                        {{ $store.state.translation.info.terms.desc113 }}
                                    </li>

                                    <table class="table table--text-page">
                                        <thead>
                                            <tr>
                                                <th>{{ $store.state.translation.info.terms.desc114 }}</th>
                                                <th>{{ $store.state.translation.info.terms.desc115 }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in $store.state.promo_statuses">
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.max_withdrawal }} {{ $store.state.user.data.merchant.currency }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>

                                <li>
                                    {{ $store.state.translation.info.terms.desc116 }}
                                </li>
                                <ul>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc117 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc118 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc119 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc120 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc121 }}
                                    </li>
                                </ul>

                                <li>
                                    {{ $store.state.translation.info.terms.desc122 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc123 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc124 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc125 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc126 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc127 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc128 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc129 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc130 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc131 }}
                                </li>

                                <ul>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc132 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc133 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc134 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc135 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc136 }}
                                    </li>
                                </ul>

                                <li>
                                    {{ $sprintf($store.state.translation.info.terms.desc137, $config.contacts.email) }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc138 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc139 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc140 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc141 }}
                                </li>

                                <ul>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc142 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc143 }}
                                    </li>
                                </ul>

                                <li>
                                    {{ $store.state.translation.info.terms.desc144 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc145 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc146 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc147 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc148 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc149 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc150 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc151 }}
                                </li>

                                <ul>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc152 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc153 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc154 }}
                                    </li>
                                    <li>
                                        {{ $store.state.translation.info.terms.desc155 }}
                                    </li>
                                </ul>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc156 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc157 }}
                                </li>
                                
                                <p>
                                    {{ $store.state.translation.info.terms.desc158 }}
                                </p>
                                <p>
                                    <a :href="`mailto:${$config.contacts.email}`">{{ $config.contacts.email }}</a>
                                </p>

                                <li>
                                    {{ $store.state.translation.info.terms.desc159 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc160 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc161 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc162 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc163 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc164 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc165 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc166 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc167 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc168 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc169 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc170 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc171 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc172 }}
                                </li>
                            </ol>
                        </li>
                        <li>
                            {{ $store.state.translation.info.terms.desc173 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.terms.desc174 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.terms.desc175 }}
                                </li>
                                <li>
                                    {{ $sprintf($store.state.translation.info.terms.desc176, $config.contacts.email) }}
                                </li>
                            </ol>
                        </li>
                    </ol>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.info.terms.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.info.terms.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.info.terms.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.info.terms.meta.description
                    }
                ]
            }
        },
        beforeMount() {
            if(!this.$store.state.promo_statuses.length) {
                this.getVipStatuses()
            }
        }
    }
</script>
