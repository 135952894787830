<template>
    <div class="timer">
        <div class="timer__item timer__item--days">
            <div class="timer__num-block">
                <svg width="90" height="89" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M45 4.53531C45.2281 4.52586 45.445 4.43386 45.6103 4.27643C45.7756 4.11901 45.878 3.90688 45.8986 3.67954L46.1981 1.11223C46.2117 0.911957 46.1609 0.712593 46.0531 0.543242C45.9453 0.373891 45.7862 0.243424 45.5991 0.170889H45.3423C45.1222 0.0849641 44.8778 0.0849641 44.6577 0.170889H44.401C44.2138 0.243424 44.0547 0.373891 43.947 0.543242C43.8392 0.712593 43.7884 0.911957 43.802 1.11223L44.0587 3.67954C44.0889 3.91052 44.2001 4.12328 44.3725 4.27998C44.5449 4.43668 44.7672 4.52715 45 4.53531Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />

                    <path
                        d="M40.8068 4.74893C41.0378 4.71869 41.2505 4.6075 41.4072 4.43513C41.5639 4.26276 41.6544 4.04039 41.6626 3.80759V1.24028C41.6488 1.03998 41.5766 0.848112 41.4549 0.68841C41.3332 0.528707 41.1674 0.408175 40.978 0.341724H40.6784C40.4576 0.25979 40.2147 0.25979 39.9938 0.341724H39.7371C39.5627 0.443851 39.4239 0.597196 39.3397 0.780926C39.2555 0.964655 39.2299 1.16986 39.2664 1.36865L39.8227 3.89317C39.8279 4.01509 39.8579 4.13466 39.9109 4.24459C39.9639 4.35453 40.0387 4.45252 40.1308 4.5326C40.2229 4.61267 40.3303 4.67315 40.4465 4.71034C40.5627 4.74754 40.6853 4.76067 40.8068 4.74893Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M36.6989 5.39152C36.9255 5.34028 37.1252 5.20705 37.2595 5.01747C37.3938 4.8279 37.4532 4.59536 37.4264 4.3646L37.1268 1.71171C37.1176 1.50627 37.0346 1.31104 36.8932 1.16175C36.7517 1.01246 36.5613 0.919091 36.3566 0.898735H36.0999C35.8762 0.834536 35.639 0.834536 35.4153 0.898735H35.1586C34.9919 1.00423 34.8668 1.16407 34.8044 1.35119C34.742 1.53832 34.7462 1.74126 34.8163 1.92566L35.5864 4.40739C35.5917 4.54612 35.6251 4.68234 35.6845 4.80781C35.744 4.93329 35.8282 5.04541 35.9322 5.13739C36.0362 5.22938 36.1578 5.29933 36.2895 5.34301C36.4213 5.38669 36.5606 5.40319 36.6989 5.39152Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M32.6341 6.46123C32.8587 6.40072 33.0512 6.25542 33.1709 6.05591C33.2906 5.8564 33.3282 5.61823 33.2759 5.39152L32.7196 2.86701C32.6741 2.6699 32.5663 2.49271 32.4121 2.36169C32.258 2.23067 32.0657 2.1528 31.8639 2.1396H31.6071C31.4842 2.11705 31.3577 2.12474 31.2384 2.16201C31.1191 2.19928 31.0107 2.26502 30.9225 2.35354H30.7086C30.5554 2.48927 30.4484 2.66936 30.4024 2.86876C30.3564 3.06815 30.3737 3.27693 30.4519 3.46604L31.3076 5.99056C31.3515 6.11858 31.4237 6.23504 31.5188 6.33124C31.614 6.42745 31.7297 6.50092 31.8572 6.54618C31.9847 6.59143 32.1208 6.60729 32.2553 6.59258C32.3899 6.57788 32.5193 6.53297 32.6341 6.46123Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />

                    <path
                        d="M28.7404 7.95849C28.9447 7.86486 29.1088 7.70125 29.2029 7.49722C29.2971 7.29318 29.3152 7.06219 29.2539 6.84599L28.4409 4.40705C28.3824 4.20775 28.2566 4.03488 28.0849 3.91787C27.9133 3.80085 27.7065 3.74691 27.4995 3.76522H27.2428C27.1211 3.76906 27.0015 3.79759 26.8911 3.84907C26.7808 3.90056 26.6821 3.97394 26.601 4.06474C26.4888 4.22853 26.4287 4.42244 26.4287 4.62099C26.4287 4.81954 26.4888 5.01345 26.601 5.17724L27.8846 7.40224C27.954 7.57082 28.073 7.71431 28.2259 7.81366C28.3787 7.91302 28.5582 7.96353 28.7404 7.95849Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M25.0178 9.88429C25.2058 9.7556 25.3456 9.56804 25.4153 9.35115C25.485 9.13426 25.4807 8.90034 25.4029 8.68621L24.3331 6.33285C24.2569 6.14426 24.116 5.989 23.9356 5.89491C23.7553 5.80082 23.5473 5.77404 23.349 5.81939H23.0923C22.9711 5.8183 22.8517 5.84942 22.7465 5.90956C22.6412 5.9697 22.5538 6.05671 22.4932 6.16169H22.2793C22.1544 6.32019 22.0864 6.51613 22.0864 6.71794C22.0864 6.91976 22.1544 7.1157 22.2793 7.27419L23.7769 9.37083C23.8802 9.59764 24.0658 9.77679 24.2961 9.87209C24.5265 9.96739 24.7844 9.97175 25.0178 9.88429Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M21.5091 12.1521C21.6878 12.0159 21.812 11.8203 21.859 11.6006C21.9061 11.3808 21.873 11.1515 21.7658 10.954L20.4821 8.72903C20.3775 8.54657 20.2172 8.40242 20.0247 8.31772C19.8322 8.23301 19.6176 8.21221 19.4124 8.25836H19.1557C19.0369 8.2836 18.9253 8.33514 18.829 8.40919C18.7328 8.48323 18.6543 8.5779 18.5994 8.68624C18.4494 8.75074 18.3197 8.85491 18.2244 8.98758C18.1291 9.12024 18.0718 9.27637 18.0586 9.43918C18.0454 9.60199 18.0768 9.76531 18.1495 9.9116C18.2222 10.0579 18.3334 10.1816 18.4711 10.2694L20.1826 12.1949C20.3645 12.3644 20.6061 12.455 20.8547 12.447C21.1032 12.439 21.3384 12.333 21.5091 12.1521Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M18.4712 14.805C18.6136 14.6296 18.6914 14.4105 18.6914 14.1845C18.6914 13.9586 18.6136 13.7395 18.4712 13.5641L16.9308 11.4675C16.8173 11.2985 16.6472 11.1755 16.4511 11.1206C16.2551 11.0657 16.0459 11.0825 15.8611 11.168H15.6044C15.3793 11.2534 15.1956 11.4219 15.0909 11.6386V11.8954C15.0043 12.0724 14.9783 12.2731 15.017 12.4664C15.0556 12.6597 15.1568 12.8349 15.3048 12.9651L17.1875 14.7194C17.3598 14.8778 17.5809 14.9726 17.8143 14.9881C18.0477 15.0037 18.2795 14.9391 18.4712 14.805Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M15.3049 17.7572C15.4554 17.5855 15.5383 17.365 15.5383 17.1368C15.5383 16.9085 15.4554 16.688 15.3049 16.5163L13.5934 14.5909C13.4526 14.4449 13.2701 14.346 13.071 14.3077C12.8719 14.2695 12.6658 14.2936 12.4809 14.3769H12.2241C12.026 14.4929 11.8746 14.6745 11.7962 14.8904V15.1471C11.7308 15.3385 11.7287 15.5459 11.7904 15.7386C11.852 15.9313 11.9741 16.099 12.1386 16.2168L14.1924 17.7572C14.3582 17.8641 14.5514 17.9209 14.7486 17.9209C14.9459 17.9209 15.1391 17.8641 15.3049 17.7572Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M12.652 21.0086C12.8025 20.8369 12.8854 20.6164 12.8854 20.3881C12.8854 20.1599 12.8025 19.9394 12.652 19.7677L10.7265 18.0562C10.568 17.9312 10.3721 17.8633 10.1703 17.8633C9.96847 17.8633 9.77253 17.9312 9.61403 18.0562H9.3573C9.16979 18.1928 9.03405 18.3888 8.9722 18.6124V18.9119C8.93161 19.1063 8.95076 19.3084 9.02712 19.4916C9.10349 19.6749 9.2335 19.8308 9.40009 19.9389L11.6251 21.2225C11.799 21.2873 11.9877 21.3016 12.1694 21.2638C12.3511 21.2259 12.5184 21.1374 12.652 21.0086Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M10.3841 24.5173C10.4943 24.3133 10.529 24.077 10.482 23.85C10.435 23.623 10.3094 23.4199 10.1274 23.2764L8.03072 21.7788C7.87222 21.6539 7.67628 21.5859 7.47447 21.5859C7.27265 21.5859 7.07671 21.6539 6.91822 21.7788H6.66148C6.47568 21.928 6.35335 22.142 6.31918 22.3778V22.6346C6.29283 22.8367 6.33282 23.042 6.43313 23.2194C6.53344 23.3969 6.68865 23.537 6.87543 23.6187L9.22879 24.6884C9.4144 24.787 9.62669 24.8235 9.83457 24.7927C10.0425 24.7619 10.235 24.6654 10.3841 24.5173Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M8.45851 28.24C8.56398 28.0297 8.58273 27.7863 8.51072 27.5623C8.43871 27.3383 8.2817 27.1515 8.07342 27.042L5.84842 25.7583C5.68992 25.6334 5.49398 25.5654 5.29217 25.5654C5.09035 25.5654 4.89441 25.6334 4.73592 25.7583C4.65084 25.8416 4.58407 25.9416 4.53986 26.0521C4.49566 26.1627 4.47499 26.2812 4.47919 26.4001V26.6996C4.46057 26.8955 4.50997 27.0917 4.61907 27.2553C4.72817 27.419 4.89032 27.5401 5.07823 27.5982L7.55996 28.4112C7.71193 28.4614 7.8742 28.472 8.03142 28.4421C8.18865 28.4121 8.33564 28.3426 8.45851 28.24Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M6.96109 32.1336C7.0019 32.0269 7.02095 31.9132 7.01713 31.7991C7.01331 31.685 6.9867 31.5728 6.93885 31.4691C6.891 31.3654 6.82289 31.2724 6.73853 31.1954C6.65416 31.1185 6.55525 31.0592 6.44763 31.0211L4.09426 29.9514C3.91271 29.8696 3.70914 29.8504 3.51552 29.8969C3.32191 29.9434 3.14921 30.0528 3.02455 30.2081V30.422C2.97477 30.5293 2.94897 30.6461 2.94897 30.7643C2.94897 30.8826 2.97477 30.9994 3.02455 31.1066V31.3634C3.03775 31.5652 3.11563 31.7575 3.24664 31.9116C3.37766 32.0658 3.55485 32.1736 3.75196 32.2191L6.27647 32.7754C6.43865 32.7419 6.58841 32.6642 6.70922 32.5509C6.83003 32.4377 6.9172 32.2932 6.96109 32.1336Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.89138 36.1987C5.9437 35.972 5.90605 35.7338 5.78634 35.5343C5.66663 35.3348 5.47421 35.1895 5.24955 35.129L2.81061 34.316C2.61437 34.2616 2.4061 34.2695 2.21452 34.3384C2.02293 34.4074 1.85745 34.5341 1.74089 34.7011V34.915C1.65896 35.1359 1.65896 35.3788 1.74089 35.5996V35.942C1.78644 36.1391 1.89427 36.3162 2.04841 36.4473C2.20255 36.5783 2.39479 36.6562 2.59666 36.6694L5.12118 36.9689C5.31355 36.9362 5.491 36.8445 5.62898 36.7065C5.76696 36.5685 5.85866 36.3911 5.89138 36.1987Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.24948 40.2208C5.2637 39.991 5.19318 39.7639 5.05124 39.5825C4.90931 39.4011 4.70582 39.2781 4.47928 39.2367L1.99755 38.6804C1.79372 38.6408 1.58249 38.6696 1.39675 38.7625C1.21101 38.8553 1.0612 39.007 0.97063 39.1939C0.888696 39.4147 0.888696 39.6577 0.97063 39.8785V40.178C1.03198 40.3706 1.15114 40.5396 1.3119 40.6621C1.47266 40.7846 1.66724 40.8546 1.86919 40.8626H4.4365C4.61828 40.8418 4.79065 40.7707 4.93426 40.6573C5.07787 40.544 5.18702 40.3928 5.24948 40.2208Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M4.99277 44.4997C4.99491 44.2708 4.91354 44.0489 4.76389 43.8757C4.61425 43.7024 4.40659 43.5896 4.17979 43.5584L1.61248 43.3017C1.41102 43.2808 1.20843 43.3286 1.03756 43.4374C0.866692 43.5461 0.737568 43.7094 0.671133 43.9007V44.1574C0.585208 44.3775 0.585208 44.6219 0.671133 44.842V45.0988C0.737568 45.2901 0.866692 45.4534 1.03756 45.5621C1.20843 45.6708 1.41102 45.7187 1.61248 45.6978L4.17979 45.4411C4.40659 45.4099 4.61425 45.2971 4.76389 45.1238C4.91354 44.9505 4.99491 44.7287 4.99277 44.4997Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.24942 48.7786C5.21918 48.5476 5.10799 48.3349 4.93562 48.1782C4.76325 48.0215 4.54088 47.931 4.30808 47.9229H1.74077C1.53882 47.9309 1.34424 48.0009 1.18348 48.1234C1.02272 48.2459 0.903563 48.4149 0.842212 48.6075V48.907C0.760278 49.1278 0.760278 49.3708 0.842212 49.5916C0.932779 49.7785 1.08259 49.9302 1.26833 50.023C1.45407 50.1159 1.6653 50.1447 1.86913 50.1051L4.35087 49.5488C4.56615 49.5426 4.77279 49.4628 4.93632 49.3226C5.09984 49.1824 5.21035 48.9904 5.24942 48.7786Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.89122 52.8011C5.83997 52.5745 5.70675 52.3749 5.51717 52.2406C5.32759 52.1063 5.09505 52.0468 4.86429 52.0737L2.33977 52.3732C2.12174 52.3552 1.90423 52.4137 1.72463 52.5386C1.54504 52.6635 1.41456 52.8471 1.35564 53.0578V53.3146C1.27371 53.5354 1.27371 53.7783 1.35564 53.9992V54.2131C1.47219 54.3801 1.63767 54.5068 1.82926 54.5758C2.02085 54.6447 2.22912 54.6526 2.42535 54.5982L4.86429 53.828C5.00077 53.8341 5.13701 53.8117 5.26435 53.7622C5.39169 53.7128 5.50734 53.6373 5.60394 53.5407C5.70054 53.4441 5.77597 53.3285 5.82543 53.2012C5.87488 53.0738 5.89729 52.9376 5.89122 52.8011Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M6.96093 56.866C6.90042 56.6414 6.75512 56.4489 6.55561 56.3292C6.35609 56.2095 6.11793 56.1719 5.89122 56.2242L3.3667 56.7804C3.1696 56.826 2.99241 56.9338 2.86139 57.088C2.73037 57.2421 2.65249 57.4343 2.6393 57.6362V57.8929C2.58951 58.0002 2.56372 58.117 2.56372 58.2352C2.56372 58.3535 2.58951 58.4703 2.6393 58.5776V58.7915C2.76396 58.9468 2.93666 59.0562 3.13027 59.1027C3.32389 59.1492 3.52746 59.13 3.70901 59.0482L6.06238 57.9785C6.2035 57.9924 6.34584 57.9709 6.47662 57.9161C6.6074 57.8613 6.72252 57.7749 6.81162 57.6645C6.90071 57.5542 6.961 57.4235 6.98707 57.2841C7.01313 57.1447 7.00415 57.0011 6.96093 56.866Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M8.45859 60.7591C8.36496 60.5548 8.20135 60.3908 7.99731 60.2966C7.79328 60.2024 7.56228 60.1844 7.34608 60.2457L4.86435 61.0586C4.67645 61.1168 4.51431 61.2378 4.4052 61.4015C4.2961 61.5651 4.2467 61.7614 4.26532 61.9572V62.2567C4.26112 62.3757 4.28179 62.4942 4.32599 62.6047C4.3702 62.7152 4.43697 62.8153 4.52205 62.8985C4.68054 63.0235 4.87648 63.0914 5.0783 63.0914C5.28011 63.0914 5.47605 63.0235 5.63455 62.8985L7.85955 61.6149C8.03613 61.5525 8.18879 61.4364 8.2962 61.283C8.4036 61.1296 8.46038 60.9464 8.45859 60.7591Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M10.3841 64.4817C10.2609 64.2877 10.0735 64.1429 9.8547 64.0726C9.63587 64.0023 9.39931 64.0107 9.18607 64.0966L6.8327 65.1663C6.64592 65.248 6.49071 65.3881 6.3904 65.5656C6.29009 65.743 6.2501 65.9483 6.27645 66.1504V66.4072C6.31063 66.643 6.43296 66.857 6.61876 67.0062H6.87549C7.03399 67.1311 7.22993 67.1991 7.43174 67.1991C7.63356 67.1991 7.8295 67.1311 7.98799 67.0062L10.0846 65.5086C10.2283 65.3845 10.333 65.2216 10.3861 65.0394C10.4392 64.8572 10.4386 64.6635 10.3841 64.4817Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M12.6518 67.9903C12.5084 67.8082 12.3052 67.6826 12.0782 67.6356C11.8512 67.5887 11.6149 67.6234 11.411 67.7336L9.18598 69.0172C9.01939 69.1252 8.88938 69.2811 8.81301 69.4644C8.73664 69.6477 8.7175 69.8498 8.75809 70.0441V70.3437C8.81994 70.5672 8.95568 70.7633 9.14319 70.8999H9.39992C9.55493 71.0325 9.7522 71.1053 9.95617 71.1053C10.1601 71.1053 10.3574 71.0325 10.5124 70.8999L12.4379 69.1884C12.6096 69.0463 12.7253 68.8479 12.7645 68.6285C12.8037 68.4092 12.7638 68.183 12.6518 67.9903Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M15.3047 71.2429C15.1225 71.097 14.8962 71.0176 14.6628 71.0176C14.4295 71.0176 14.2032 71.097 14.021 71.2429L11.9672 72.7832C11.8027 72.9011 11.6807 73.0688 11.619 73.2615C11.5573 73.4541 11.5594 73.6615 11.6249 73.853V74.1097C11.7032 74.3255 11.8546 74.5072 12.0527 74.6232H12.3095C12.4944 74.7065 12.7005 74.7306 12.8996 74.6923C13.0987 74.654 13.2812 74.5551 13.422 74.4092L15.1335 72.4837C15.3093 72.3339 15.4239 72.1247 15.4554 71.8959C15.487 71.6671 15.4333 71.4347 15.3047 71.2429Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M18.4712 74.195C18.2891 74.0492 18.0627 73.9697 17.8294 73.9697C17.596 73.9697 17.3697 74.0492 17.1875 74.195L15.3048 75.9493C15.1568 76.0795 15.0556 76.2547 15.017 76.448C14.9783 76.6413 15.0043 76.842 15.0909 77.019V77.2758C15.1956 77.4925 15.3793 77.661 15.6044 77.7465H15.8611C16.0459 77.8319 16.2551 77.8487 16.4511 77.7938C16.6472 77.7389 16.8173 77.6159 16.9308 77.4469L18.4712 75.3503C18.593 75.1823 18.6585 74.9801 18.6585 74.7727C18.6585 74.5652 18.593 74.363 18.4712 74.195Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M21.5092 76.8477C21.3375 76.6972 21.117 76.6143 20.8887 76.6143C20.6605 76.6143 20.44 76.6972 20.2683 76.8477L18.4712 78.7303C18.3463 78.8888 18.2783 79.0848 18.2783 79.2866C18.2783 79.4884 18.3463 79.6844 18.4712 79.8428V80.0568C18.5261 80.1651 18.6045 80.2598 18.7008 80.3338C18.797 80.4079 18.9087 80.4594 19.0274 80.4847H19.2842C19.4894 80.5308 19.704 80.51 19.8965 80.4253C20.089 80.3406 20.2493 80.1965 20.3539 80.014L21.6375 77.789C21.7015 77.6348 21.7234 77.4664 21.7008 77.3009C21.6783 77.1355 21.6121 76.9791 21.5092 76.8477Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M25.0178 79.1154C24.8138 79.0052 24.5775 78.9705 24.3505 79.0175C24.1235 79.0644 23.9204 79.19 23.7769 79.3721L22.2793 81.4688C22.1544 81.6273 22.0864 81.8232 22.0864 82.025C22.0864 82.2268 22.1544 82.4228 22.2793 82.5813L22.4932 82.7952C22.5538 82.9002 22.6412 82.9872 22.7465 83.0473C22.8517 83.1075 22.9711 83.1386 23.0923 83.1375H23.349C23.5473 83.1828 23.7553 83.1561 23.9356 83.062C24.116 82.9679 24.2569 82.8126 24.3331 82.624L25.4029 80.2707C25.4703 80.0622 25.4693 79.8377 25.4 79.6298C25.3307 79.4219 25.1968 79.2417 25.0178 79.1154Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M28.7404 81.0406C28.5301 80.9352 28.2867 80.9164 28.0627 80.9884C27.8387 81.0604 27.6519 81.2174 27.5424 81.4257L26.2587 83.6507C26.1465 83.8145 26.0864 84.0084 26.0864 84.207C26.0864 84.4055 26.1465 84.5994 26.2587 84.7632C26.3398 84.854 26.4385 84.9274 26.5489 84.9789C26.6592 85.0304 26.7788 85.0589 26.9005 85.0627H27.1573C27.3642 85.081 27.571 85.0271 27.7427 84.9101C27.9143 84.7931 28.0401 84.6202 28.0986 84.4209L28.9116 81.982C28.9698 81.8241 28.9846 81.6535 28.9545 81.4879C28.9244 81.3224 28.8505 81.1679 28.7404 81.0406Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M32.6341 82.539C32.5193 82.4673 32.3899 82.4224 32.2554 82.4077C32.1208 82.393 31.9847 82.4088 31.8572 82.4541C31.7297 82.4993 31.614 82.5728 31.5189 82.669C31.4237 82.7652 31.3515 82.8817 31.3076 83.0097L30.2807 85.3631C30.2025 85.5522 30.1852 85.7609 30.2313 85.9603C30.2773 86.1597 30.3843 86.3398 30.5374 86.4756H30.7514C30.8578 86.5283 30.9749 86.5558 31.0937 86.5558C31.2125 86.5558 31.3296 86.5283 31.436 86.4756H31.6927C31.8946 86.4624 32.0868 86.3845 32.241 86.2535C32.3951 86.1224 32.503 85.9453 32.5485 85.7481L33.1048 83.2236C33.0872 83.0801 33.0352 82.9429 32.9533 82.8237C32.8713 82.7045 32.7618 82.6068 32.6341 82.539Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M36.6989 83.6081C36.4674 83.5641 36.2277 83.6047 36.0236 83.7225C35.8194 83.8403 35.6643 84.0275 35.5864 84.2499L34.8163 86.6889C34.7462 86.8733 34.742 87.0762 34.8044 87.2633C34.8668 87.4505 34.9919 87.6103 35.1586 87.7158H35.4153C35.639 87.78 35.8762 87.78 36.0999 87.7158H36.3566C36.5613 87.6954 36.7517 87.6021 36.8932 87.4528C37.0346 87.3035 37.1176 87.1083 37.1268 86.9028L37.4264 84.3783C37.3964 84.1917 37.311 84.0185 37.1813 83.8812C37.0515 83.7438 36.8835 83.6486 36.6989 83.6081Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M40.8068 84.2498C40.5812 84.2243 40.3543 84.2851 40.1718 84.4201C39.9892 84.555 39.8645 84.7541 39.8227 84.9772L39.2664 87.5017C39.2299 87.7005 39.2555 87.9057 39.3397 88.0895C39.4239 88.2732 39.5627 88.4266 39.7371 88.5287H39.9938C40.2147 88.6106 40.4576 88.6106 40.6784 88.5287H40.978C41.1674 88.4622 41.3332 88.3417 41.4549 88.182C41.5766 88.0223 41.6488 87.8304 41.6626 87.6301V85.0628C41.627 84.853 41.5244 84.6604 41.3701 84.5138C41.2158 84.3672 41.0182 84.2746 40.8068 84.2498Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M45 84.4639C44.7672 84.472 44.5449 84.5625 44.3725 84.7192C44.2001 84.8759 44.0889 85.0887 44.0587 85.3196L43.802 87.8869C43.7884 88.0872 43.8392 88.2866 43.947 88.4559C44.0547 88.6253 44.2138 88.7558 44.401 88.8283H44.6577C44.8778 88.9142 45.1222 88.9142 45.3423 88.8283H45.5991C45.7862 88.7558 45.9453 88.6253 46.0531 88.4559C46.1609 88.2866 46.2117 88.0872 46.1981 87.8869L45.8986 85.3196C45.878 85.0923 45.7756 84.8802 45.6103 84.7227C45.445 84.5653 45.2281 84.4733 45 84.4639Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M49.1504 84.2505C48.9236 84.2817 48.716 84.3945 48.5663 84.5678C48.4167 84.7411 48.3353 84.9629 48.3374 85.1919V87.7592C48.3455 87.9611 48.4155 88.1557 48.538 88.3165C48.6605 88.4772 48.8295 88.5964 49.0221 88.6577H49.2788C49.4989 88.7437 49.7433 88.7437 49.9634 88.6577H50.2201C50.4042 88.5662 50.5514 88.4144 50.637 88.2275C50.7227 88.0406 50.7417 87.83 50.6908 87.6308L50.1773 85.1063C50.1727 84.9803 50.1415 84.8567 50.0859 84.7435C50.0303 84.6304 49.9515 84.5302 49.8547 84.4495C49.7578 84.3688 49.645 84.3093 49.5237 84.2751C49.4024 84.2408 49.2752 84.2324 49.1504 84.2505Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M53.3008 83.6084C53.0693 83.6502 52.8633 83.7812 52.7273 83.9733C52.5913 84.1653 52.5361 84.403 52.5734 84.6353L52.7018 87.2882C52.7192 87.4909 52.8048 87.6817 52.9447 87.8294C53.0847 87.977 53.2705 88.0729 53.472 88.1012H53.7715C53.996 88.1592 54.2316 88.1592 54.4561 88.1012C54.6247 87.9965 54.7538 87.8391 54.8235 87.6533C54.8932 87.4675 54.8994 87.2639 54.8412 87.0743L54.0282 84.6353C54.0656 84.403 54.0103 84.1653 53.8743 83.9733C53.7383 83.7812 53.5324 83.6502 53.3008 83.6084Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M57.3658 82.5387C57.1411 82.5992 56.9487 82.7445 56.829 82.944C56.7093 83.1435 56.6716 83.3817 56.7239 83.6084L57.2802 86.1329C57.3234 86.3235 57.4249 86.496 57.5706 86.6264C57.7163 86.7567 57.8989 86.8385 58.0932 86.8603H58.3927C58.4926 86.9094 58.6023 86.9349 58.7136 86.9349C58.8249 86.9349 58.9347 86.9094 59.0345 86.8603H59.2485C59.4072 86.7286 59.5184 86.5484 59.5648 86.3474C59.6111 86.1464 59.5902 85.9357 59.5052 85.7478L58.4783 83.0093C58.3928 82.7997 58.2278 82.6324 58.0192 82.5442C57.8107 82.456 57.5758 82.454 57.3658 82.5387Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M61.2596 81.0415C61.0492 81.1276 60.8792 81.2903 60.7839 81.4967C60.6886 81.7032 60.6751 81.938 60.7462 82.154L61.5592 84.593C61.6006 84.7934 61.7162 84.9709 61.8827 85.0899C62.0493 85.2088 62.2546 85.2606 62.4577 85.2348H62.7572C63.0009 85.2181 63.2297 85.1113 63.3991 84.9353C63.524 84.7768 63.5919 84.5809 63.5919 84.379C63.5919 84.1772 63.524 83.9813 63.3991 83.8228L62.1582 81.5978C62.0864 81.422 61.9608 81.2735 61.7993 81.1736C61.6379 81.0737 61.449 81.0274 61.2596 81.0415Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M64.9821 79.1155C64.7738 79.225 64.6168 79.4118 64.5448 79.6358C64.4728 79.8599 64.4916 80.1032 64.597 80.3136L65.6667 82.6669C65.743 82.8555 65.8839 83.0108 66.0643 83.1049C66.2446 83.1989 66.4526 83.2257 66.6509 83.1804H66.9076C67.0288 83.1815 67.1481 83.1503 67.2534 83.0902C67.3586 83.0301 67.446 82.943 67.5066 82.8381V82.6241C67.6189 82.4603 67.6789 82.2664 67.6789 82.0679C67.6789 81.8693 67.6189 81.6754 67.5066 81.5116L65.9663 79.415C65.849 79.275 65.6935 79.1721 65.5187 79.1189C65.344 79.0657 65.1575 79.0645 64.9821 79.1155Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M68.4909 76.848C68.3044 76.9782 68.1743 77.1742 68.1266 77.3965C68.079 77.6189 68.1174 77.851 68.2341 78.0461L69.5178 80.2711C69.615 80.4501 69.7677 80.5927 69.9528 80.6776C70.138 80.7625 70.3457 80.785 70.5447 80.7418H70.8442C70.9609 80.7114 71.0703 80.6579 71.1659 80.5844C71.2614 80.5109 71.3412 80.4189 71.4005 80.3139V80.1C71.5254 79.9415 71.5934 79.7455 71.5934 79.5437C71.5934 79.3419 71.5254 79.146 71.4005 78.9875L69.689 77.062C69.5469 76.8903 69.3485 76.7745 69.1291 76.7354C68.9098 76.6962 68.6836 76.7361 68.4909 76.848Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M71.7427 74.1951C71.5922 74.3667 71.5093 74.5872 71.5093 74.8155C71.5093 75.0438 71.5922 75.2642 71.7427 75.4359L73.2403 77.5326C73.3538 77.7016 73.5239 77.8246 73.7199 77.8795C73.916 77.9344 74.1252 77.9176 74.31 77.8321H74.6095C74.8284 77.7369 75.0091 77.5712 75.123 77.3614V77.1047C75.2376 76.9499 75.2995 76.7624 75.2995 76.5698C75.2995 76.3772 75.2376 76.1897 75.123 76.035L73.1975 74.2806C73.1114 74.1738 73.0038 74.0861 72.8818 74.0233C72.7598 73.9604 72.626 73.9238 72.489 73.9158C72.352 73.9077 72.2148 73.9284 72.0863 73.9765C71.9578 74.0246 71.8407 74.099 71.7427 74.1951Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M74.6953 71.242C74.5529 71.4174 74.4751 71.6365 74.4751 71.8625C74.4751 72.0884 74.5529 72.3075 74.6953 72.4829L76.4068 74.4084C76.5477 74.5543 76.7301 74.6532 76.9292 74.6915C77.1284 74.7298 77.3345 74.7056 77.5193 74.6223H77.7761C77.9859 74.5085 78.1515 74.3277 78.2467 74.1089V73.8521C78.3322 73.6674 78.349 73.4581 78.2941 73.2621C78.2392 73.066 78.1162 72.896 77.9472 72.7824L75.8506 71.242C75.6826 71.1203 75.4804 71.0547 75.2729 71.0547C75.0655 71.0547 74.8633 71.1203 74.6953 71.242Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M77.3482 67.9909C77.1886 68.158 77.0996 68.3802 77.0996 68.6113C77.0996 68.8424 77.1886 69.0646 77.3482 69.2317L79.2309 70.9433C79.3927 71.0791 79.5972 71.1535 79.8085 71.1535C80.0198 71.1535 80.2243 71.0791 80.3862 70.9433H80.6001C80.799 70.8089 80.9491 70.6137 81.028 70.387V70.1731C81.0908 69.979 81.0822 69.7689 81.0037 69.5806C80.9252 69.3923 80.7821 69.2382 80.6001 69.1461L78.3751 67.8625C78.2101 67.7812 78.0249 67.7501 77.8424 67.7729C77.6599 67.7957 77.4881 67.8715 77.3482 67.9909Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M79.6158 64.4822C79.4965 64.6839 79.4567 64.9228 79.5041 65.1523C79.5516 65.3818 79.683 65.5853 79.8726 65.7231L81.9692 67.2207C82.1277 67.3456 82.3236 67.4135 82.5254 67.4135C82.7273 67.4135 82.9232 67.3456 83.0817 67.2207C83.1814 67.1543 83.2642 67.0656 83.3237 66.9616C83.3831 66.8576 83.4174 66.7412 83.424 66.6216V66.3649C83.461 66.1671 83.4305 65.9625 83.3374 65.7841C83.2443 65.6056 83.094 65.4636 82.9105 65.3808L80.5572 64.3111C80.3993 64.2528 80.2287 64.238 80.0631 64.2681C79.8976 64.2982 79.7431 64.3721 79.6158 64.4822Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M81.4985 60.7596C81.4207 60.9737 81.4163 61.2076 81.486 61.4245C81.5557 61.6414 81.6956 61.829 81.8836 61.9577L84.1086 63.2413C84.2766 63.3631 84.4788 63.4287 84.6862 63.4287C84.8937 63.4287 85.0959 63.3631 85.2639 63.2413C85.3547 63.1602 85.4281 63.0615 85.4796 62.9512C85.531 62.8408 85.5596 62.7212 85.5634 62.5995V62.3C85.5586 62.1032 85.4948 61.9124 85.3804 61.7522C85.266 61.592 85.1062 61.4698 84.9216 61.4014L82.6538 60.2461C82.4316 60.1824 82.194 60.1989 81.9828 60.2928C81.7716 60.3867 81.6001 60.552 81.4985 60.7596Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M82.9961 56.8656C82.9349 57.0818 82.9529 57.3128 83.0471 57.5169C83.1413 57.7209 83.3053 57.8845 83.5096 57.9781L85.863 59.0478C86.0521 59.126 86.2609 59.1433 86.4603 59.0973C86.6596 59.0513 86.8397 58.9443 86.9755 58.7911V58.5772C87.1267 58.3821 87.2027 58.1391 87.1894 57.8926V57.6358C87.1738 57.4281 87.0898 57.2313 86.9504 57.0765C86.811 56.9216 86.6242 56.8174 86.4192 56.7801L83.9375 56.2238C83.7334 56.22 83.5332 56.2797 83.3645 56.3946C83.1958 56.5096 83.0671 56.6742 82.9961 56.8656Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.1087 52.8003C84.0528 53.027 84.0831 53.2664 84.1938 53.472C84.3045 53.6776 84.4877 53.8347 84.7077 53.9128L87.1467 54.683C87.3426 54.7442 87.5531 54.7397 87.7462 54.6702C87.9393 54.6006 88.1044 54.4699 88.2164 54.2979V54.0839C88.3023 53.8638 88.3023 53.6194 88.2164 53.3993V53.057C88.196 52.8524 88.1026 52.6619 87.9534 52.5205C87.8041 52.379 87.6088 52.2961 87.4034 52.2868L84.8361 51.9873C84.6424 52.0243 84.4663 52.1239 84.3349 52.2708C84.2035 52.4177 84.124 52.6037 84.1087 52.8003Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.7506 48.7785C84.7251 49.0041 84.7859 49.231 84.9208 49.4136C85.0558 49.5961 85.2548 49.7209 85.478 49.7627L88.0025 50.3189C88.2059 50.3516 88.4143 50.3196 88.5986 50.2275C88.7828 50.1354 88.9335 49.9878 89.0294 49.8055C89.1153 49.5853 89.1153 49.341 89.0294 49.1209V48.8213C88.9681 48.6288 88.8489 48.4598 88.6881 48.3373C88.5274 48.2148 88.3328 48.1448 88.1308 48.1367H85.5635C85.3818 48.1576 85.2094 48.2286 85.0658 48.342C84.9222 48.4554 84.813 48.6066 84.7506 48.7785Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.9644 44.4999C84.9725 44.7327 85.063 44.9551 85.2197 45.1275C85.3764 45.2999 85.5891 45.411 85.8201 45.4413L88.3446 45.698C88.5508 45.7142 88.7569 45.6651 88.9335 45.5576C89.1102 45.45 89.2485 45.2896 89.3288 45.099V44.8422C89.4147 44.6221 89.4147 44.3777 89.3288 44.1576V43.9009C89.2485 43.7103 89.1102 43.5499 88.9335 43.4423C88.7569 43.3348 88.5508 43.2856 88.3446 43.3019L85.8201 43.5586C85.5891 43.5888 85.3764 43.7 85.2197 43.8724C85.063 44.0448 84.9725 44.2671 84.9644 44.4999Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.7505 40.2213C84.7807 40.4522 84.8919 40.665 85.0643 40.8217C85.2367 40.9784 85.459 41.0689 85.6918 41.077H88.2591C88.4611 41.069 88.6557 40.999 88.8164 40.8765C88.9772 40.754 89.0963 40.585 89.1577 40.3924V40.2213C89.2436 40.0011 89.2436 39.7568 89.1577 39.5366C89.0618 39.3544 88.9111 39.2068 88.7269 39.1146C88.5426 39.0225 88.3341 38.9905 88.1308 39.0232L85.6062 39.5794C85.4137 39.5806 85.2265 39.6436 85.0724 39.7591C84.9184 39.8747 84.8055 40.0367 84.7505 40.2213Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.1086 36.199C84.1504 36.4221 84.2751 36.6211 84.4577 36.7561C84.6402 36.891 84.8671 36.9519 85.0927 36.9264L87.66 36.6268C87.859 36.6191 88.049 36.5422 88.1974 36.4095C88.3458 36.2767 88.4433 36.0963 88.473 35.8994V35.5999C88.5589 35.3798 88.5589 35.1354 88.473 34.9153V34.7014C88.361 34.5293 88.1959 34.3986 88.0028 34.3291C87.8097 34.2596 87.5992 34.2551 87.4033 34.3163L84.9643 35.1292C84.8341 35.1331 84.7062 35.1652 84.5896 35.2233C84.4729 35.2815 84.3704 35.3643 84.2889 35.4661C84.2075 35.5679 84.1492 35.6861 84.1181 35.8127C84.0869 35.9392 84.0837 36.071 84.1086 36.199Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M82.9961 32.1338C83.0739 32.3563 83.229 32.5435 83.4332 32.6612C83.6374 32.779 83.877 32.8196 84.1086 32.7757L86.5903 32.2194C86.7953 32.1821 86.9821 32.0779 87.1215 31.923C87.2608 31.7681 87.3449 31.5714 87.3605 31.3636V31.1069C87.3738 30.8604 87.2978 30.6174 87.1465 30.4223V30.2083C87.0108 30.0552 86.8307 29.9482 86.6313 29.9022C86.4319 29.8561 86.2232 29.8734 86.034 29.9516L83.6807 31.0213C83.4448 31.0821 83.2417 31.232 83.114 31.4395C82.9863 31.647 82.9441 31.8958 82.9961 32.1338Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M81.4985 28.2399C81.6001 28.4475 81.7716 28.6128 81.9828 28.7066C82.1941 28.8005 82.4316 28.8171 82.6538 28.7533L85.0928 27.9403C85.2773 27.8719 85.4372 27.7498 85.5516 27.5896C85.666 27.4294 85.7298 27.2386 85.7346 27.0418V26.7423C85.7308 26.6206 85.7022 26.5009 85.6507 26.3906C85.5992 26.2803 85.5259 26.1816 85.4351 26.1005C85.2671 25.9787 85.0649 25.9131 84.8574 25.9131C84.6499 25.9131 84.4478 25.9787 84.2798 26.1005L82.0548 27.3841C81.8955 27.4659 81.7604 27.5879 81.6628 27.738C81.5652 27.8881 81.5086 28.0611 81.4985 28.2399Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M79.616 24.5172C79.733 24.7179 79.92 24.8683 80.1412 24.9394C80.3624 25.0105 80.602 24.9973 80.814 24.9023L83.1674 23.8326C83.3508 23.7498 83.5012 23.6077 83.5943 23.4293C83.6874 23.2508 83.7179 23.0463 83.6809 22.8485V22.5917C83.6743 22.4721 83.64 22.3557 83.5805 22.2517C83.5211 22.1477 83.4383 22.059 83.3386 21.9927C83.1801 21.8677 82.9841 21.7998 82.7823 21.7998C82.5805 21.7998 82.3846 21.8677 82.2261 21.9927L80.1294 23.4903C79.9426 23.5808 79.7909 23.7307 79.698 23.9164C79.6051 24.1021 79.5763 24.3134 79.616 24.5172Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M77.3481 21.0088C77.4843 21.1876 77.6799 21.3117 77.8996 21.3588C78.1194 21.4059 78.3487 21.3728 78.5462 21.2656L80.7712 19.9819C80.9532 19.8898 81.0963 19.7357 81.1748 19.5475C81.2532 19.3592 81.2618 19.1491 81.1991 18.955V18.8266C81.1202 18.5999 80.9701 18.4048 80.7712 18.2704H80.5572C80.3892 18.1486 80.1871 18.083 79.9796 18.083C79.7721 18.083 79.5699 18.1486 79.4019 18.2704L77.5192 19.9819C77.3876 20.1141 77.3005 20.284 77.2698 20.4681C77.2391 20.6521 77.2665 20.8411 77.3481 21.0088Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M74.6951 17.7572C74.8705 17.8996 75.0896 17.9774 75.3155 17.9774C75.5415 17.9774 75.7605 17.8996 75.9359 17.7572L78.0326 16.2168C78.2016 16.1033 78.3246 15.9332 78.3795 15.7372C78.4344 15.5411 78.4176 15.3319 78.3321 15.1471V14.8904C78.2369 14.6715 78.0712 14.4908 77.8614 14.3769H77.6047C77.4198 14.2936 77.2137 14.2695 77.0146 14.3077C76.8154 14.346 76.633 14.4449 76.4922 14.5909L74.7807 16.5163C74.6257 16.6817 74.5326 16.8955 74.517 17.1215C74.5014 17.3476 74.5643 17.5721 74.6951 17.7572Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M71.7428 14.8044C71.9182 14.9468 72.1373 15.0246 72.3632 15.0246C72.5892 15.0246 72.8083 14.9468 72.9837 14.8044L74.9092 13.05C75.0238 12.8953 75.0857 12.7078 75.0857 12.5152C75.0857 12.3226 75.0238 12.1351 74.9092 11.9803V11.7236C74.7953 11.5138 74.6146 11.3481 74.3957 11.2529H74.0962C73.9114 11.1674 73.7022 11.1506 73.5061 11.2055C73.31 11.2604 73.14 11.3834 73.0265 11.5524L71.5289 13.6491C71.4282 13.8374 71.3946 14.0543 71.4335 14.2642C71.4723 14.4741 71.5814 14.6646 71.7428 14.8044Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M68.4909 12.1514C68.6625 12.3018 68.883 12.3847 69.1113 12.3847C69.3396 12.3847 69.56 12.3018 69.7317 12.1514L71.4433 10.2259C71.5682 10.0674 71.6361 9.87144 71.6361 9.66962C71.6361 9.46781 71.5682 9.27187 71.4433 9.11337C71.384 9.00838 71.3042 8.91639 71.2086 8.84288C71.1131 8.76937 71.0037 8.71584 70.887 8.68549H70.5875C70.3884 8.64228 70.1807 8.6648 69.9956 8.74966C69.8104 8.83453 69.6578 8.97717 69.5606 9.15616L68.2769 11.3812C68.2507 11.5173 68.2564 11.6577 68.2935 11.7914C68.3306 11.925 68.3982 12.0482 68.4909 12.1514Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M64.9821 9.88341C65.1796 9.99067 65.4089 10.0237 65.6286 9.97666C65.8484 9.92957 66.044 9.80544 66.1802 9.62669L67.7206 7.53005C67.8328 7.36626 67.8929 7.17235 67.8929 6.9738C67.8929 6.77525 67.8328 6.58134 67.7206 6.41755C67.66 6.31257 67.5726 6.22556 67.4673 6.16542C67.3621 6.10528 67.2428 6.07416 67.1215 6.07524H66.8648C66.6665 6.0299 66.4585 6.05668 66.2782 6.15077C66.0979 6.24486 65.9569 6.40012 65.8807 6.58871L64.811 8.94207C64.7479 9.09917 64.7306 9.27087 64.7608 9.43741C64.7911 9.60396 64.8678 9.75856 64.9821 9.88341Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M61.2596 7.95828C61.47 8.06375 61.7133 8.0825 61.9373 8.01049C62.1614 7.93848 62.3482 7.78147 62.4577 7.57319L63.6986 5.34819C63.8235 5.18969 63.8914 4.99375 63.8914 4.79194C63.8914 4.59012 63.8235 4.39418 63.6986 4.23569C63.5292 4.05965 63.3004 3.95289 63.0567 3.93617H62.7572C62.5542 3.91038 62.3488 3.96214 62.1823 4.08112C62.0157 4.2001 61.9001 4.37754 61.8587 4.57799L61.0457 7.01694C60.9881 7.17794 60.9776 7.35203 61.0155 7.51878C61.0534 7.68552 61.1381 7.83799 61.2596 7.95828Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M57.3657 6.46078C57.5757 6.54544 57.8107 6.54345 58.0192 6.45523C58.2277 6.36702 58.3928 6.19977 58.4782 5.9901L59.5052 3.63674C59.5902 3.44879 59.6111 3.23812 59.5647 3.03712C59.5183 2.83613 59.4072 2.65593 59.2484 2.52424H59.0345C58.9528 2.43925 58.8518 2.37538 58.74 2.33811C58.6282 2.30084 58.509 2.2913 58.3927 2.3103H58.0931C57.8989 2.33212 57.7162 2.41388 57.5705 2.54423C57.4249 2.67458 57.3234 2.84705 57.2802 3.0377L56.7239 5.56222C56.7156 5.76206 56.7743 5.95896 56.8905 6.12172C57.0068 6.28448 57.174 6.4038 57.3657 6.46078Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M53.3009 5.39122C53.5214 5.43561 53.7505 5.39961 53.9467 5.28973C54.1429 5.17985 54.2933 5.00335 54.3707 4.79218L55.1836 2.31045C55.2418 2.12078 55.2356 1.91718 55.1659 1.73142C55.0963 1.54566 54.9671 1.38817 54.7985 1.28353C54.574 1.22555 54.3384 1.22555 54.1139 1.28353H53.8144C53.6087 1.20658 53.382 1.20762 53.177 1.28646C52.972 1.36529 52.803 1.51646 52.7019 1.71141L52.4452 4.23593C52.4053 4.36962 52.3971 4.51075 52.4213 4.64814C52.4454 4.78554 52.5012 4.91543 52.5842 5.02753C52.6673 5.13962 52.7753 5.23085 52.8997 5.29397C53.0241 5.3571 53.1614 5.39039 53.3009 5.39122Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M49.1504 4.74954C49.3827 4.7869 49.6204 4.73164 49.8125 4.59563C50.0045 4.45961 50.1355 4.2537 50.1773 4.02214L50.6908 1.49762C50.7416 1.29843 50.7226 1.08783 50.637 0.900943C50.5513 0.714059 50.4042 0.562198 50.2201 0.470694H49.9634C49.7433 0.384769 49.4989 0.384769 49.2788 0.470694H49.022C48.8294 0.532045 48.6604 0.651197 48.5379 0.81196C48.4155 0.972724 48.3454 1.1673 48.3374 1.36925V3.93656C48.3641 4.14285 48.4583 4.3345 48.6054 4.48159C48.7524 4.62867 48.9441 4.72289 49.1504 4.74954Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                </svg>
                <span class="timer__num">01</span>
            </div>
            <div class="timer__caption">{{ $store.state.translation.timer.days }}</div>
        </div>
        <div class="timer__item timer__item--hours">
            <div class="timer__num-block">
                <svg width="90" height="89" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M45 4.53531C45.2281 4.52586 45.445 4.43386 45.6103 4.27643C45.7756 4.11901 45.878 3.90688 45.8986 3.67954L46.1981 1.11223C46.2117 0.911957 46.1609 0.712593 46.0531 0.543242C45.9453 0.373891 45.7862 0.243424 45.5991 0.170889H45.3423C45.1222 0.0849641 44.8778 0.0849641 44.6577 0.170889H44.401C44.2138 0.243424 44.0547 0.373891 43.947 0.543242C43.8392 0.712593 43.7884 0.911957 43.802 1.11223L44.0587 3.67954C44.0889 3.91052 44.2001 4.12328 44.3725 4.27998C44.5449 4.43668 44.7672 4.52715 45 4.53531Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />

                    <path
                        d="M40.8068 4.74893C41.0378 4.71869 41.2505 4.6075 41.4072 4.43513C41.5639 4.26276 41.6544 4.04039 41.6626 3.80759V1.24028C41.6488 1.03998 41.5766 0.848112 41.4549 0.68841C41.3332 0.528707 41.1674 0.408175 40.978 0.341724H40.6784C40.4576 0.25979 40.2147 0.25979 39.9938 0.341724H39.7371C39.5627 0.443851 39.4239 0.597196 39.3397 0.780926C39.2555 0.964655 39.2299 1.16986 39.2664 1.36865L39.8227 3.89317C39.8279 4.01509 39.8579 4.13466 39.9109 4.24459C39.9639 4.35453 40.0387 4.45252 40.1308 4.5326C40.2229 4.61267 40.3303 4.67315 40.4465 4.71034C40.5627 4.74754 40.6853 4.76067 40.8068 4.74893Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M36.6989 5.39152C36.9255 5.34028 37.1252 5.20705 37.2595 5.01747C37.3938 4.8279 37.4532 4.59536 37.4264 4.3646L37.1268 1.71171C37.1176 1.50627 37.0346 1.31104 36.8932 1.16175C36.7517 1.01246 36.5613 0.919091 36.3566 0.898735H36.0999C35.8762 0.834536 35.639 0.834536 35.4153 0.898735H35.1586C34.9919 1.00423 34.8668 1.16407 34.8044 1.35119C34.742 1.53832 34.7462 1.74126 34.8163 1.92566L35.5864 4.40739C35.5917 4.54612 35.6251 4.68234 35.6845 4.80781C35.744 4.93329 35.8282 5.04541 35.9322 5.13739C36.0362 5.22938 36.1578 5.29933 36.2895 5.34301C36.4213 5.38669 36.5606 5.40319 36.6989 5.39152Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M32.6341 6.46123C32.8587 6.40072 33.0512 6.25542 33.1709 6.05591C33.2906 5.8564 33.3282 5.61823 33.2759 5.39152L32.7196 2.86701C32.6741 2.6699 32.5663 2.49271 32.4121 2.36169C32.258 2.23067 32.0657 2.1528 31.8639 2.1396H31.6071C31.4842 2.11705 31.3577 2.12474 31.2384 2.16201C31.1191 2.19928 31.0107 2.26502 30.9225 2.35354H30.7086C30.5554 2.48927 30.4484 2.66936 30.4024 2.86876C30.3564 3.06815 30.3737 3.27693 30.4519 3.46604L31.3076 5.99056C31.3515 6.11858 31.4237 6.23504 31.5188 6.33124C31.614 6.42745 31.7297 6.50092 31.8572 6.54618C31.9847 6.59143 32.1208 6.60729 32.2553 6.59258C32.3899 6.57788 32.5193 6.53297 32.6341 6.46123Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />

                    <path
                        d="M28.7404 7.95849C28.9447 7.86486 29.1088 7.70125 29.2029 7.49722C29.2971 7.29318 29.3152 7.06219 29.2539 6.84599L28.4409 4.40705C28.3824 4.20775 28.2566 4.03488 28.0849 3.91787C27.9133 3.80085 27.7065 3.74691 27.4995 3.76522H27.2428C27.1211 3.76906 27.0015 3.79759 26.8911 3.84907C26.7808 3.90056 26.6821 3.97394 26.601 4.06474C26.4888 4.22853 26.4287 4.42244 26.4287 4.62099C26.4287 4.81954 26.4888 5.01345 26.601 5.17724L27.8846 7.40224C27.954 7.57082 28.073 7.71431 28.2259 7.81366C28.3787 7.91302 28.5582 7.96353 28.7404 7.95849Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M25.0178 9.88429C25.2058 9.7556 25.3456 9.56804 25.4153 9.35115C25.485 9.13426 25.4807 8.90034 25.4029 8.68621L24.3331 6.33285C24.2569 6.14426 24.116 5.989 23.9356 5.89491C23.7553 5.80082 23.5473 5.77404 23.349 5.81939H23.0923C22.9711 5.8183 22.8517 5.84942 22.7465 5.90956C22.6412 5.9697 22.5538 6.05671 22.4932 6.16169H22.2793C22.1544 6.32019 22.0864 6.51613 22.0864 6.71794C22.0864 6.91976 22.1544 7.1157 22.2793 7.27419L23.7769 9.37083C23.8802 9.59764 24.0658 9.77679 24.2961 9.87209C24.5265 9.96739 24.7844 9.97175 25.0178 9.88429Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M21.5091 12.1521C21.6878 12.0159 21.812 11.8203 21.859 11.6006C21.9061 11.3808 21.873 11.1515 21.7658 10.954L20.4821 8.72903C20.3775 8.54657 20.2172 8.40242 20.0247 8.31772C19.8322 8.23301 19.6176 8.21221 19.4124 8.25836H19.1557C19.0369 8.2836 18.9253 8.33514 18.829 8.40919C18.7328 8.48323 18.6543 8.5779 18.5994 8.68624C18.4494 8.75074 18.3197 8.85491 18.2244 8.98758C18.1291 9.12024 18.0718 9.27637 18.0586 9.43918C18.0454 9.60199 18.0768 9.76531 18.1495 9.9116C18.2222 10.0579 18.3334 10.1816 18.4711 10.2694L20.1826 12.1949C20.3645 12.3644 20.6061 12.455 20.8547 12.447C21.1032 12.439 21.3384 12.333 21.5091 12.1521Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M18.4712 14.805C18.6136 14.6296 18.6914 14.4105 18.6914 14.1845C18.6914 13.9586 18.6136 13.7395 18.4712 13.5641L16.9308 11.4675C16.8173 11.2985 16.6472 11.1755 16.4511 11.1206C16.2551 11.0657 16.0459 11.0825 15.8611 11.168H15.6044C15.3793 11.2534 15.1956 11.4219 15.0909 11.6386V11.8954C15.0043 12.0724 14.9783 12.2731 15.017 12.4664C15.0556 12.6597 15.1568 12.8349 15.3048 12.9651L17.1875 14.7194C17.3598 14.8778 17.5809 14.9726 17.8143 14.9881C18.0477 15.0037 18.2795 14.9391 18.4712 14.805Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M15.3049 17.7572C15.4554 17.5855 15.5383 17.365 15.5383 17.1368C15.5383 16.9085 15.4554 16.688 15.3049 16.5163L13.5934 14.5909C13.4526 14.4449 13.2701 14.346 13.071 14.3077C12.8719 14.2695 12.6658 14.2936 12.4809 14.3769H12.2241C12.026 14.4929 11.8746 14.6745 11.7962 14.8904V15.1471C11.7308 15.3385 11.7287 15.5459 11.7904 15.7386C11.852 15.9313 11.9741 16.099 12.1386 16.2168L14.1924 17.7572C14.3582 17.8641 14.5514 17.9209 14.7486 17.9209C14.9459 17.9209 15.1391 17.8641 15.3049 17.7572Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M12.652 21.0086C12.8025 20.8369 12.8854 20.6164 12.8854 20.3881C12.8854 20.1599 12.8025 19.9394 12.652 19.7677L10.7265 18.0562C10.568 17.9312 10.3721 17.8633 10.1703 17.8633C9.96847 17.8633 9.77253 17.9312 9.61403 18.0562H9.3573C9.16979 18.1928 9.03405 18.3888 8.9722 18.6124V18.9119C8.93161 19.1063 8.95076 19.3084 9.02712 19.4916C9.10349 19.6749 9.2335 19.8308 9.40009 19.9389L11.6251 21.2225C11.799 21.2873 11.9877 21.3016 12.1694 21.2638C12.3511 21.2259 12.5184 21.1374 12.652 21.0086Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M10.3841 24.5173C10.4943 24.3133 10.529 24.077 10.482 23.85C10.435 23.623 10.3094 23.4199 10.1274 23.2764L8.03072 21.7788C7.87222 21.6539 7.67628 21.5859 7.47447 21.5859C7.27265 21.5859 7.07671 21.6539 6.91822 21.7788H6.66148C6.47568 21.928 6.35335 22.142 6.31918 22.3778V22.6346C6.29283 22.8367 6.33282 23.042 6.43313 23.2194C6.53344 23.3969 6.68865 23.537 6.87543 23.6187L9.22879 24.6884C9.4144 24.787 9.62669 24.8235 9.83457 24.7927C10.0425 24.7619 10.235 24.6654 10.3841 24.5173Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M8.45851 28.24C8.56398 28.0297 8.58273 27.7863 8.51072 27.5623C8.43871 27.3383 8.2817 27.1515 8.07342 27.042L5.84842 25.7583C5.68992 25.6334 5.49398 25.5654 5.29217 25.5654C5.09035 25.5654 4.89441 25.6334 4.73592 25.7583C4.65084 25.8416 4.58407 25.9416 4.53986 26.0521C4.49566 26.1627 4.47499 26.2812 4.47919 26.4001V26.6996C4.46057 26.8955 4.50997 27.0917 4.61907 27.2553C4.72817 27.419 4.89032 27.5401 5.07823 27.5982L7.55996 28.4112C7.71193 28.4614 7.8742 28.472 8.03142 28.4421C8.18865 28.4121 8.33564 28.3426 8.45851 28.24Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M6.96109 32.1336C7.0019 32.0269 7.02095 31.9132 7.01713 31.7991C7.01331 31.685 6.9867 31.5728 6.93885 31.4691C6.891 31.3654 6.82289 31.2724 6.73853 31.1954C6.65416 31.1185 6.55525 31.0592 6.44763 31.0211L4.09426 29.9514C3.91271 29.8696 3.70914 29.8504 3.51552 29.8969C3.32191 29.9434 3.14921 30.0528 3.02455 30.2081V30.422C2.97477 30.5293 2.94897 30.6461 2.94897 30.7643C2.94897 30.8826 2.97477 30.9994 3.02455 31.1066V31.3634C3.03775 31.5652 3.11563 31.7575 3.24664 31.9116C3.37766 32.0658 3.55485 32.1736 3.75196 32.2191L6.27647 32.7754C6.43865 32.7419 6.58841 32.6642 6.70922 32.5509C6.83003 32.4377 6.9172 32.2932 6.96109 32.1336Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.89138 36.1987C5.9437 35.972 5.90605 35.7338 5.78634 35.5343C5.66663 35.3348 5.47421 35.1895 5.24955 35.129L2.81061 34.316C2.61437 34.2616 2.4061 34.2695 2.21452 34.3384C2.02293 34.4074 1.85745 34.5341 1.74089 34.7011V34.915C1.65896 35.1359 1.65896 35.3788 1.74089 35.5996V35.942C1.78644 36.1391 1.89427 36.3162 2.04841 36.4473C2.20255 36.5783 2.39479 36.6562 2.59666 36.6694L5.12118 36.9689C5.31355 36.9362 5.491 36.8445 5.62898 36.7065C5.76696 36.5685 5.85866 36.3911 5.89138 36.1987Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.24948 40.2208C5.2637 39.991 5.19318 39.7639 5.05124 39.5825C4.90931 39.4011 4.70582 39.2781 4.47928 39.2367L1.99755 38.6804C1.79372 38.6408 1.58249 38.6696 1.39675 38.7625C1.21101 38.8553 1.0612 39.007 0.97063 39.1939C0.888696 39.4147 0.888696 39.6577 0.97063 39.8785V40.178C1.03198 40.3706 1.15114 40.5396 1.3119 40.6621C1.47266 40.7846 1.66724 40.8546 1.86919 40.8626H4.4365C4.61828 40.8418 4.79065 40.7707 4.93426 40.6573C5.07787 40.544 5.18702 40.3928 5.24948 40.2208Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M4.99277 44.4997C4.99491 44.2708 4.91354 44.0489 4.76389 43.8757C4.61425 43.7024 4.40659 43.5896 4.17979 43.5584L1.61248 43.3017C1.41102 43.2808 1.20843 43.3286 1.03756 43.4374C0.866692 43.5461 0.737568 43.7094 0.671133 43.9007V44.1574C0.585208 44.3775 0.585208 44.6219 0.671133 44.842V45.0988C0.737568 45.2901 0.866692 45.4534 1.03756 45.5621C1.20843 45.6708 1.41102 45.7187 1.61248 45.6978L4.17979 45.4411C4.40659 45.4099 4.61425 45.2971 4.76389 45.1238C4.91354 44.9505 4.99491 44.7287 4.99277 44.4997Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.24942 48.7786C5.21918 48.5476 5.10799 48.3349 4.93562 48.1782C4.76325 48.0215 4.54088 47.931 4.30808 47.9229H1.74077C1.53882 47.9309 1.34424 48.0009 1.18348 48.1234C1.02272 48.2459 0.903563 48.4149 0.842212 48.6075V48.907C0.760278 49.1278 0.760278 49.3708 0.842212 49.5916C0.932779 49.7785 1.08259 49.9302 1.26833 50.023C1.45407 50.1159 1.6653 50.1447 1.86913 50.1051L4.35087 49.5488C4.56615 49.5426 4.77279 49.4628 4.93632 49.3226C5.09984 49.1824 5.21035 48.9904 5.24942 48.7786Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.89122 52.8011C5.83997 52.5745 5.70675 52.3749 5.51717 52.2406C5.32759 52.1063 5.09505 52.0468 4.86429 52.0737L2.33977 52.3732C2.12174 52.3552 1.90423 52.4137 1.72463 52.5386C1.54504 52.6635 1.41456 52.8471 1.35564 53.0578V53.3146C1.27371 53.5354 1.27371 53.7783 1.35564 53.9992V54.2131C1.47219 54.3801 1.63767 54.5068 1.82926 54.5758C2.02085 54.6447 2.22912 54.6526 2.42535 54.5982L4.86429 53.828C5.00077 53.8341 5.13701 53.8117 5.26435 53.7622C5.39169 53.7128 5.50734 53.6373 5.60394 53.5407C5.70054 53.4441 5.77597 53.3285 5.82543 53.2012C5.87488 53.0738 5.89729 52.9376 5.89122 52.8011Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M6.96093 56.866C6.90042 56.6414 6.75512 56.4489 6.55561 56.3292C6.35609 56.2095 6.11793 56.1719 5.89122 56.2242L3.3667 56.7804C3.1696 56.826 2.99241 56.9338 2.86139 57.088C2.73037 57.2421 2.65249 57.4343 2.6393 57.6362V57.8929C2.58951 58.0002 2.56372 58.117 2.56372 58.2352C2.56372 58.3535 2.58951 58.4703 2.6393 58.5776V58.7915C2.76396 58.9468 2.93666 59.0562 3.13027 59.1027C3.32389 59.1492 3.52746 59.13 3.70901 59.0482L6.06238 57.9785C6.2035 57.9924 6.34584 57.9709 6.47662 57.9161C6.6074 57.8613 6.72252 57.7749 6.81162 57.6645C6.90071 57.5542 6.961 57.4235 6.98707 57.2841C7.01313 57.1447 7.00415 57.0011 6.96093 56.866Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M8.45859 60.7591C8.36496 60.5548 8.20135 60.3908 7.99731 60.2966C7.79328 60.2024 7.56228 60.1844 7.34608 60.2457L4.86435 61.0586C4.67645 61.1168 4.51431 61.2378 4.4052 61.4015C4.2961 61.5651 4.2467 61.7614 4.26532 61.9572V62.2567C4.26112 62.3757 4.28179 62.4942 4.32599 62.6047C4.3702 62.7152 4.43697 62.8153 4.52205 62.8985C4.68054 63.0235 4.87648 63.0914 5.0783 63.0914C5.28011 63.0914 5.47605 63.0235 5.63455 62.8985L7.85955 61.6149C8.03613 61.5525 8.18879 61.4364 8.2962 61.283C8.4036 61.1296 8.46038 60.9464 8.45859 60.7591Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M10.3841 64.4817C10.2609 64.2877 10.0735 64.1429 9.8547 64.0726C9.63587 64.0023 9.39931 64.0107 9.18607 64.0966L6.8327 65.1663C6.64592 65.248 6.49071 65.3881 6.3904 65.5656C6.29009 65.743 6.2501 65.9483 6.27645 66.1504V66.4072C6.31063 66.643 6.43296 66.857 6.61876 67.0062H6.87549C7.03399 67.1311 7.22993 67.1991 7.43174 67.1991C7.63356 67.1991 7.8295 67.1311 7.98799 67.0062L10.0846 65.5086C10.2283 65.3845 10.333 65.2216 10.3861 65.0394C10.4392 64.8572 10.4386 64.6635 10.3841 64.4817Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M12.6518 67.9903C12.5084 67.8082 12.3052 67.6826 12.0782 67.6356C11.8512 67.5887 11.6149 67.6234 11.411 67.7336L9.18598 69.0172C9.01939 69.1252 8.88938 69.2811 8.81301 69.4644C8.73664 69.6477 8.7175 69.8498 8.75809 70.0441V70.3437C8.81994 70.5672 8.95568 70.7633 9.14319 70.8999H9.39992C9.55493 71.0325 9.7522 71.1053 9.95617 71.1053C10.1601 71.1053 10.3574 71.0325 10.5124 70.8999L12.4379 69.1884C12.6096 69.0463 12.7253 68.8479 12.7645 68.6285C12.8037 68.4092 12.7638 68.183 12.6518 67.9903Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M15.3047 71.2429C15.1225 71.097 14.8962 71.0176 14.6628 71.0176C14.4295 71.0176 14.2032 71.097 14.021 71.2429L11.9672 72.7832C11.8027 72.9011 11.6807 73.0688 11.619 73.2615C11.5573 73.4541 11.5594 73.6615 11.6249 73.853V74.1097C11.7032 74.3255 11.8546 74.5072 12.0527 74.6232H12.3095C12.4944 74.7065 12.7005 74.7306 12.8996 74.6923C13.0987 74.654 13.2812 74.5551 13.422 74.4092L15.1335 72.4837C15.3093 72.3339 15.4239 72.1247 15.4554 71.8959C15.487 71.6671 15.4333 71.4347 15.3047 71.2429Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M18.4712 74.195C18.2891 74.0492 18.0627 73.9697 17.8294 73.9697C17.596 73.9697 17.3697 74.0492 17.1875 74.195L15.3048 75.9493C15.1568 76.0795 15.0556 76.2547 15.017 76.448C14.9783 76.6413 15.0043 76.842 15.0909 77.019V77.2758C15.1956 77.4925 15.3793 77.661 15.6044 77.7465H15.8611C16.0459 77.8319 16.2551 77.8487 16.4511 77.7938C16.6472 77.7389 16.8173 77.6159 16.9308 77.4469L18.4712 75.3503C18.593 75.1823 18.6585 74.9801 18.6585 74.7727C18.6585 74.5652 18.593 74.363 18.4712 74.195Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M21.5092 76.8477C21.3375 76.6972 21.117 76.6143 20.8887 76.6143C20.6605 76.6143 20.44 76.6972 20.2683 76.8477L18.4712 78.7303C18.3463 78.8888 18.2783 79.0848 18.2783 79.2866C18.2783 79.4884 18.3463 79.6844 18.4712 79.8428V80.0568C18.5261 80.1651 18.6045 80.2598 18.7008 80.3338C18.797 80.4079 18.9087 80.4594 19.0274 80.4847H19.2842C19.4894 80.5308 19.704 80.51 19.8965 80.4253C20.089 80.3406 20.2493 80.1965 20.3539 80.014L21.6375 77.789C21.7015 77.6348 21.7234 77.4664 21.7008 77.3009C21.6783 77.1355 21.6121 76.9791 21.5092 76.8477Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M25.0178 79.1154C24.8138 79.0052 24.5775 78.9705 24.3505 79.0175C24.1235 79.0644 23.9204 79.19 23.7769 79.3721L22.2793 81.4688C22.1544 81.6273 22.0864 81.8232 22.0864 82.025C22.0864 82.2268 22.1544 82.4228 22.2793 82.5813L22.4932 82.7952C22.5538 82.9002 22.6412 82.9872 22.7465 83.0473C22.8517 83.1075 22.9711 83.1386 23.0923 83.1375H23.349C23.5473 83.1828 23.7553 83.1561 23.9356 83.062C24.116 82.9679 24.2569 82.8126 24.3331 82.624L25.4029 80.2707C25.4703 80.0622 25.4693 79.8377 25.4 79.6298C25.3307 79.4219 25.1968 79.2417 25.0178 79.1154Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M28.7404 81.0406C28.5301 80.9352 28.2867 80.9164 28.0627 80.9884C27.8387 81.0604 27.6519 81.2174 27.5424 81.4257L26.2587 83.6507C26.1465 83.8145 26.0864 84.0084 26.0864 84.207C26.0864 84.4055 26.1465 84.5994 26.2587 84.7632C26.3398 84.854 26.4385 84.9274 26.5489 84.9789C26.6592 85.0304 26.7788 85.0589 26.9005 85.0627H27.1573C27.3642 85.081 27.571 85.0271 27.7427 84.9101C27.9143 84.7931 28.0401 84.6202 28.0986 84.4209L28.9116 81.982C28.9698 81.8241 28.9846 81.6535 28.9545 81.4879C28.9244 81.3224 28.8505 81.1679 28.7404 81.0406Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M32.6341 82.539C32.5193 82.4673 32.3899 82.4224 32.2554 82.4077C32.1208 82.393 31.9847 82.4088 31.8572 82.4541C31.7297 82.4993 31.614 82.5728 31.5189 82.669C31.4237 82.7652 31.3515 82.8817 31.3076 83.0097L30.2807 85.3631C30.2025 85.5522 30.1852 85.7609 30.2313 85.9603C30.2773 86.1597 30.3843 86.3398 30.5374 86.4756H30.7514C30.8578 86.5283 30.9749 86.5558 31.0937 86.5558C31.2125 86.5558 31.3296 86.5283 31.436 86.4756H31.6927C31.8946 86.4624 32.0868 86.3845 32.241 86.2535C32.3951 86.1224 32.503 85.9453 32.5485 85.7481L33.1048 83.2236C33.0872 83.0801 33.0352 82.9429 32.9533 82.8237C32.8713 82.7045 32.7618 82.6068 32.6341 82.539Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M36.6989 83.6081C36.4674 83.5641 36.2277 83.6047 36.0236 83.7225C35.8194 83.8403 35.6643 84.0275 35.5864 84.2499L34.8163 86.6889C34.7462 86.8733 34.742 87.0762 34.8044 87.2633C34.8668 87.4505 34.9919 87.6103 35.1586 87.7158H35.4153C35.639 87.78 35.8762 87.78 36.0999 87.7158H36.3566C36.5613 87.6954 36.7517 87.6021 36.8932 87.4528C37.0346 87.3035 37.1176 87.1083 37.1268 86.9028L37.4264 84.3783C37.3964 84.1917 37.311 84.0185 37.1813 83.8812C37.0515 83.7438 36.8835 83.6486 36.6989 83.6081Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M40.8068 84.2498C40.5812 84.2243 40.3543 84.2851 40.1718 84.4201C39.9892 84.555 39.8645 84.7541 39.8227 84.9772L39.2664 87.5017C39.2299 87.7005 39.2555 87.9057 39.3397 88.0895C39.4239 88.2732 39.5627 88.4266 39.7371 88.5287H39.9938C40.2147 88.6106 40.4576 88.6106 40.6784 88.5287H40.978C41.1674 88.4622 41.3332 88.3417 41.4549 88.182C41.5766 88.0223 41.6488 87.8304 41.6626 87.6301V85.0628C41.627 84.853 41.5244 84.6604 41.3701 84.5138C41.2158 84.3672 41.0182 84.2746 40.8068 84.2498Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M45 84.4639C44.7672 84.472 44.5449 84.5625 44.3725 84.7192C44.2001 84.8759 44.0889 85.0887 44.0587 85.3196L43.802 87.8869C43.7884 88.0872 43.8392 88.2866 43.947 88.4559C44.0547 88.6253 44.2138 88.7558 44.401 88.8283H44.6577C44.8778 88.9142 45.1222 88.9142 45.3423 88.8283H45.5991C45.7862 88.7558 45.9453 88.6253 46.0531 88.4559C46.1609 88.2866 46.2117 88.0872 46.1981 87.8869L45.8986 85.3196C45.878 85.0923 45.7756 84.8802 45.6103 84.7227C45.445 84.5653 45.2281 84.4733 45 84.4639Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M49.1504 84.2505C48.9236 84.2817 48.716 84.3945 48.5663 84.5678C48.4167 84.7411 48.3353 84.9629 48.3374 85.1919V87.7592C48.3455 87.9611 48.4155 88.1557 48.538 88.3165C48.6605 88.4772 48.8295 88.5964 49.0221 88.6577H49.2788C49.4989 88.7437 49.7433 88.7437 49.9634 88.6577H50.2201C50.4042 88.5662 50.5514 88.4144 50.637 88.2275C50.7227 88.0406 50.7417 87.83 50.6908 87.6308L50.1773 85.1063C50.1727 84.9803 50.1415 84.8567 50.0859 84.7435C50.0303 84.6304 49.9515 84.5302 49.8547 84.4495C49.7578 84.3688 49.645 84.3093 49.5237 84.2751C49.4024 84.2408 49.2752 84.2324 49.1504 84.2505Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M53.3008 83.6084C53.0693 83.6502 52.8633 83.7812 52.7273 83.9733C52.5913 84.1653 52.5361 84.403 52.5734 84.6353L52.7018 87.2882C52.7192 87.4909 52.8048 87.6817 52.9447 87.8294C53.0847 87.977 53.2705 88.0729 53.472 88.1012H53.7715C53.996 88.1592 54.2316 88.1592 54.4561 88.1012C54.6247 87.9965 54.7538 87.8391 54.8235 87.6533C54.8932 87.4675 54.8994 87.2639 54.8412 87.0743L54.0282 84.6353C54.0656 84.403 54.0103 84.1653 53.8743 83.9733C53.7383 83.7812 53.5324 83.6502 53.3008 83.6084Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M57.3658 82.5387C57.1411 82.5992 56.9487 82.7445 56.829 82.944C56.7093 83.1435 56.6716 83.3817 56.7239 83.6084L57.2802 86.1329C57.3234 86.3235 57.4249 86.496 57.5706 86.6264C57.7163 86.7567 57.8989 86.8385 58.0932 86.8603H58.3927C58.4926 86.9094 58.6023 86.9349 58.7136 86.9349C58.8249 86.9349 58.9347 86.9094 59.0345 86.8603H59.2485C59.4072 86.7286 59.5184 86.5484 59.5648 86.3474C59.6111 86.1464 59.5902 85.9357 59.5052 85.7478L58.4783 83.0093C58.3928 82.7997 58.2278 82.6324 58.0192 82.5442C57.8107 82.456 57.5758 82.454 57.3658 82.5387Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M61.2596 81.0415C61.0492 81.1276 60.8792 81.2903 60.7839 81.4967C60.6886 81.7032 60.6751 81.938 60.7462 82.154L61.5592 84.593C61.6006 84.7934 61.7162 84.9709 61.8827 85.0899C62.0493 85.2088 62.2546 85.2606 62.4577 85.2348H62.7572C63.0009 85.2181 63.2297 85.1113 63.3991 84.9353C63.524 84.7768 63.5919 84.5809 63.5919 84.379C63.5919 84.1772 63.524 83.9813 63.3991 83.8228L62.1582 81.5978C62.0864 81.422 61.9608 81.2735 61.7993 81.1736C61.6379 81.0737 61.449 81.0274 61.2596 81.0415Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M64.9821 79.1155C64.7738 79.225 64.6168 79.4118 64.5448 79.6358C64.4728 79.8599 64.4916 80.1032 64.597 80.3136L65.6667 82.6669C65.743 82.8555 65.8839 83.0108 66.0643 83.1049C66.2446 83.1989 66.4526 83.2257 66.6509 83.1804H66.9076C67.0288 83.1815 67.1481 83.1503 67.2534 83.0902C67.3586 83.0301 67.446 82.943 67.5066 82.8381V82.6241C67.6189 82.4603 67.6789 82.2664 67.6789 82.0679C67.6789 81.8693 67.6189 81.6754 67.5066 81.5116L65.9663 79.415C65.849 79.275 65.6935 79.1721 65.5187 79.1189C65.344 79.0657 65.1575 79.0645 64.9821 79.1155Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M68.4909 76.848C68.3044 76.9782 68.1743 77.1742 68.1266 77.3965C68.079 77.6189 68.1174 77.851 68.2341 78.0461L69.5178 80.2711C69.615 80.4501 69.7677 80.5927 69.9528 80.6776C70.138 80.7625 70.3457 80.785 70.5447 80.7418H70.8442C70.9609 80.7114 71.0703 80.6579 71.1659 80.5844C71.2614 80.5109 71.3412 80.4189 71.4005 80.3139V80.1C71.5254 79.9415 71.5934 79.7455 71.5934 79.5437C71.5934 79.3419 71.5254 79.146 71.4005 78.9875L69.689 77.062C69.5469 76.8903 69.3485 76.7745 69.1291 76.7354C68.9098 76.6962 68.6836 76.7361 68.4909 76.848Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M71.7427 74.1951C71.5922 74.3667 71.5093 74.5872 71.5093 74.8155C71.5093 75.0438 71.5922 75.2642 71.7427 75.4359L73.2403 77.5326C73.3538 77.7016 73.5239 77.8246 73.7199 77.8795C73.916 77.9344 74.1252 77.9176 74.31 77.8321H74.6095C74.8284 77.7369 75.0091 77.5712 75.123 77.3614V77.1047C75.2376 76.9499 75.2995 76.7624 75.2995 76.5698C75.2995 76.3772 75.2376 76.1897 75.123 76.035L73.1975 74.2806C73.1114 74.1738 73.0038 74.0861 72.8818 74.0233C72.7598 73.9604 72.626 73.9238 72.489 73.9158C72.352 73.9077 72.2148 73.9284 72.0863 73.9765C71.9578 74.0246 71.8407 74.099 71.7427 74.1951Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M74.6953 71.242C74.5529 71.4174 74.4751 71.6365 74.4751 71.8625C74.4751 72.0884 74.5529 72.3075 74.6953 72.4829L76.4068 74.4084C76.5477 74.5543 76.7301 74.6532 76.9292 74.6915C77.1284 74.7298 77.3345 74.7056 77.5193 74.6223H77.7761C77.9859 74.5085 78.1515 74.3277 78.2467 74.1089V73.8521C78.3322 73.6674 78.349 73.4581 78.2941 73.2621C78.2392 73.066 78.1162 72.896 77.9472 72.7824L75.8506 71.242C75.6826 71.1203 75.4804 71.0547 75.2729 71.0547C75.0655 71.0547 74.8633 71.1203 74.6953 71.242Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M77.3482 67.9909C77.1886 68.158 77.0996 68.3802 77.0996 68.6113C77.0996 68.8424 77.1886 69.0646 77.3482 69.2317L79.2309 70.9433C79.3927 71.0791 79.5972 71.1535 79.8085 71.1535C80.0198 71.1535 80.2243 71.0791 80.3862 70.9433H80.6001C80.799 70.8089 80.9491 70.6137 81.028 70.387V70.1731C81.0908 69.979 81.0822 69.7689 81.0037 69.5806C80.9252 69.3923 80.7821 69.2382 80.6001 69.1461L78.3751 67.8625C78.2101 67.7812 78.0249 67.7501 77.8424 67.7729C77.6599 67.7957 77.4881 67.8715 77.3482 67.9909Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M79.6158 64.4822C79.4965 64.6839 79.4567 64.9228 79.5041 65.1523C79.5516 65.3818 79.683 65.5853 79.8726 65.7231L81.9692 67.2207C82.1277 67.3456 82.3236 67.4135 82.5254 67.4135C82.7273 67.4135 82.9232 67.3456 83.0817 67.2207C83.1814 67.1543 83.2642 67.0656 83.3237 66.9616C83.3831 66.8576 83.4174 66.7412 83.424 66.6216V66.3649C83.461 66.1671 83.4305 65.9625 83.3374 65.7841C83.2443 65.6056 83.094 65.4636 82.9105 65.3808L80.5572 64.3111C80.3993 64.2528 80.2287 64.238 80.0631 64.2681C79.8976 64.2982 79.7431 64.3721 79.6158 64.4822Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M81.4985 60.7596C81.4207 60.9737 81.4163 61.2076 81.486 61.4245C81.5557 61.6414 81.6956 61.829 81.8836 61.9577L84.1086 63.2413C84.2766 63.3631 84.4788 63.4287 84.6862 63.4287C84.8937 63.4287 85.0959 63.3631 85.2639 63.2413C85.3547 63.1602 85.4281 63.0615 85.4796 62.9512C85.531 62.8408 85.5596 62.7212 85.5634 62.5995V62.3C85.5586 62.1032 85.4948 61.9124 85.3804 61.7522C85.266 61.592 85.1062 61.4698 84.9216 61.4014L82.6538 60.2461C82.4316 60.1824 82.194 60.1989 81.9828 60.2928C81.7716 60.3867 81.6001 60.552 81.4985 60.7596Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M82.9961 56.8656C82.9349 57.0818 82.9529 57.3128 83.0471 57.5169C83.1413 57.7209 83.3053 57.8845 83.5096 57.9781L85.863 59.0478C86.0521 59.126 86.2609 59.1433 86.4603 59.0973C86.6596 59.0513 86.8397 58.9443 86.9755 58.7911V58.5772C87.1267 58.3821 87.2027 58.1391 87.1894 57.8926V57.6358C87.1738 57.4281 87.0898 57.2313 86.9504 57.0765C86.811 56.9216 86.6242 56.8174 86.4192 56.7801L83.9375 56.2238C83.7334 56.22 83.5332 56.2797 83.3645 56.3946C83.1958 56.5096 83.0671 56.6742 82.9961 56.8656Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.1087 52.8003C84.0528 53.027 84.0831 53.2664 84.1938 53.472C84.3045 53.6776 84.4877 53.8347 84.7077 53.9128L87.1467 54.683C87.3426 54.7442 87.5531 54.7397 87.7462 54.6702C87.9393 54.6006 88.1044 54.4699 88.2164 54.2979V54.0839C88.3023 53.8638 88.3023 53.6194 88.2164 53.3993V53.057C88.196 52.8524 88.1026 52.6619 87.9534 52.5205C87.8041 52.379 87.6088 52.2961 87.4034 52.2868L84.8361 51.9873C84.6424 52.0243 84.4663 52.1239 84.3349 52.2708C84.2035 52.4177 84.124 52.6037 84.1087 52.8003Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.7506 48.7785C84.7251 49.0041 84.7859 49.231 84.9208 49.4136C85.0558 49.5961 85.2548 49.7209 85.478 49.7627L88.0025 50.3189C88.2059 50.3516 88.4143 50.3196 88.5986 50.2275C88.7828 50.1354 88.9335 49.9878 89.0294 49.8055C89.1153 49.5853 89.1153 49.341 89.0294 49.1209V48.8213C88.9681 48.6288 88.8489 48.4598 88.6881 48.3373C88.5274 48.2148 88.3328 48.1448 88.1308 48.1367H85.5635C85.3818 48.1576 85.2094 48.2286 85.0658 48.342C84.9222 48.4554 84.813 48.6066 84.7506 48.7785Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.9644 44.4999C84.9725 44.7327 85.063 44.9551 85.2197 45.1275C85.3764 45.2999 85.5891 45.411 85.8201 45.4413L88.3446 45.698C88.5508 45.7142 88.7569 45.6651 88.9335 45.5576C89.1102 45.45 89.2485 45.2896 89.3288 45.099V44.8422C89.4147 44.6221 89.4147 44.3777 89.3288 44.1576V43.9009C89.2485 43.7103 89.1102 43.5499 88.9335 43.4423C88.7569 43.3348 88.5508 43.2856 88.3446 43.3019L85.8201 43.5586C85.5891 43.5888 85.3764 43.7 85.2197 43.8724C85.063 44.0448 84.9725 44.2671 84.9644 44.4999Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.7505 40.2213C84.7807 40.4522 84.8919 40.665 85.0643 40.8217C85.2367 40.9784 85.459 41.0689 85.6918 41.077H88.2591C88.4611 41.069 88.6557 40.999 88.8164 40.8765C88.9772 40.754 89.0963 40.585 89.1577 40.3924V40.2213C89.2436 40.0011 89.2436 39.7568 89.1577 39.5366C89.0618 39.3544 88.9111 39.2068 88.7269 39.1146C88.5426 39.0225 88.3341 38.9905 88.1308 39.0232L85.6062 39.5794C85.4137 39.5806 85.2265 39.6436 85.0724 39.7591C84.9184 39.8747 84.8055 40.0367 84.7505 40.2213Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.1086 36.199C84.1504 36.4221 84.2751 36.6211 84.4577 36.7561C84.6402 36.891 84.8671 36.9519 85.0927 36.9264L87.66 36.6268C87.859 36.6191 88.049 36.5422 88.1974 36.4095C88.3458 36.2767 88.4433 36.0963 88.473 35.8994V35.5999C88.5589 35.3798 88.5589 35.1354 88.473 34.9153V34.7014C88.361 34.5293 88.1959 34.3986 88.0028 34.3291C87.8097 34.2596 87.5992 34.2551 87.4033 34.3163L84.9643 35.1292C84.8341 35.1331 84.7062 35.1652 84.5896 35.2233C84.4729 35.2815 84.3704 35.3643 84.2889 35.4661C84.2075 35.5679 84.1492 35.6861 84.1181 35.8127C84.0869 35.9392 84.0837 36.071 84.1086 36.199Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M82.9961 32.1338C83.0739 32.3563 83.229 32.5435 83.4332 32.6612C83.6374 32.779 83.877 32.8196 84.1086 32.7757L86.5903 32.2194C86.7953 32.1821 86.9821 32.0779 87.1215 31.923C87.2608 31.7681 87.3449 31.5714 87.3605 31.3636V31.1069C87.3738 30.8604 87.2978 30.6174 87.1465 30.4223V30.2083C87.0108 30.0552 86.8307 29.9482 86.6313 29.9022C86.4319 29.8561 86.2232 29.8734 86.034 29.9516L83.6807 31.0213C83.4448 31.0821 83.2417 31.232 83.114 31.4395C82.9863 31.647 82.9441 31.8958 82.9961 32.1338Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M81.4985 28.2399C81.6001 28.4475 81.7716 28.6128 81.9828 28.7066C82.1941 28.8005 82.4316 28.8171 82.6538 28.7533L85.0928 27.9403C85.2773 27.8719 85.4372 27.7498 85.5516 27.5896C85.666 27.4294 85.7298 27.2386 85.7346 27.0418V26.7423C85.7308 26.6206 85.7022 26.5009 85.6507 26.3906C85.5992 26.2803 85.5259 26.1816 85.4351 26.1005C85.2671 25.9787 85.0649 25.9131 84.8574 25.9131C84.6499 25.9131 84.4478 25.9787 84.2798 26.1005L82.0548 27.3841C81.8955 27.4659 81.7604 27.5879 81.6628 27.738C81.5652 27.8881 81.5086 28.0611 81.4985 28.2399Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M79.616 24.5172C79.733 24.7179 79.92 24.8683 80.1412 24.9394C80.3624 25.0105 80.602 24.9973 80.814 24.9023L83.1674 23.8326C83.3508 23.7498 83.5012 23.6077 83.5943 23.4293C83.6874 23.2508 83.7179 23.0463 83.6809 22.8485V22.5917C83.6743 22.4721 83.64 22.3557 83.5805 22.2517C83.5211 22.1477 83.4383 22.059 83.3386 21.9927C83.1801 21.8677 82.9841 21.7998 82.7823 21.7998C82.5805 21.7998 82.3846 21.8677 82.2261 21.9927L80.1294 23.4903C79.9426 23.5808 79.7909 23.7307 79.698 23.9164C79.6051 24.1021 79.5763 24.3134 79.616 24.5172Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M77.3481 21.0088C77.4843 21.1876 77.6799 21.3117 77.8996 21.3588C78.1194 21.4059 78.3487 21.3728 78.5462 21.2656L80.7712 19.9819C80.9532 19.8898 81.0963 19.7357 81.1748 19.5475C81.2532 19.3592 81.2618 19.1491 81.1991 18.955V18.8266C81.1202 18.5999 80.9701 18.4048 80.7712 18.2704H80.5572C80.3892 18.1486 80.1871 18.083 79.9796 18.083C79.7721 18.083 79.5699 18.1486 79.4019 18.2704L77.5192 19.9819C77.3876 20.1141 77.3005 20.284 77.2698 20.4681C77.2391 20.6521 77.2665 20.8411 77.3481 21.0088Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M74.6951 17.7572C74.8705 17.8996 75.0896 17.9774 75.3155 17.9774C75.5415 17.9774 75.7605 17.8996 75.9359 17.7572L78.0326 16.2168C78.2016 16.1033 78.3246 15.9332 78.3795 15.7372C78.4344 15.5411 78.4176 15.3319 78.3321 15.1471V14.8904C78.2369 14.6715 78.0712 14.4908 77.8614 14.3769H77.6047C77.4198 14.2936 77.2137 14.2695 77.0146 14.3077C76.8154 14.346 76.633 14.4449 76.4922 14.5909L74.7807 16.5163C74.6257 16.6817 74.5326 16.8955 74.517 17.1215C74.5014 17.3476 74.5643 17.5721 74.6951 17.7572Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M71.7428 14.8044C71.9182 14.9468 72.1373 15.0246 72.3632 15.0246C72.5892 15.0246 72.8083 14.9468 72.9837 14.8044L74.9092 13.05C75.0238 12.8953 75.0857 12.7078 75.0857 12.5152C75.0857 12.3226 75.0238 12.1351 74.9092 11.9803V11.7236C74.7953 11.5138 74.6146 11.3481 74.3957 11.2529H74.0962C73.9114 11.1674 73.7022 11.1506 73.5061 11.2055C73.31 11.2604 73.14 11.3834 73.0265 11.5524L71.5289 13.6491C71.4282 13.8374 71.3946 14.0543 71.4335 14.2642C71.4723 14.4741 71.5814 14.6646 71.7428 14.8044Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M68.4909 12.1514C68.6625 12.3018 68.883 12.3847 69.1113 12.3847C69.3396 12.3847 69.56 12.3018 69.7317 12.1514L71.4433 10.2259C71.5682 10.0674 71.6361 9.87144 71.6361 9.66962C71.6361 9.46781 71.5682 9.27187 71.4433 9.11337C71.384 9.00838 71.3042 8.91639 71.2086 8.84288C71.1131 8.76937 71.0037 8.71584 70.887 8.68549H70.5875C70.3884 8.64228 70.1807 8.6648 69.9956 8.74966C69.8104 8.83453 69.6578 8.97717 69.5606 9.15616L68.2769 11.3812C68.2507 11.5173 68.2564 11.6577 68.2935 11.7914C68.3306 11.925 68.3982 12.0482 68.4909 12.1514Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M64.9821 9.88341C65.1796 9.99067 65.4089 10.0237 65.6286 9.97666C65.8484 9.92957 66.044 9.80544 66.1802 9.62669L67.7206 7.53005C67.8328 7.36626 67.8929 7.17235 67.8929 6.9738C67.8929 6.77525 67.8328 6.58134 67.7206 6.41755C67.66 6.31257 67.5726 6.22556 67.4673 6.16542C67.3621 6.10528 67.2428 6.07416 67.1215 6.07524H66.8648C66.6665 6.0299 66.4585 6.05668 66.2782 6.15077C66.0979 6.24486 65.9569 6.40012 65.8807 6.58871L64.811 8.94207C64.7479 9.09917 64.7306 9.27087 64.7608 9.43741C64.7911 9.60396 64.8678 9.75856 64.9821 9.88341Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M61.2596 7.95828C61.47 8.06375 61.7133 8.0825 61.9373 8.01049C62.1614 7.93848 62.3482 7.78147 62.4577 7.57319L63.6986 5.34819C63.8235 5.18969 63.8914 4.99375 63.8914 4.79194C63.8914 4.59012 63.8235 4.39418 63.6986 4.23569C63.5292 4.05965 63.3004 3.95289 63.0567 3.93617H62.7572C62.5542 3.91038 62.3488 3.96214 62.1823 4.08112C62.0157 4.2001 61.9001 4.37754 61.8587 4.57799L61.0457 7.01694C60.9881 7.17794 60.9776 7.35203 61.0155 7.51878C61.0534 7.68552 61.1381 7.83799 61.2596 7.95828Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M57.3657 6.46078C57.5757 6.54544 57.8107 6.54345 58.0192 6.45523C58.2277 6.36702 58.3928 6.19977 58.4782 5.9901L59.5052 3.63674C59.5902 3.44879 59.6111 3.23812 59.5647 3.03712C59.5183 2.83613 59.4072 2.65593 59.2484 2.52424H59.0345C58.9528 2.43925 58.8518 2.37538 58.74 2.33811C58.6282 2.30084 58.509 2.2913 58.3927 2.3103H58.0931C57.8989 2.33212 57.7162 2.41388 57.5705 2.54423C57.4249 2.67458 57.3234 2.84705 57.2802 3.0377L56.7239 5.56222C56.7156 5.76206 56.7743 5.95896 56.8905 6.12172C57.0068 6.28448 57.174 6.4038 57.3657 6.46078Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M53.3009 5.39122C53.5214 5.43561 53.7505 5.39961 53.9467 5.28973C54.1429 5.17985 54.2933 5.00335 54.3707 4.79218L55.1836 2.31045C55.2418 2.12078 55.2356 1.91718 55.1659 1.73142C55.0963 1.54566 54.9671 1.38817 54.7985 1.28353C54.574 1.22555 54.3384 1.22555 54.1139 1.28353H53.8144C53.6087 1.20658 53.382 1.20762 53.177 1.28646C52.972 1.36529 52.803 1.51646 52.7019 1.71141L52.4452 4.23593C52.4053 4.36962 52.3971 4.51075 52.4213 4.64814C52.4454 4.78554 52.5012 4.91543 52.5842 5.02753C52.6673 5.13962 52.7753 5.23085 52.8997 5.29397C53.0241 5.3571 53.1614 5.39039 53.3009 5.39122Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M49.1504 4.74954C49.3827 4.7869 49.6204 4.73164 49.8125 4.59563C50.0045 4.45961 50.1355 4.2537 50.1773 4.02214L50.6908 1.49762C50.7416 1.29843 50.7226 1.08783 50.637 0.900943C50.5513 0.714059 50.4042 0.562198 50.2201 0.470694H49.9634C49.7433 0.384769 49.4989 0.384769 49.2788 0.470694H49.022C48.8294 0.532045 48.6604 0.651197 48.5379 0.81196C48.4155 0.972724 48.3454 1.1673 48.3374 1.36925V3.93656C48.3641 4.14285 48.4583 4.3345 48.6054 4.48159C48.7524 4.62867 48.9441 4.72289 49.1504 4.74954Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                </svg>
                <span class="timer__num">01</span>
            </div>
            <div class="timer__caption">{{ $store.state.translation.timer.hours }}</div>
        </div>
        <div class="timer__item timer__item--mins">
            <div class="timer__num-block">
                <svg width="90" height="89" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M45 4.53531C45.2281 4.52586 45.445 4.43386 45.6103 4.27643C45.7756 4.11901 45.878 3.90688 45.8986 3.67954L46.1981 1.11223C46.2117 0.911957 46.1609 0.712593 46.0531 0.543242C45.9453 0.373891 45.7862 0.243424 45.5991 0.170889H45.3423C45.1222 0.0849641 44.8778 0.0849641 44.6577 0.170889H44.401C44.2138 0.243424 44.0547 0.373891 43.947 0.543242C43.8392 0.712593 43.7884 0.911957 43.802 1.11223L44.0587 3.67954C44.0889 3.91052 44.2001 4.12328 44.3725 4.27998C44.5449 4.43668 44.7672 4.52715 45 4.53531Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />

                    <path
                        d="M40.8068 4.74893C41.0378 4.71869 41.2505 4.6075 41.4072 4.43513C41.5639 4.26276 41.6544 4.04039 41.6626 3.80759V1.24028C41.6488 1.03998 41.5766 0.848112 41.4549 0.68841C41.3332 0.528707 41.1674 0.408175 40.978 0.341724H40.6784C40.4576 0.25979 40.2147 0.25979 39.9938 0.341724H39.7371C39.5627 0.443851 39.4239 0.597196 39.3397 0.780926C39.2555 0.964655 39.2299 1.16986 39.2664 1.36865L39.8227 3.89317C39.8279 4.01509 39.8579 4.13466 39.9109 4.24459C39.9639 4.35453 40.0387 4.45252 40.1308 4.5326C40.2229 4.61267 40.3303 4.67315 40.4465 4.71034C40.5627 4.74754 40.6853 4.76067 40.8068 4.74893Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M36.6989 5.39152C36.9255 5.34028 37.1252 5.20705 37.2595 5.01747C37.3938 4.8279 37.4532 4.59536 37.4264 4.3646L37.1268 1.71171C37.1176 1.50627 37.0346 1.31104 36.8932 1.16175C36.7517 1.01246 36.5613 0.919091 36.3566 0.898735H36.0999C35.8762 0.834536 35.639 0.834536 35.4153 0.898735H35.1586C34.9919 1.00423 34.8668 1.16407 34.8044 1.35119C34.742 1.53832 34.7462 1.74126 34.8163 1.92566L35.5864 4.40739C35.5917 4.54612 35.6251 4.68234 35.6845 4.80781C35.744 4.93329 35.8282 5.04541 35.9322 5.13739C36.0362 5.22938 36.1578 5.29933 36.2895 5.34301C36.4213 5.38669 36.5606 5.40319 36.6989 5.39152Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M32.6341 6.46123C32.8587 6.40072 33.0512 6.25542 33.1709 6.05591C33.2906 5.8564 33.3282 5.61823 33.2759 5.39152L32.7196 2.86701C32.6741 2.6699 32.5663 2.49271 32.4121 2.36169C32.258 2.23067 32.0657 2.1528 31.8639 2.1396H31.6071C31.4842 2.11705 31.3577 2.12474 31.2384 2.16201C31.1191 2.19928 31.0107 2.26502 30.9225 2.35354H30.7086C30.5554 2.48927 30.4484 2.66936 30.4024 2.86876C30.3564 3.06815 30.3737 3.27693 30.4519 3.46604L31.3076 5.99056C31.3515 6.11858 31.4237 6.23504 31.5188 6.33124C31.614 6.42745 31.7297 6.50092 31.8572 6.54618C31.9847 6.59143 32.1208 6.60729 32.2553 6.59258C32.3899 6.57788 32.5193 6.53297 32.6341 6.46123Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />

                    <path
                        d="M28.7404 7.95849C28.9447 7.86486 29.1088 7.70125 29.2029 7.49722C29.2971 7.29318 29.3152 7.06219 29.2539 6.84599L28.4409 4.40705C28.3824 4.20775 28.2566 4.03488 28.0849 3.91787C27.9133 3.80085 27.7065 3.74691 27.4995 3.76522H27.2428C27.1211 3.76906 27.0015 3.79759 26.8911 3.84907C26.7808 3.90056 26.6821 3.97394 26.601 4.06474C26.4888 4.22853 26.4287 4.42244 26.4287 4.62099C26.4287 4.81954 26.4888 5.01345 26.601 5.17724L27.8846 7.40224C27.954 7.57082 28.073 7.71431 28.2259 7.81366C28.3787 7.91302 28.5582 7.96353 28.7404 7.95849Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M25.0178 9.88429C25.2058 9.7556 25.3456 9.56804 25.4153 9.35115C25.485 9.13426 25.4807 8.90034 25.4029 8.68621L24.3331 6.33285C24.2569 6.14426 24.116 5.989 23.9356 5.89491C23.7553 5.80082 23.5473 5.77404 23.349 5.81939H23.0923C22.9711 5.8183 22.8517 5.84942 22.7465 5.90956C22.6412 5.9697 22.5538 6.05671 22.4932 6.16169H22.2793C22.1544 6.32019 22.0864 6.51613 22.0864 6.71794C22.0864 6.91976 22.1544 7.1157 22.2793 7.27419L23.7769 9.37083C23.8802 9.59764 24.0658 9.77679 24.2961 9.87209C24.5265 9.96739 24.7844 9.97175 25.0178 9.88429Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M21.5091 12.1521C21.6878 12.0159 21.812 11.8203 21.859 11.6006C21.9061 11.3808 21.873 11.1515 21.7658 10.954L20.4821 8.72903C20.3775 8.54657 20.2172 8.40242 20.0247 8.31772C19.8322 8.23301 19.6176 8.21221 19.4124 8.25836H19.1557C19.0369 8.2836 18.9253 8.33514 18.829 8.40919C18.7328 8.48323 18.6543 8.5779 18.5994 8.68624C18.4494 8.75074 18.3197 8.85491 18.2244 8.98758C18.1291 9.12024 18.0718 9.27637 18.0586 9.43918C18.0454 9.60199 18.0768 9.76531 18.1495 9.9116C18.2222 10.0579 18.3334 10.1816 18.4711 10.2694L20.1826 12.1949C20.3645 12.3644 20.6061 12.455 20.8547 12.447C21.1032 12.439 21.3384 12.333 21.5091 12.1521Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M18.4712 14.805C18.6136 14.6296 18.6914 14.4105 18.6914 14.1845C18.6914 13.9586 18.6136 13.7395 18.4712 13.5641L16.9308 11.4675C16.8173 11.2985 16.6472 11.1755 16.4511 11.1206C16.2551 11.0657 16.0459 11.0825 15.8611 11.168H15.6044C15.3793 11.2534 15.1956 11.4219 15.0909 11.6386V11.8954C15.0043 12.0724 14.9783 12.2731 15.017 12.4664C15.0556 12.6597 15.1568 12.8349 15.3048 12.9651L17.1875 14.7194C17.3598 14.8778 17.5809 14.9726 17.8143 14.9881C18.0477 15.0037 18.2795 14.9391 18.4712 14.805Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M15.3049 17.7572C15.4554 17.5855 15.5383 17.365 15.5383 17.1368C15.5383 16.9085 15.4554 16.688 15.3049 16.5163L13.5934 14.5909C13.4526 14.4449 13.2701 14.346 13.071 14.3077C12.8719 14.2695 12.6658 14.2936 12.4809 14.3769H12.2241C12.026 14.4929 11.8746 14.6745 11.7962 14.8904V15.1471C11.7308 15.3385 11.7287 15.5459 11.7904 15.7386C11.852 15.9313 11.9741 16.099 12.1386 16.2168L14.1924 17.7572C14.3582 17.8641 14.5514 17.9209 14.7486 17.9209C14.9459 17.9209 15.1391 17.8641 15.3049 17.7572Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M12.652 21.0086C12.8025 20.8369 12.8854 20.6164 12.8854 20.3881C12.8854 20.1599 12.8025 19.9394 12.652 19.7677L10.7265 18.0562C10.568 17.9312 10.3721 17.8633 10.1703 17.8633C9.96847 17.8633 9.77253 17.9312 9.61403 18.0562H9.3573C9.16979 18.1928 9.03405 18.3888 8.9722 18.6124V18.9119C8.93161 19.1063 8.95076 19.3084 9.02712 19.4916C9.10349 19.6749 9.2335 19.8308 9.40009 19.9389L11.6251 21.2225C11.799 21.2873 11.9877 21.3016 12.1694 21.2638C12.3511 21.2259 12.5184 21.1374 12.652 21.0086Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M10.3841 24.5173C10.4943 24.3133 10.529 24.077 10.482 23.85C10.435 23.623 10.3094 23.4199 10.1274 23.2764L8.03072 21.7788C7.87222 21.6539 7.67628 21.5859 7.47447 21.5859C7.27265 21.5859 7.07671 21.6539 6.91822 21.7788H6.66148C6.47568 21.928 6.35335 22.142 6.31918 22.3778V22.6346C6.29283 22.8367 6.33282 23.042 6.43313 23.2194C6.53344 23.3969 6.68865 23.537 6.87543 23.6187L9.22879 24.6884C9.4144 24.787 9.62669 24.8235 9.83457 24.7927C10.0425 24.7619 10.235 24.6654 10.3841 24.5173Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M8.45851 28.24C8.56398 28.0297 8.58273 27.7863 8.51072 27.5623C8.43871 27.3383 8.2817 27.1515 8.07342 27.042L5.84842 25.7583C5.68992 25.6334 5.49398 25.5654 5.29217 25.5654C5.09035 25.5654 4.89441 25.6334 4.73592 25.7583C4.65084 25.8416 4.58407 25.9416 4.53986 26.0521C4.49566 26.1627 4.47499 26.2812 4.47919 26.4001V26.6996C4.46057 26.8955 4.50997 27.0917 4.61907 27.2553C4.72817 27.419 4.89032 27.5401 5.07823 27.5982L7.55996 28.4112C7.71193 28.4614 7.8742 28.472 8.03142 28.4421C8.18865 28.4121 8.33564 28.3426 8.45851 28.24Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M6.96109 32.1336C7.0019 32.0269 7.02095 31.9132 7.01713 31.7991C7.01331 31.685 6.9867 31.5728 6.93885 31.4691C6.891 31.3654 6.82289 31.2724 6.73853 31.1954C6.65416 31.1185 6.55525 31.0592 6.44763 31.0211L4.09426 29.9514C3.91271 29.8696 3.70914 29.8504 3.51552 29.8969C3.32191 29.9434 3.14921 30.0528 3.02455 30.2081V30.422C2.97477 30.5293 2.94897 30.6461 2.94897 30.7643C2.94897 30.8826 2.97477 30.9994 3.02455 31.1066V31.3634C3.03775 31.5652 3.11563 31.7575 3.24664 31.9116C3.37766 32.0658 3.55485 32.1736 3.75196 32.2191L6.27647 32.7754C6.43865 32.7419 6.58841 32.6642 6.70922 32.5509C6.83003 32.4377 6.9172 32.2932 6.96109 32.1336Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.89138 36.1987C5.9437 35.972 5.90605 35.7338 5.78634 35.5343C5.66663 35.3348 5.47421 35.1895 5.24955 35.129L2.81061 34.316C2.61437 34.2616 2.4061 34.2695 2.21452 34.3384C2.02293 34.4074 1.85745 34.5341 1.74089 34.7011V34.915C1.65896 35.1359 1.65896 35.3788 1.74089 35.5996V35.942C1.78644 36.1391 1.89427 36.3162 2.04841 36.4473C2.20255 36.5783 2.39479 36.6562 2.59666 36.6694L5.12118 36.9689C5.31355 36.9362 5.491 36.8445 5.62898 36.7065C5.76696 36.5685 5.85866 36.3911 5.89138 36.1987Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.24948 40.2208C5.2637 39.991 5.19318 39.7639 5.05124 39.5825C4.90931 39.4011 4.70582 39.2781 4.47928 39.2367L1.99755 38.6804C1.79372 38.6408 1.58249 38.6696 1.39675 38.7625C1.21101 38.8553 1.0612 39.007 0.97063 39.1939C0.888696 39.4147 0.888696 39.6577 0.97063 39.8785V40.178C1.03198 40.3706 1.15114 40.5396 1.3119 40.6621C1.47266 40.7846 1.66724 40.8546 1.86919 40.8626H4.4365C4.61828 40.8418 4.79065 40.7707 4.93426 40.6573C5.07787 40.544 5.18702 40.3928 5.24948 40.2208Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M4.99277 44.4997C4.99491 44.2708 4.91354 44.0489 4.76389 43.8757C4.61425 43.7024 4.40659 43.5896 4.17979 43.5584L1.61248 43.3017C1.41102 43.2808 1.20843 43.3286 1.03756 43.4374C0.866692 43.5461 0.737568 43.7094 0.671133 43.9007V44.1574C0.585208 44.3775 0.585208 44.6219 0.671133 44.842V45.0988C0.737568 45.2901 0.866692 45.4534 1.03756 45.5621C1.20843 45.6708 1.41102 45.7187 1.61248 45.6978L4.17979 45.4411C4.40659 45.4099 4.61425 45.2971 4.76389 45.1238C4.91354 44.9505 4.99491 44.7287 4.99277 44.4997Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.24942 48.7786C5.21918 48.5476 5.10799 48.3349 4.93562 48.1782C4.76325 48.0215 4.54088 47.931 4.30808 47.9229H1.74077C1.53882 47.9309 1.34424 48.0009 1.18348 48.1234C1.02272 48.2459 0.903563 48.4149 0.842212 48.6075V48.907C0.760278 49.1278 0.760278 49.3708 0.842212 49.5916C0.932779 49.7785 1.08259 49.9302 1.26833 50.023C1.45407 50.1159 1.6653 50.1447 1.86913 50.1051L4.35087 49.5488C4.56615 49.5426 4.77279 49.4628 4.93632 49.3226C5.09984 49.1824 5.21035 48.9904 5.24942 48.7786Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.89122 52.8011C5.83997 52.5745 5.70675 52.3749 5.51717 52.2406C5.32759 52.1063 5.09505 52.0468 4.86429 52.0737L2.33977 52.3732C2.12174 52.3552 1.90423 52.4137 1.72463 52.5386C1.54504 52.6635 1.41456 52.8471 1.35564 53.0578V53.3146C1.27371 53.5354 1.27371 53.7783 1.35564 53.9992V54.2131C1.47219 54.3801 1.63767 54.5068 1.82926 54.5758C2.02085 54.6447 2.22912 54.6526 2.42535 54.5982L4.86429 53.828C5.00077 53.8341 5.13701 53.8117 5.26435 53.7622C5.39169 53.7128 5.50734 53.6373 5.60394 53.5407C5.70054 53.4441 5.77597 53.3285 5.82543 53.2012C5.87488 53.0738 5.89729 52.9376 5.89122 52.8011Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M6.96093 56.866C6.90042 56.6414 6.75512 56.4489 6.55561 56.3292C6.35609 56.2095 6.11793 56.1719 5.89122 56.2242L3.3667 56.7804C3.1696 56.826 2.99241 56.9338 2.86139 57.088C2.73037 57.2421 2.65249 57.4343 2.6393 57.6362V57.8929C2.58951 58.0002 2.56372 58.117 2.56372 58.2352C2.56372 58.3535 2.58951 58.4703 2.6393 58.5776V58.7915C2.76396 58.9468 2.93666 59.0562 3.13027 59.1027C3.32389 59.1492 3.52746 59.13 3.70901 59.0482L6.06238 57.9785C6.2035 57.9924 6.34584 57.9709 6.47662 57.9161C6.6074 57.8613 6.72252 57.7749 6.81162 57.6645C6.90071 57.5542 6.961 57.4235 6.98707 57.2841C7.01313 57.1447 7.00415 57.0011 6.96093 56.866Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M8.45859 60.7591C8.36496 60.5548 8.20135 60.3908 7.99731 60.2966C7.79328 60.2024 7.56228 60.1844 7.34608 60.2457L4.86435 61.0586C4.67645 61.1168 4.51431 61.2378 4.4052 61.4015C4.2961 61.5651 4.2467 61.7614 4.26532 61.9572V62.2567C4.26112 62.3757 4.28179 62.4942 4.32599 62.6047C4.3702 62.7152 4.43697 62.8153 4.52205 62.8985C4.68054 63.0235 4.87648 63.0914 5.0783 63.0914C5.28011 63.0914 5.47605 63.0235 5.63455 62.8985L7.85955 61.6149C8.03613 61.5525 8.18879 61.4364 8.2962 61.283C8.4036 61.1296 8.46038 60.9464 8.45859 60.7591Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M10.3841 64.4817C10.2609 64.2877 10.0735 64.1429 9.8547 64.0726C9.63587 64.0023 9.39931 64.0107 9.18607 64.0966L6.8327 65.1663C6.64592 65.248 6.49071 65.3881 6.3904 65.5656C6.29009 65.743 6.2501 65.9483 6.27645 66.1504V66.4072C6.31063 66.643 6.43296 66.857 6.61876 67.0062H6.87549C7.03399 67.1311 7.22993 67.1991 7.43174 67.1991C7.63356 67.1991 7.8295 67.1311 7.98799 67.0062L10.0846 65.5086C10.2283 65.3845 10.333 65.2216 10.3861 65.0394C10.4392 64.8572 10.4386 64.6635 10.3841 64.4817Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M12.6518 67.9903C12.5084 67.8082 12.3052 67.6826 12.0782 67.6356C11.8512 67.5887 11.6149 67.6234 11.411 67.7336L9.18598 69.0172C9.01939 69.1252 8.88938 69.2811 8.81301 69.4644C8.73664 69.6477 8.7175 69.8498 8.75809 70.0441V70.3437C8.81994 70.5672 8.95568 70.7633 9.14319 70.8999H9.39992C9.55493 71.0325 9.7522 71.1053 9.95617 71.1053C10.1601 71.1053 10.3574 71.0325 10.5124 70.8999L12.4379 69.1884C12.6096 69.0463 12.7253 68.8479 12.7645 68.6285C12.8037 68.4092 12.7638 68.183 12.6518 67.9903Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M15.3047 71.2429C15.1225 71.097 14.8962 71.0176 14.6628 71.0176C14.4295 71.0176 14.2032 71.097 14.021 71.2429L11.9672 72.7832C11.8027 72.9011 11.6807 73.0688 11.619 73.2615C11.5573 73.4541 11.5594 73.6615 11.6249 73.853V74.1097C11.7032 74.3255 11.8546 74.5072 12.0527 74.6232H12.3095C12.4944 74.7065 12.7005 74.7306 12.8996 74.6923C13.0987 74.654 13.2812 74.5551 13.422 74.4092L15.1335 72.4837C15.3093 72.3339 15.4239 72.1247 15.4554 71.8959C15.487 71.6671 15.4333 71.4347 15.3047 71.2429Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M18.4712 74.195C18.2891 74.0492 18.0627 73.9697 17.8294 73.9697C17.596 73.9697 17.3697 74.0492 17.1875 74.195L15.3048 75.9493C15.1568 76.0795 15.0556 76.2547 15.017 76.448C14.9783 76.6413 15.0043 76.842 15.0909 77.019V77.2758C15.1956 77.4925 15.3793 77.661 15.6044 77.7465H15.8611C16.0459 77.8319 16.2551 77.8487 16.4511 77.7938C16.6472 77.7389 16.8173 77.6159 16.9308 77.4469L18.4712 75.3503C18.593 75.1823 18.6585 74.9801 18.6585 74.7727C18.6585 74.5652 18.593 74.363 18.4712 74.195Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M21.5092 76.8477C21.3375 76.6972 21.117 76.6143 20.8887 76.6143C20.6605 76.6143 20.44 76.6972 20.2683 76.8477L18.4712 78.7303C18.3463 78.8888 18.2783 79.0848 18.2783 79.2866C18.2783 79.4884 18.3463 79.6844 18.4712 79.8428V80.0568C18.5261 80.1651 18.6045 80.2598 18.7008 80.3338C18.797 80.4079 18.9087 80.4594 19.0274 80.4847H19.2842C19.4894 80.5308 19.704 80.51 19.8965 80.4253C20.089 80.3406 20.2493 80.1965 20.3539 80.014L21.6375 77.789C21.7015 77.6348 21.7234 77.4664 21.7008 77.3009C21.6783 77.1355 21.6121 76.9791 21.5092 76.8477Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M25.0178 79.1154C24.8138 79.0052 24.5775 78.9705 24.3505 79.0175C24.1235 79.0644 23.9204 79.19 23.7769 79.3721L22.2793 81.4688C22.1544 81.6273 22.0864 81.8232 22.0864 82.025C22.0864 82.2268 22.1544 82.4228 22.2793 82.5813L22.4932 82.7952C22.5538 82.9002 22.6412 82.9872 22.7465 83.0473C22.8517 83.1075 22.9711 83.1386 23.0923 83.1375H23.349C23.5473 83.1828 23.7553 83.1561 23.9356 83.062C24.116 82.9679 24.2569 82.8126 24.3331 82.624L25.4029 80.2707C25.4703 80.0622 25.4693 79.8377 25.4 79.6298C25.3307 79.4219 25.1968 79.2417 25.0178 79.1154Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M28.7404 81.0406C28.5301 80.9352 28.2867 80.9164 28.0627 80.9884C27.8387 81.0604 27.6519 81.2174 27.5424 81.4257L26.2587 83.6507C26.1465 83.8145 26.0864 84.0084 26.0864 84.207C26.0864 84.4055 26.1465 84.5994 26.2587 84.7632C26.3398 84.854 26.4385 84.9274 26.5489 84.9789C26.6592 85.0304 26.7788 85.0589 26.9005 85.0627H27.1573C27.3642 85.081 27.571 85.0271 27.7427 84.9101C27.9143 84.7931 28.0401 84.6202 28.0986 84.4209L28.9116 81.982C28.9698 81.8241 28.9846 81.6535 28.9545 81.4879C28.9244 81.3224 28.8505 81.1679 28.7404 81.0406Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M32.6341 82.539C32.5193 82.4673 32.3899 82.4224 32.2554 82.4077C32.1208 82.393 31.9847 82.4088 31.8572 82.4541C31.7297 82.4993 31.614 82.5728 31.5189 82.669C31.4237 82.7652 31.3515 82.8817 31.3076 83.0097L30.2807 85.3631C30.2025 85.5522 30.1852 85.7609 30.2313 85.9603C30.2773 86.1597 30.3843 86.3398 30.5374 86.4756H30.7514C30.8578 86.5283 30.9749 86.5558 31.0937 86.5558C31.2125 86.5558 31.3296 86.5283 31.436 86.4756H31.6927C31.8946 86.4624 32.0868 86.3845 32.241 86.2535C32.3951 86.1224 32.503 85.9453 32.5485 85.7481L33.1048 83.2236C33.0872 83.0801 33.0352 82.9429 32.9533 82.8237C32.8713 82.7045 32.7618 82.6068 32.6341 82.539Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M36.6989 83.6081C36.4674 83.5641 36.2277 83.6047 36.0236 83.7225C35.8194 83.8403 35.6643 84.0275 35.5864 84.2499L34.8163 86.6889C34.7462 86.8733 34.742 87.0762 34.8044 87.2633C34.8668 87.4505 34.9919 87.6103 35.1586 87.7158H35.4153C35.639 87.78 35.8762 87.78 36.0999 87.7158H36.3566C36.5613 87.6954 36.7517 87.6021 36.8932 87.4528C37.0346 87.3035 37.1176 87.1083 37.1268 86.9028L37.4264 84.3783C37.3964 84.1917 37.311 84.0185 37.1813 83.8812C37.0515 83.7438 36.8835 83.6486 36.6989 83.6081Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M40.8068 84.2498C40.5812 84.2243 40.3543 84.2851 40.1718 84.4201C39.9892 84.555 39.8645 84.7541 39.8227 84.9772L39.2664 87.5017C39.2299 87.7005 39.2555 87.9057 39.3397 88.0895C39.4239 88.2732 39.5627 88.4266 39.7371 88.5287H39.9938C40.2147 88.6106 40.4576 88.6106 40.6784 88.5287H40.978C41.1674 88.4622 41.3332 88.3417 41.4549 88.182C41.5766 88.0223 41.6488 87.8304 41.6626 87.6301V85.0628C41.627 84.853 41.5244 84.6604 41.3701 84.5138C41.2158 84.3672 41.0182 84.2746 40.8068 84.2498Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M45 84.4639C44.7672 84.472 44.5449 84.5625 44.3725 84.7192C44.2001 84.8759 44.0889 85.0887 44.0587 85.3196L43.802 87.8869C43.7884 88.0872 43.8392 88.2866 43.947 88.4559C44.0547 88.6253 44.2138 88.7558 44.401 88.8283H44.6577C44.8778 88.9142 45.1222 88.9142 45.3423 88.8283H45.5991C45.7862 88.7558 45.9453 88.6253 46.0531 88.4559C46.1609 88.2866 46.2117 88.0872 46.1981 87.8869L45.8986 85.3196C45.878 85.0923 45.7756 84.8802 45.6103 84.7227C45.445 84.5653 45.2281 84.4733 45 84.4639Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M49.1504 84.2505C48.9236 84.2817 48.716 84.3945 48.5663 84.5678C48.4167 84.7411 48.3353 84.9629 48.3374 85.1919V87.7592C48.3455 87.9611 48.4155 88.1557 48.538 88.3165C48.6605 88.4772 48.8295 88.5964 49.0221 88.6577H49.2788C49.4989 88.7437 49.7433 88.7437 49.9634 88.6577H50.2201C50.4042 88.5662 50.5514 88.4144 50.637 88.2275C50.7227 88.0406 50.7417 87.83 50.6908 87.6308L50.1773 85.1063C50.1727 84.9803 50.1415 84.8567 50.0859 84.7435C50.0303 84.6304 49.9515 84.5302 49.8547 84.4495C49.7578 84.3688 49.645 84.3093 49.5237 84.2751C49.4024 84.2408 49.2752 84.2324 49.1504 84.2505Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M53.3008 83.6084C53.0693 83.6502 52.8633 83.7812 52.7273 83.9733C52.5913 84.1653 52.5361 84.403 52.5734 84.6353L52.7018 87.2882C52.7192 87.4909 52.8048 87.6817 52.9447 87.8294C53.0847 87.977 53.2705 88.0729 53.472 88.1012H53.7715C53.996 88.1592 54.2316 88.1592 54.4561 88.1012C54.6247 87.9965 54.7538 87.8391 54.8235 87.6533C54.8932 87.4675 54.8994 87.2639 54.8412 87.0743L54.0282 84.6353C54.0656 84.403 54.0103 84.1653 53.8743 83.9733C53.7383 83.7812 53.5324 83.6502 53.3008 83.6084Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M57.3658 82.5387C57.1411 82.5992 56.9487 82.7445 56.829 82.944C56.7093 83.1435 56.6716 83.3817 56.7239 83.6084L57.2802 86.1329C57.3234 86.3235 57.4249 86.496 57.5706 86.6264C57.7163 86.7567 57.8989 86.8385 58.0932 86.8603H58.3927C58.4926 86.9094 58.6023 86.9349 58.7136 86.9349C58.8249 86.9349 58.9347 86.9094 59.0345 86.8603H59.2485C59.4072 86.7286 59.5184 86.5484 59.5648 86.3474C59.6111 86.1464 59.5902 85.9357 59.5052 85.7478L58.4783 83.0093C58.3928 82.7997 58.2278 82.6324 58.0192 82.5442C57.8107 82.456 57.5758 82.454 57.3658 82.5387Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M61.2596 81.0415C61.0492 81.1276 60.8792 81.2903 60.7839 81.4967C60.6886 81.7032 60.6751 81.938 60.7462 82.154L61.5592 84.593C61.6006 84.7934 61.7162 84.9709 61.8827 85.0899C62.0493 85.2088 62.2546 85.2606 62.4577 85.2348H62.7572C63.0009 85.2181 63.2297 85.1113 63.3991 84.9353C63.524 84.7768 63.5919 84.5809 63.5919 84.379C63.5919 84.1772 63.524 83.9813 63.3991 83.8228L62.1582 81.5978C62.0864 81.422 61.9608 81.2735 61.7993 81.1736C61.6379 81.0737 61.449 81.0274 61.2596 81.0415Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M64.9821 79.1155C64.7738 79.225 64.6168 79.4118 64.5448 79.6358C64.4728 79.8599 64.4916 80.1032 64.597 80.3136L65.6667 82.6669C65.743 82.8555 65.8839 83.0108 66.0643 83.1049C66.2446 83.1989 66.4526 83.2257 66.6509 83.1804H66.9076C67.0288 83.1815 67.1481 83.1503 67.2534 83.0902C67.3586 83.0301 67.446 82.943 67.5066 82.8381V82.6241C67.6189 82.4603 67.6789 82.2664 67.6789 82.0679C67.6789 81.8693 67.6189 81.6754 67.5066 81.5116L65.9663 79.415C65.849 79.275 65.6935 79.1721 65.5187 79.1189C65.344 79.0657 65.1575 79.0645 64.9821 79.1155Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M68.4909 76.848C68.3044 76.9782 68.1743 77.1742 68.1266 77.3965C68.079 77.6189 68.1174 77.851 68.2341 78.0461L69.5178 80.2711C69.615 80.4501 69.7677 80.5927 69.9528 80.6776C70.138 80.7625 70.3457 80.785 70.5447 80.7418H70.8442C70.9609 80.7114 71.0703 80.6579 71.1659 80.5844C71.2614 80.5109 71.3412 80.4189 71.4005 80.3139V80.1C71.5254 79.9415 71.5934 79.7455 71.5934 79.5437C71.5934 79.3419 71.5254 79.146 71.4005 78.9875L69.689 77.062C69.5469 76.8903 69.3485 76.7745 69.1291 76.7354C68.9098 76.6962 68.6836 76.7361 68.4909 76.848Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M71.7427 74.1951C71.5922 74.3667 71.5093 74.5872 71.5093 74.8155C71.5093 75.0438 71.5922 75.2642 71.7427 75.4359L73.2403 77.5326C73.3538 77.7016 73.5239 77.8246 73.7199 77.8795C73.916 77.9344 74.1252 77.9176 74.31 77.8321H74.6095C74.8284 77.7369 75.0091 77.5712 75.123 77.3614V77.1047C75.2376 76.9499 75.2995 76.7624 75.2995 76.5698C75.2995 76.3772 75.2376 76.1897 75.123 76.035L73.1975 74.2806C73.1114 74.1738 73.0038 74.0861 72.8818 74.0233C72.7598 73.9604 72.626 73.9238 72.489 73.9158C72.352 73.9077 72.2148 73.9284 72.0863 73.9765C71.9578 74.0246 71.8407 74.099 71.7427 74.1951Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M74.6953 71.242C74.5529 71.4174 74.4751 71.6365 74.4751 71.8625C74.4751 72.0884 74.5529 72.3075 74.6953 72.4829L76.4068 74.4084C76.5477 74.5543 76.7301 74.6532 76.9292 74.6915C77.1284 74.7298 77.3345 74.7056 77.5193 74.6223H77.7761C77.9859 74.5085 78.1515 74.3277 78.2467 74.1089V73.8521C78.3322 73.6674 78.349 73.4581 78.2941 73.2621C78.2392 73.066 78.1162 72.896 77.9472 72.7824L75.8506 71.242C75.6826 71.1203 75.4804 71.0547 75.2729 71.0547C75.0655 71.0547 74.8633 71.1203 74.6953 71.242Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M77.3482 67.9909C77.1886 68.158 77.0996 68.3802 77.0996 68.6113C77.0996 68.8424 77.1886 69.0646 77.3482 69.2317L79.2309 70.9433C79.3927 71.0791 79.5972 71.1535 79.8085 71.1535C80.0198 71.1535 80.2243 71.0791 80.3862 70.9433H80.6001C80.799 70.8089 80.9491 70.6137 81.028 70.387V70.1731C81.0908 69.979 81.0822 69.7689 81.0037 69.5806C80.9252 69.3923 80.7821 69.2382 80.6001 69.1461L78.3751 67.8625C78.2101 67.7812 78.0249 67.7501 77.8424 67.7729C77.6599 67.7957 77.4881 67.8715 77.3482 67.9909Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M79.6158 64.4822C79.4965 64.6839 79.4567 64.9228 79.5041 65.1523C79.5516 65.3818 79.683 65.5853 79.8726 65.7231L81.9692 67.2207C82.1277 67.3456 82.3236 67.4135 82.5254 67.4135C82.7273 67.4135 82.9232 67.3456 83.0817 67.2207C83.1814 67.1543 83.2642 67.0656 83.3237 66.9616C83.3831 66.8576 83.4174 66.7412 83.424 66.6216V66.3649C83.461 66.1671 83.4305 65.9625 83.3374 65.7841C83.2443 65.6056 83.094 65.4636 82.9105 65.3808L80.5572 64.3111C80.3993 64.2528 80.2287 64.238 80.0631 64.2681C79.8976 64.2982 79.7431 64.3721 79.6158 64.4822Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M81.4985 60.7596C81.4207 60.9737 81.4163 61.2076 81.486 61.4245C81.5557 61.6414 81.6956 61.829 81.8836 61.9577L84.1086 63.2413C84.2766 63.3631 84.4788 63.4287 84.6862 63.4287C84.8937 63.4287 85.0959 63.3631 85.2639 63.2413C85.3547 63.1602 85.4281 63.0615 85.4796 62.9512C85.531 62.8408 85.5596 62.7212 85.5634 62.5995V62.3C85.5586 62.1032 85.4948 61.9124 85.3804 61.7522C85.266 61.592 85.1062 61.4698 84.9216 61.4014L82.6538 60.2461C82.4316 60.1824 82.194 60.1989 81.9828 60.2928C81.7716 60.3867 81.6001 60.552 81.4985 60.7596Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M82.9961 56.8656C82.9349 57.0818 82.9529 57.3128 83.0471 57.5169C83.1413 57.7209 83.3053 57.8845 83.5096 57.9781L85.863 59.0478C86.0521 59.126 86.2609 59.1433 86.4603 59.0973C86.6596 59.0513 86.8397 58.9443 86.9755 58.7911V58.5772C87.1267 58.3821 87.2027 58.1391 87.1894 57.8926V57.6358C87.1738 57.4281 87.0898 57.2313 86.9504 57.0765C86.811 56.9216 86.6242 56.8174 86.4192 56.7801L83.9375 56.2238C83.7334 56.22 83.5332 56.2797 83.3645 56.3946C83.1958 56.5096 83.0671 56.6742 82.9961 56.8656Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.1087 52.8003C84.0528 53.027 84.0831 53.2664 84.1938 53.472C84.3045 53.6776 84.4877 53.8347 84.7077 53.9128L87.1467 54.683C87.3426 54.7442 87.5531 54.7397 87.7462 54.6702C87.9393 54.6006 88.1044 54.4699 88.2164 54.2979V54.0839C88.3023 53.8638 88.3023 53.6194 88.2164 53.3993V53.057C88.196 52.8524 88.1026 52.6619 87.9534 52.5205C87.8041 52.379 87.6088 52.2961 87.4034 52.2868L84.8361 51.9873C84.6424 52.0243 84.4663 52.1239 84.3349 52.2708C84.2035 52.4177 84.124 52.6037 84.1087 52.8003Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.7506 48.7785C84.7251 49.0041 84.7859 49.231 84.9208 49.4136C85.0558 49.5961 85.2548 49.7209 85.478 49.7627L88.0025 50.3189C88.2059 50.3516 88.4143 50.3196 88.5986 50.2275C88.7828 50.1354 88.9335 49.9878 89.0294 49.8055C89.1153 49.5853 89.1153 49.341 89.0294 49.1209V48.8213C88.9681 48.6288 88.8489 48.4598 88.6881 48.3373C88.5274 48.2148 88.3328 48.1448 88.1308 48.1367H85.5635C85.3818 48.1576 85.2094 48.2286 85.0658 48.342C84.9222 48.4554 84.813 48.6066 84.7506 48.7785Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.9644 44.4999C84.9725 44.7327 85.063 44.9551 85.2197 45.1275C85.3764 45.2999 85.5891 45.411 85.8201 45.4413L88.3446 45.698C88.5508 45.7142 88.7569 45.6651 88.9335 45.5576C89.1102 45.45 89.2485 45.2896 89.3288 45.099V44.8422C89.4147 44.6221 89.4147 44.3777 89.3288 44.1576V43.9009C89.2485 43.7103 89.1102 43.5499 88.9335 43.4423C88.7569 43.3348 88.5508 43.2856 88.3446 43.3019L85.8201 43.5586C85.5891 43.5888 85.3764 43.7 85.2197 43.8724C85.063 44.0448 84.9725 44.2671 84.9644 44.4999Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.7505 40.2213C84.7807 40.4522 84.8919 40.665 85.0643 40.8217C85.2367 40.9784 85.459 41.0689 85.6918 41.077H88.2591C88.4611 41.069 88.6557 40.999 88.8164 40.8765C88.9772 40.754 89.0963 40.585 89.1577 40.3924V40.2213C89.2436 40.0011 89.2436 39.7568 89.1577 39.5366C89.0618 39.3544 88.9111 39.2068 88.7269 39.1146C88.5426 39.0225 88.3341 38.9905 88.1308 39.0232L85.6062 39.5794C85.4137 39.5806 85.2265 39.6436 85.0724 39.7591C84.9184 39.8747 84.8055 40.0367 84.7505 40.2213Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.1086 36.199C84.1504 36.4221 84.2751 36.6211 84.4577 36.7561C84.6402 36.891 84.8671 36.9519 85.0927 36.9264L87.66 36.6268C87.859 36.6191 88.049 36.5422 88.1974 36.4095C88.3458 36.2767 88.4433 36.0963 88.473 35.8994V35.5999C88.5589 35.3798 88.5589 35.1354 88.473 34.9153V34.7014C88.361 34.5293 88.1959 34.3986 88.0028 34.3291C87.8097 34.2596 87.5992 34.2551 87.4033 34.3163L84.9643 35.1292C84.8341 35.1331 84.7062 35.1652 84.5896 35.2233C84.4729 35.2815 84.3704 35.3643 84.2889 35.4661C84.2075 35.5679 84.1492 35.6861 84.1181 35.8127C84.0869 35.9392 84.0837 36.071 84.1086 36.199Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M82.9961 32.1338C83.0739 32.3563 83.229 32.5435 83.4332 32.6612C83.6374 32.779 83.877 32.8196 84.1086 32.7757L86.5903 32.2194C86.7953 32.1821 86.9821 32.0779 87.1215 31.923C87.2608 31.7681 87.3449 31.5714 87.3605 31.3636V31.1069C87.3738 30.8604 87.2978 30.6174 87.1465 30.4223V30.2083C87.0108 30.0552 86.8307 29.9482 86.6313 29.9022C86.4319 29.8561 86.2232 29.8734 86.034 29.9516L83.6807 31.0213C83.4448 31.0821 83.2417 31.232 83.114 31.4395C82.9863 31.647 82.9441 31.8958 82.9961 32.1338Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M81.4985 28.2399C81.6001 28.4475 81.7716 28.6128 81.9828 28.7066C82.1941 28.8005 82.4316 28.8171 82.6538 28.7533L85.0928 27.9403C85.2773 27.8719 85.4372 27.7498 85.5516 27.5896C85.666 27.4294 85.7298 27.2386 85.7346 27.0418V26.7423C85.7308 26.6206 85.7022 26.5009 85.6507 26.3906C85.5992 26.2803 85.5259 26.1816 85.4351 26.1005C85.2671 25.9787 85.0649 25.9131 84.8574 25.9131C84.6499 25.9131 84.4478 25.9787 84.2798 26.1005L82.0548 27.3841C81.8955 27.4659 81.7604 27.5879 81.6628 27.738C81.5652 27.8881 81.5086 28.0611 81.4985 28.2399Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M79.616 24.5172C79.733 24.7179 79.92 24.8683 80.1412 24.9394C80.3624 25.0105 80.602 24.9973 80.814 24.9023L83.1674 23.8326C83.3508 23.7498 83.5012 23.6077 83.5943 23.4293C83.6874 23.2508 83.7179 23.0463 83.6809 22.8485V22.5917C83.6743 22.4721 83.64 22.3557 83.5805 22.2517C83.5211 22.1477 83.4383 22.059 83.3386 21.9927C83.1801 21.8677 82.9841 21.7998 82.7823 21.7998C82.5805 21.7998 82.3846 21.8677 82.2261 21.9927L80.1294 23.4903C79.9426 23.5808 79.7909 23.7307 79.698 23.9164C79.6051 24.1021 79.5763 24.3134 79.616 24.5172Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M77.3481 21.0088C77.4843 21.1876 77.6799 21.3117 77.8996 21.3588C78.1194 21.4059 78.3487 21.3728 78.5462 21.2656L80.7712 19.9819C80.9532 19.8898 81.0963 19.7357 81.1748 19.5475C81.2532 19.3592 81.2618 19.1491 81.1991 18.955V18.8266C81.1202 18.5999 80.9701 18.4048 80.7712 18.2704H80.5572C80.3892 18.1486 80.1871 18.083 79.9796 18.083C79.7721 18.083 79.5699 18.1486 79.4019 18.2704L77.5192 19.9819C77.3876 20.1141 77.3005 20.284 77.2698 20.4681C77.2391 20.6521 77.2665 20.8411 77.3481 21.0088Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M74.6951 17.7572C74.8705 17.8996 75.0896 17.9774 75.3155 17.9774C75.5415 17.9774 75.7605 17.8996 75.9359 17.7572L78.0326 16.2168C78.2016 16.1033 78.3246 15.9332 78.3795 15.7372C78.4344 15.5411 78.4176 15.3319 78.3321 15.1471V14.8904C78.2369 14.6715 78.0712 14.4908 77.8614 14.3769H77.6047C77.4198 14.2936 77.2137 14.2695 77.0146 14.3077C76.8154 14.346 76.633 14.4449 76.4922 14.5909L74.7807 16.5163C74.6257 16.6817 74.5326 16.8955 74.517 17.1215C74.5014 17.3476 74.5643 17.5721 74.6951 17.7572Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M71.7428 14.8044C71.9182 14.9468 72.1373 15.0246 72.3632 15.0246C72.5892 15.0246 72.8083 14.9468 72.9837 14.8044L74.9092 13.05C75.0238 12.8953 75.0857 12.7078 75.0857 12.5152C75.0857 12.3226 75.0238 12.1351 74.9092 11.9803V11.7236C74.7953 11.5138 74.6146 11.3481 74.3957 11.2529H74.0962C73.9114 11.1674 73.7022 11.1506 73.5061 11.2055C73.31 11.2604 73.14 11.3834 73.0265 11.5524L71.5289 13.6491C71.4282 13.8374 71.3946 14.0543 71.4335 14.2642C71.4723 14.4741 71.5814 14.6646 71.7428 14.8044Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M68.4909 12.1514C68.6625 12.3018 68.883 12.3847 69.1113 12.3847C69.3396 12.3847 69.56 12.3018 69.7317 12.1514L71.4433 10.2259C71.5682 10.0674 71.6361 9.87144 71.6361 9.66962C71.6361 9.46781 71.5682 9.27187 71.4433 9.11337C71.384 9.00838 71.3042 8.91639 71.2086 8.84288C71.1131 8.76937 71.0037 8.71584 70.887 8.68549H70.5875C70.3884 8.64228 70.1807 8.6648 69.9956 8.74966C69.8104 8.83453 69.6578 8.97717 69.5606 9.15616L68.2769 11.3812C68.2507 11.5173 68.2564 11.6577 68.2935 11.7914C68.3306 11.925 68.3982 12.0482 68.4909 12.1514Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M64.9821 9.88341C65.1796 9.99067 65.4089 10.0237 65.6286 9.97666C65.8484 9.92957 66.044 9.80544 66.1802 9.62669L67.7206 7.53005C67.8328 7.36626 67.8929 7.17235 67.8929 6.9738C67.8929 6.77525 67.8328 6.58134 67.7206 6.41755C67.66 6.31257 67.5726 6.22556 67.4673 6.16542C67.3621 6.10528 67.2428 6.07416 67.1215 6.07524H66.8648C66.6665 6.0299 66.4585 6.05668 66.2782 6.15077C66.0979 6.24486 65.9569 6.40012 65.8807 6.58871L64.811 8.94207C64.7479 9.09917 64.7306 9.27087 64.7608 9.43741C64.7911 9.60396 64.8678 9.75856 64.9821 9.88341Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M61.2596 7.95828C61.47 8.06375 61.7133 8.0825 61.9373 8.01049C62.1614 7.93848 62.3482 7.78147 62.4577 7.57319L63.6986 5.34819C63.8235 5.18969 63.8914 4.99375 63.8914 4.79194C63.8914 4.59012 63.8235 4.39418 63.6986 4.23569C63.5292 4.05965 63.3004 3.95289 63.0567 3.93617H62.7572C62.5542 3.91038 62.3488 3.96214 62.1823 4.08112C62.0157 4.2001 61.9001 4.37754 61.8587 4.57799L61.0457 7.01694C60.9881 7.17794 60.9776 7.35203 61.0155 7.51878C61.0534 7.68552 61.1381 7.83799 61.2596 7.95828Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M57.3657 6.46078C57.5757 6.54544 57.8107 6.54345 58.0192 6.45523C58.2277 6.36702 58.3928 6.19977 58.4782 5.9901L59.5052 3.63674C59.5902 3.44879 59.6111 3.23812 59.5647 3.03712C59.5183 2.83613 59.4072 2.65593 59.2484 2.52424H59.0345C58.9528 2.43925 58.8518 2.37538 58.74 2.33811C58.6282 2.30084 58.509 2.2913 58.3927 2.3103H58.0931C57.8989 2.33212 57.7162 2.41388 57.5705 2.54423C57.4249 2.67458 57.3234 2.84705 57.2802 3.0377L56.7239 5.56222C56.7156 5.76206 56.7743 5.95896 56.8905 6.12172C57.0068 6.28448 57.174 6.4038 57.3657 6.46078Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M53.3009 5.39122C53.5214 5.43561 53.7505 5.39961 53.9467 5.28973C54.1429 5.17985 54.2933 5.00335 54.3707 4.79218L55.1836 2.31045C55.2418 2.12078 55.2356 1.91718 55.1659 1.73142C55.0963 1.54566 54.9671 1.38817 54.7985 1.28353C54.574 1.22555 54.3384 1.22555 54.1139 1.28353H53.8144C53.6087 1.20658 53.382 1.20762 53.177 1.28646C52.972 1.36529 52.803 1.51646 52.7019 1.71141L52.4452 4.23593C52.4053 4.36962 52.3971 4.51075 52.4213 4.64814C52.4454 4.78554 52.5012 4.91543 52.5842 5.02753C52.6673 5.13962 52.7753 5.23085 52.8997 5.29397C53.0241 5.3571 53.1614 5.39039 53.3009 5.39122Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M49.1504 4.74954C49.3827 4.7869 49.6204 4.73164 49.8125 4.59563C50.0045 4.45961 50.1355 4.2537 50.1773 4.02214L50.6908 1.49762C50.7416 1.29843 50.7226 1.08783 50.637 0.900943C50.5513 0.714059 50.4042 0.562198 50.2201 0.470694H49.9634C49.7433 0.384769 49.4989 0.384769 49.2788 0.470694H49.022C48.8294 0.532045 48.6604 0.651197 48.5379 0.81196C48.4155 0.972724 48.3454 1.1673 48.3374 1.36925V3.93656C48.3641 4.14285 48.4583 4.3345 48.6054 4.48159C48.7524 4.62867 48.9441 4.72289 49.1504 4.74954Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                </svg>
                <span class="timer__num">01</span>
            </div>
            <div class="timer__caption">{{ $store.state.translation.timer.min }}</div>
        </div>
        <div class="timer__item timer__item--secs">
            <div class="timer__num-block">
                <svg width="90" height="89" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M45 4.53531C45.2281 4.52586 45.445 4.43386 45.6103 4.27643C45.7756 4.11901 45.878 3.90688 45.8986 3.67954L46.1981 1.11223C46.2117 0.911957 46.1609 0.712593 46.0531 0.543242C45.9453 0.373891 45.7862 0.243424 45.5991 0.170889H45.3423C45.1222 0.0849641 44.8778 0.0849641 44.6577 0.170889H44.401C44.2138 0.243424 44.0547 0.373891 43.947 0.543242C43.8392 0.712593 43.7884 0.911957 43.802 1.11223L44.0587 3.67954C44.0889 3.91052 44.2001 4.12328 44.3725 4.27998C44.5449 4.43668 44.7672 4.52715 45 4.53531Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />

                    <path
                        d="M40.8068 4.74893C41.0378 4.71869 41.2505 4.6075 41.4072 4.43513C41.5639 4.26276 41.6544 4.04039 41.6626 3.80759V1.24028C41.6488 1.03998 41.5766 0.848112 41.4549 0.68841C41.3332 0.528707 41.1674 0.408175 40.978 0.341724H40.6784C40.4576 0.25979 40.2147 0.25979 39.9938 0.341724H39.7371C39.5627 0.443851 39.4239 0.597196 39.3397 0.780926C39.2555 0.964655 39.2299 1.16986 39.2664 1.36865L39.8227 3.89317C39.8279 4.01509 39.8579 4.13466 39.9109 4.24459C39.9639 4.35453 40.0387 4.45252 40.1308 4.5326C40.2229 4.61267 40.3303 4.67315 40.4465 4.71034C40.5627 4.74754 40.6853 4.76067 40.8068 4.74893Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M36.6989 5.39152C36.9255 5.34028 37.1252 5.20705 37.2595 5.01747C37.3938 4.8279 37.4532 4.59536 37.4264 4.3646L37.1268 1.71171C37.1176 1.50627 37.0346 1.31104 36.8932 1.16175C36.7517 1.01246 36.5613 0.919091 36.3566 0.898735H36.0999C35.8762 0.834536 35.639 0.834536 35.4153 0.898735H35.1586C34.9919 1.00423 34.8668 1.16407 34.8044 1.35119C34.742 1.53832 34.7462 1.74126 34.8163 1.92566L35.5864 4.40739C35.5917 4.54612 35.6251 4.68234 35.6845 4.80781C35.744 4.93329 35.8282 5.04541 35.9322 5.13739C36.0362 5.22938 36.1578 5.29933 36.2895 5.34301C36.4213 5.38669 36.5606 5.40319 36.6989 5.39152Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M32.6341 6.46123C32.8587 6.40072 33.0512 6.25542 33.1709 6.05591C33.2906 5.8564 33.3282 5.61823 33.2759 5.39152L32.7196 2.86701C32.6741 2.6699 32.5663 2.49271 32.4121 2.36169C32.258 2.23067 32.0657 2.1528 31.8639 2.1396H31.6071C31.4842 2.11705 31.3577 2.12474 31.2384 2.16201C31.1191 2.19928 31.0107 2.26502 30.9225 2.35354H30.7086C30.5554 2.48927 30.4484 2.66936 30.4024 2.86876C30.3564 3.06815 30.3737 3.27693 30.4519 3.46604L31.3076 5.99056C31.3515 6.11858 31.4237 6.23504 31.5188 6.33124C31.614 6.42745 31.7297 6.50092 31.8572 6.54618C31.9847 6.59143 32.1208 6.60729 32.2553 6.59258C32.3899 6.57788 32.5193 6.53297 32.6341 6.46123Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />

                    <path
                        d="M28.7404 7.95849C28.9447 7.86486 29.1088 7.70125 29.2029 7.49722C29.2971 7.29318 29.3152 7.06219 29.2539 6.84599L28.4409 4.40705C28.3824 4.20775 28.2566 4.03488 28.0849 3.91787C27.9133 3.80085 27.7065 3.74691 27.4995 3.76522H27.2428C27.1211 3.76906 27.0015 3.79759 26.8911 3.84907C26.7808 3.90056 26.6821 3.97394 26.601 4.06474C26.4888 4.22853 26.4287 4.42244 26.4287 4.62099C26.4287 4.81954 26.4888 5.01345 26.601 5.17724L27.8846 7.40224C27.954 7.57082 28.073 7.71431 28.2259 7.81366C28.3787 7.91302 28.5582 7.96353 28.7404 7.95849Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M25.0178 9.88429C25.2058 9.7556 25.3456 9.56804 25.4153 9.35115C25.485 9.13426 25.4807 8.90034 25.4029 8.68621L24.3331 6.33285C24.2569 6.14426 24.116 5.989 23.9356 5.89491C23.7553 5.80082 23.5473 5.77404 23.349 5.81939H23.0923C22.9711 5.8183 22.8517 5.84942 22.7465 5.90956C22.6412 5.9697 22.5538 6.05671 22.4932 6.16169H22.2793C22.1544 6.32019 22.0864 6.51613 22.0864 6.71794C22.0864 6.91976 22.1544 7.1157 22.2793 7.27419L23.7769 9.37083C23.8802 9.59764 24.0658 9.77679 24.2961 9.87209C24.5265 9.96739 24.7844 9.97175 25.0178 9.88429Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M21.5091 12.1521C21.6878 12.0159 21.812 11.8203 21.859 11.6006C21.9061 11.3808 21.873 11.1515 21.7658 10.954L20.4821 8.72903C20.3775 8.54657 20.2172 8.40242 20.0247 8.31772C19.8322 8.23301 19.6176 8.21221 19.4124 8.25836H19.1557C19.0369 8.2836 18.9253 8.33514 18.829 8.40919C18.7328 8.48323 18.6543 8.5779 18.5994 8.68624C18.4494 8.75074 18.3197 8.85491 18.2244 8.98758C18.1291 9.12024 18.0718 9.27637 18.0586 9.43918C18.0454 9.60199 18.0768 9.76531 18.1495 9.9116C18.2222 10.0579 18.3334 10.1816 18.4711 10.2694L20.1826 12.1949C20.3645 12.3644 20.6061 12.455 20.8547 12.447C21.1032 12.439 21.3384 12.333 21.5091 12.1521Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M18.4712 14.805C18.6136 14.6296 18.6914 14.4105 18.6914 14.1845C18.6914 13.9586 18.6136 13.7395 18.4712 13.5641L16.9308 11.4675C16.8173 11.2985 16.6472 11.1755 16.4511 11.1206C16.2551 11.0657 16.0459 11.0825 15.8611 11.168H15.6044C15.3793 11.2534 15.1956 11.4219 15.0909 11.6386V11.8954C15.0043 12.0724 14.9783 12.2731 15.017 12.4664C15.0556 12.6597 15.1568 12.8349 15.3048 12.9651L17.1875 14.7194C17.3598 14.8778 17.5809 14.9726 17.8143 14.9881C18.0477 15.0037 18.2795 14.9391 18.4712 14.805Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M15.3049 17.7572C15.4554 17.5855 15.5383 17.365 15.5383 17.1368C15.5383 16.9085 15.4554 16.688 15.3049 16.5163L13.5934 14.5909C13.4526 14.4449 13.2701 14.346 13.071 14.3077C12.8719 14.2695 12.6658 14.2936 12.4809 14.3769H12.2241C12.026 14.4929 11.8746 14.6745 11.7962 14.8904V15.1471C11.7308 15.3385 11.7287 15.5459 11.7904 15.7386C11.852 15.9313 11.9741 16.099 12.1386 16.2168L14.1924 17.7572C14.3582 17.8641 14.5514 17.9209 14.7486 17.9209C14.9459 17.9209 15.1391 17.8641 15.3049 17.7572Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M12.652 21.0086C12.8025 20.8369 12.8854 20.6164 12.8854 20.3881C12.8854 20.1599 12.8025 19.9394 12.652 19.7677L10.7265 18.0562C10.568 17.9312 10.3721 17.8633 10.1703 17.8633C9.96847 17.8633 9.77253 17.9312 9.61403 18.0562H9.3573C9.16979 18.1928 9.03405 18.3888 8.9722 18.6124V18.9119C8.93161 19.1063 8.95076 19.3084 9.02712 19.4916C9.10349 19.6749 9.2335 19.8308 9.40009 19.9389L11.6251 21.2225C11.799 21.2873 11.9877 21.3016 12.1694 21.2638C12.3511 21.2259 12.5184 21.1374 12.652 21.0086Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M10.3841 24.5173C10.4943 24.3133 10.529 24.077 10.482 23.85C10.435 23.623 10.3094 23.4199 10.1274 23.2764L8.03072 21.7788C7.87222 21.6539 7.67628 21.5859 7.47447 21.5859C7.27265 21.5859 7.07671 21.6539 6.91822 21.7788H6.66148C6.47568 21.928 6.35335 22.142 6.31918 22.3778V22.6346C6.29283 22.8367 6.33282 23.042 6.43313 23.2194C6.53344 23.3969 6.68865 23.537 6.87543 23.6187L9.22879 24.6884C9.4144 24.787 9.62669 24.8235 9.83457 24.7927C10.0425 24.7619 10.235 24.6654 10.3841 24.5173Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M8.45851 28.24C8.56398 28.0297 8.58273 27.7863 8.51072 27.5623C8.43871 27.3383 8.2817 27.1515 8.07342 27.042L5.84842 25.7583C5.68992 25.6334 5.49398 25.5654 5.29217 25.5654C5.09035 25.5654 4.89441 25.6334 4.73592 25.7583C4.65084 25.8416 4.58407 25.9416 4.53986 26.0521C4.49566 26.1627 4.47499 26.2812 4.47919 26.4001V26.6996C4.46057 26.8955 4.50997 27.0917 4.61907 27.2553C4.72817 27.419 4.89032 27.5401 5.07823 27.5982L7.55996 28.4112C7.71193 28.4614 7.8742 28.472 8.03142 28.4421C8.18865 28.4121 8.33564 28.3426 8.45851 28.24Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M6.96109 32.1336C7.0019 32.0269 7.02095 31.9132 7.01713 31.7991C7.01331 31.685 6.9867 31.5728 6.93885 31.4691C6.891 31.3654 6.82289 31.2724 6.73853 31.1954C6.65416 31.1185 6.55525 31.0592 6.44763 31.0211L4.09426 29.9514C3.91271 29.8696 3.70914 29.8504 3.51552 29.8969C3.32191 29.9434 3.14921 30.0528 3.02455 30.2081V30.422C2.97477 30.5293 2.94897 30.6461 2.94897 30.7643C2.94897 30.8826 2.97477 30.9994 3.02455 31.1066V31.3634C3.03775 31.5652 3.11563 31.7575 3.24664 31.9116C3.37766 32.0658 3.55485 32.1736 3.75196 32.2191L6.27647 32.7754C6.43865 32.7419 6.58841 32.6642 6.70922 32.5509C6.83003 32.4377 6.9172 32.2932 6.96109 32.1336Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.89138 36.1987C5.9437 35.972 5.90605 35.7338 5.78634 35.5343C5.66663 35.3348 5.47421 35.1895 5.24955 35.129L2.81061 34.316C2.61437 34.2616 2.4061 34.2695 2.21452 34.3384C2.02293 34.4074 1.85745 34.5341 1.74089 34.7011V34.915C1.65896 35.1359 1.65896 35.3788 1.74089 35.5996V35.942C1.78644 36.1391 1.89427 36.3162 2.04841 36.4473C2.20255 36.5783 2.39479 36.6562 2.59666 36.6694L5.12118 36.9689C5.31355 36.9362 5.491 36.8445 5.62898 36.7065C5.76696 36.5685 5.85866 36.3911 5.89138 36.1987Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.24948 40.2208C5.2637 39.991 5.19318 39.7639 5.05124 39.5825C4.90931 39.4011 4.70582 39.2781 4.47928 39.2367L1.99755 38.6804C1.79372 38.6408 1.58249 38.6696 1.39675 38.7625C1.21101 38.8553 1.0612 39.007 0.97063 39.1939C0.888696 39.4147 0.888696 39.6577 0.97063 39.8785V40.178C1.03198 40.3706 1.15114 40.5396 1.3119 40.6621C1.47266 40.7846 1.66724 40.8546 1.86919 40.8626H4.4365C4.61828 40.8418 4.79065 40.7707 4.93426 40.6573C5.07787 40.544 5.18702 40.3928 5.24948 40.2208Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M4.99277 44.4997C4.99491 44.2708 4.91354 44.0489 4.76389 43.8757C4.61425 43.7024 4.40659 43.5896 4.17979 43.5584L1.61248 43.3017C1.41102 43.2808 1.20843 43.3286 1.03756 43.4374C0.866692 43.5461 0.737568 43.7094 0.671133 43.9007V44.1574C0.585208 44.3775 0.585208 44.6219 0.671133 44.842V45.0988C0.737568 45.2901 0.866692 45.4534 1.03756 45.5621C1.20843 45.6708 1.41102 45.7187 1.61248 45.6978L4.17979 45.4411C4.40659 45.4099 4.61425 45.2971 4.76389 45.1238C4.91354 44.9505 4.99491 44.7287 4.99277 44.4997Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.24942 48.7786C5.21918 48.5476 5.10799 48.3349 4.93562 48.1782C4.76325 48.0215 4.54088 47.931 4.30808 47.9229H1.74077C1.53882 47.9309 1.34424 48.0009 1.18348 48.1234C1.02272 48.2459 0.903563 48.4149 0.842212 48.6075V48.907C0.760278 49.1278 0.760278 49.3708 0.842212 49.5916C0.932779 49.7785 1.08259 49.9302 1.26833 50.023C1.45407 50.1159 1.6653 50.1447 1.86913 50.1051L4.35087 49.5488C4.56615 49.5426 4.77279 49.4628 4.93632 49.3226C5.09984 49.1824 5.21035 48.9904 5.24942 48.7786Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M5.89122 52.8011C5.83997 52.5745 5.70675 52.3749 5.51717 52.2406C5.32759 52.1063 5.09505 52.0468 4.86429 52.0737L2.33977 52.3732C2.12174 52.3552 1.90423 52.4137 1.72463 52.5386C1.54504 52.6635 1.41456 52.8471 1.35564 53.0578V53.3146C1.27371 53.5354 1.27371 53.7783 1.35564 53.9992V54.2131C1.47219 54.3801 1.63767 54.5068 1.82926 54.5758C2.02085 54.6447 2.22912 54.6526 2.42535 54.5982L4.86429 53.828C5.00077 53.8341 5.13701 53.8117 5.26435 53.7622C5.39169 53.7128 5.50734 53.6373 5.60394 53.5407C5.70054 53.4441 5.77597 53.3285 5.82543 53.2012C5.87488 53.0738 5.89729 52.9376 5.89122 52.8011Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M6.96093 56.866C6.90042 56.6414 6.75512 56.4489 6.55561 56.3292C6.35609 56.2095 6.11793 56.1719 5.89122 56.2242L3.3667 56.7804C3.1696 56.826 2.99241 56.9338 2.86139 57.088C2.73037 57.2421 2.65249 57.4343 2.6393 57.6362V57.8929C2.58951 58.0002 2.56372 58.117 2.56372 58.2352C2.56372 58.3535 2.58951 58.4703 2.6393 58.5776V58.7915C2.76396 58.9468 2.93666 59.0562 3.13027 59.1027C3.32389 59.1492 3.52746 59.13 3.70901 59.0482L6.06238 57.9785C6.2035 57.9924 6.34584 57.9709 6.47662 57.9161C6.6074 57.8613 6.72252 57.7749 6.81162 57.6645C6.90071 57.5542 6.961 57.4235 6.98707 57.2841C7.01313 57.1447 7.00415 57.0011 6.96093 56.866Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M8.45859 60.7591C8.36496 60.5548 8.20135 60.3908 7.99731 60.2966C7.79328 60.2024 7.56228 60.1844 7.34608 60.2457L4.86435 61.0586C4.67645 61.1168 4.51431 61.2378 4.4052 61.4015C4.2961 61.5651 4.2467 61.7614 4.26532 61.9572V62.2567C4.26112 62.3757 4.28179 62.4942 4.32599 62.6047C4.3702 62.7152 4.43697 62.8153 4.52205 62.8985C4.68054 63.0235 4.87648 63.0914 5.0783 63.0914C5.28011 63.0914 5.47605 63.0235 5.63455 62.8985L7.85955 61.6149C8.03613 61.5525 8.18879 61.4364 8.2962 61.283C8.4036 61.1296 8.46038 60.9464 8.45859 60.7591Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M10.3841 64.4817C10.2609 64.2877 10.0735 64.1429 9.8547 64.0726C9.63587 64.0023 9.39931 64.0107 9.18607 64.0966L6.8327 65.1663C6.64592 65.248 6.49071 65.3881 6.3904 65.5656C6.29009 65.743 6.2501 65.9483 6.27645 66.1504V66.4072C6.31063 66.643 6.43296 66.857 6.61876 67.0062H6.87549C7.03399 67.1311 7.22993 67.1991 7.43174 67.1991C7.63356 67.1991 7.8295 67.1311 7.98799 67.0062L10.0846 65.5086C10.2283 65.3845 10.333 65.2216 10.3861 65.0394C10.4392 64.8572 10.4386 64.6635 10.3841 64.4817Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M12.6518 67.9903C12.5084 67.8082 12.3052 67.6826 12.0782 67.6356C11.8512 67.5887 11.6149 67.6234 11.411 67.7336L9.18598 69.0172C9.01939 69.1252 8.88938 69.2811 8.81301 69.4644C8.73664 69.6477 8.7175 69.8498 8.75809 70.0441V70.3437C8.81994 70.5672 8.95568 70.7633 9.14319 70.8999H9.39992C9.55493 71.0325 9.7522 71.1053 9.95617 71.1053C10.1601 71.1053 10.3574 71.0325 10.5124 70.8999L12.4379 69.1884C12.6096 69.0463 12.7253 68.8479 12.7645 68.6285C12.8037 68.4092 12.7638 68.183 12.6518 67.9903Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M15.3047 71.2429C15.1225 71.097 14.8962 71.0176 14.6628 71.0176C14.4295 71.0176 14.2032 71.097 14.021 71.2429L11.9672 72.7832C11.8027 72.9011 11.6807 73.0688 11.619 73.2615C11.5573 73.4541 11.5594 73.6615 11.6249 73.853V74.1097C11.7032 74.3255 11.8546 74.5072 12.0527 74.6232H12.3095C12.4944 74.7065 12.7005 74.7306 12.8996 74.6923C13.0987 74.654 13.2812 74.5551 13.422 74.4092L15.1335 72.4837C15.3093 72.3339 15.4239 72.1247 15.4554 71.8959C15.487 71.6671 15.4333 71.4347 15.3047 71.2429Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M18.4712 74.195C18.2891 74.0492 18.0627 73.9697 17.8294 73.9697C17.596 73.9697 17.3697 74.0492 17.1875 74.195L15.3048 75.9493C15.1568 76.0795 15.0556 76.2547 15.017 76.448C14.9783 76.6413 15.0043 76.842 15.0909 77.019V77.2758C15.1956 77.4925 15.3793 77.661 15.6044 77.7465H15.8611C16.0459 77.8319 16.2551 77.8487 16.4511 77.7938C16.6472 77.7389 16.8173 77.6159 16.9308 77.4469L18.4712 75.3503C18.593 75.1823 18.6585 74.9801 18.6585 74.7727C18.6585 74.5652 18.593 74.363 18.4712 74.195Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M21.5092 76.8477C21.3375 76.6972 21.117 76.6143 20.8887 76.6143C20.6605 76.6143 20.44 76.6972 20.2683 76.8477L18.4712 78.7303C18.3463 78.8888 18.2783 79.0848 18.2783 79.2866C18.2783 79.4884 18.3463 79.6844 18.4712 79.8428V80.0568C18.5261 80.1651 18.6045 80.2598 18.7008 80.3338C18.797 80.4079 18.9087 80.4594 19.0274 80.4847H19.2842C19.4894 80.5308 19.704 80.51 19.8965 80.4253C20.089 80.3406 20.2493 80.1965 20.3539 80.014L21.6375 77.789C21.7015 77.6348 21.7234 77.4664 21.7008 77.3009C21.6783 77.1355 21.6121 76.9791 21.5092 76.8477Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M25.0178 79.1154C24.8138 79.0052 24.5775 78.9705 24.3505 79.0175C24.1235 79.0644 23.9204 79.19 23.7769 79.3721L22.2793 81.4688C22.1544 81.6273 22.0864 81.8232 22.0864 82.025C22.0864 82.2268 22.1544 82.4228 22.2793 82.5813L22.4932 82.7952C22.5538 82.9002 22.6412 82.9872 22.7465 83.0473C22.8517 83.1075 22.9711 83.1386 23.0923 83.1375H23.349C23.5473 83.1828 23.7553 83.1561 23.9356 83.062C24.116 82.9679 24.2569 82.8126 24.3331 82.624L25.4029 80.2707C25.4703 80.0622 25.4693 79.8377 25.4 79.6298C25.3307 79.4219 25.1968 79.2417 25.0178 79.1154Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M28.7404 81.0406C28.5301 80.9352 28.2867 80.9164 28.0627 80.9884C27.8387 81.0604 27.6519 81.2174 27.5424 81.4257L26.2587 83.6507C26.1465 83.8145 26.0864 84.0084 26.0864 84.207C26.0864 84.4055 26.1465 84.5994 26.2587 84.7632C26.3398 84.854 26.4385 84.9274 26.5489 84.9789C26.6592 85.0304 26.7788 85.0589 26.9005 85.0627H27.1573C27.3642 85.081 27.571 85.0271 27.7427 84.9101C27.9143 84.7931 28.0401 84.6202 28.0986 84.4209L28.9116 81.982C28.9698 81.8241 28.9846 81.6535 28.9545 81.4879C28.9244 81.3224 28.8505 81.1679 28.7404 81.0406Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M32.6341 82.539C32.5193 82.4673 32.3899 82.4224 32.2554 82.4077C32.1208 82.393 31.9847 82.4088 31.8572 82.4541C31.7297 82.4993 31.614 82.5728 31.5189 82.669C31.4237 82.7652 31.3515 82.8817 31.3076 83.0097L30.2807 85.3631C30.2025 85.5522 30.1852 85.7609 30.2313 85.9603C30.2773 86.1597 30.3843 86.3398 30.5374 86.4756H30.7514C30.8578 86.5283 30.9749 86.5558 31.0937 86.5558C31.2125 86.5558 31.3296 86.5283 31.436 86.4756H31.6927C31.8946 86.4624 32.0868 86.3845 32.241 86.2535C32.3951 86.1224 32.503 85.9453 32.5485 85.7481L33.1048 83.2236C33.0872 83.0801 33.0352 82.9429 32.9533 82.8237C32.8713 82.7045 32.7618 82.6068 32.6341 82.539Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M36.6989 83.6081C36.4674 83.5641 36.2277 83.6047 36.0236 83.7225C35.8194 83.8403 35.6643 84.0275 35.5864 84.2499L34.8163 86.6889C34.7462 86.8733 34.742 87.0762 34.8044 87.2633C34.8668 87.4505 34.9919 87.6103 35.1586 87.7158H35.4153C35.639 87.78 35.8762 87.78 36.0999 87.7158H36.3566C36.5613 87.6954 36.7517 87.6021 36.8932 87.4528C37.0346 87.3035 37.1176 87.1083 37.1268 86.9028L37.4264 84.3783C37.3964 84.1917 37.311 84.0185 37.1813 83.8812C37.0515 83.7438 36.8835 83.6486 36.6989 83.6081Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M40.8068 84.2498C40.5812 84.2243 40.3543 84.2851 40.1718 84.4201C39.9892 84.555 39.8645 84.7541 39.8227 84.9772L39.2664 87.5017C39.2299 87.7005 39.2555 87.9057 39.3397 88.0895C39.4239 88.2732 39.5627 88.4266 39.7371 88.5287H39.9938C40.2147 88.6106 40.4576 88.6106 40.6784 88.5287H40.978C41.1674 88.4622 41.3332 88.3417 41.4549 88.182C41.5766 88.0223 41.6488 87.8304 41.6626 87.6301V85.0628C41.627 84.853 41.5244 84.6604 41.3701 84.5138C41.2158 84.3672 41.0182 84.2746 40.8068 84.2498Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M45 84.4639C44.7672 84.472 44.5449 84.5625 44.3725 84.7192C44.2001 84.8759 44.0889 85.0887 44.0587 85.3196L43.802 87.8869C43.7884 88.0872 43.8392 88.2866 43.947 88.4559C44.0547 88.6253 44.2138 88.7558 44.401 88.8283H44.6577C44.8778 88.9142 45.1222 88.9142 45.3423 88.8283H45.5991C45.7862 88.7558 45.9453 88.6253 46.0531 88.4559C46.1609 88.2866 46.2117 88.0872 46.1981 87.8869L45.8986 85.3196C45.878 85.0923 45.7756 84.8802 45.6103 84.7227C45.445 84.5653 45.2281 84.4733 45 84.4639Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M49.1504 84.2505C48.9236 84.2817 48.716 84.3945 48.5663 84.5678C48.4167 84.7411 48.3353 84.9629 48.3374 85.1919V87.7592C48.3455 87.9611 48.4155 88.1557 48.538 88.3165C48.6605 88.4772 48.8295 88.5964 49.0221 88.6577H49.2788C49.4989 88.7437 49.7433 88.7437 49.9634 88.6577H50.2201C50.4042 88.5662 50.5514 88.4144 50.637 88.2275C50.7227 88.0406 50.7417 87.83 50.6908 87.6308L50.1773 85.1063C50.1727 84.9803 50.1415 84.8567 50.0859 84.7435C50.0303 84.6304 49.9515 84.5302 49.8547 84.4495C49.7578 84.3688 49.645 84.3093 49.5237 84.2751C49.4024 84.2408 49.2752 84.2324 49.1504 84.2505Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M53.3008 83.6084C53.0693 83.6502 52.8633 83.7812 52.7273 83.9733C52.5913 84.1653 52.5361 84.403 52.5734 84.6353L52.7018 87.2882C52.7192 87.4909 52.8048 87.6817 52.9447 87.8294C53.0847 87.977 53.2705 88.0729 53.472 88.1012H53.7715C53.996 88.1592 54.2316 88.1592 54.4561 88.1012C54.6247 87.9965 54.7538 87.8391 54.8235 87.6533C54.8932 87.4675 54.8994 87.2639 54.8412 87.0743L54.0282 84.6353C54.0656 84.403 54.0103 84.1653 53.8743 83.9733C53.7383 83.7812 53.5324 83.6502 53.3008 83.6084Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M57.3658 82.5387C57.1411 82.5992 56.9487 82.7445 56.829 82.944C56.7093 83.1435 56.6716 83.3817 56.7239 83.6084L57.2802 86.1329C57.3234 86.3235 57.4249 86.496 57.5706 86.6264C57.7163 86.7567 57.8989 86.8385 58.0932 86.8603H58.3927C58.4926 86.9094 58.6023 86.9349 58.7136 86.9349C58.8249 86.9349 58.9347 86.9094 59.0345 86.8603H59.2485C59.4072 86.7286 59.5184 86.5484 59.5648 86.3474C59.6111 86.1464 59.5902 85.9357 59.5052 85.7478L58.4783 83.0093C58.3928 82.7997 58.2278 82.6324 58.0192 82.5442C57.8107 82.456 57.5758 82.454 57.3658 82.5387Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M61.2596 81.0415C61.0492 81.1276 60.8792 81.2903 60.7839 81.4967C60.6886 81.7032 60.6751 81.938 60.7462 82.154L61.5592 84.593C61.6006 84.7934 61.7162 84.9709 61.8827 85.0899C62.0493 85.2088 62.2546 85.2606 62.4577 85.2348H62.7572C63.0009 85.2181 63.2297 85.1113 63.3991 84.9353C63.524 84.7768 63.5919 84.5809 63.5919 84.379C63.5919 84.1772 63.524 83.9813 63.3991 83.8228L62.1582 81.5978C62.0864 81.422 61.9608 81.2735 61.7993 81.1736C61.6379 81.0737 61.449 81.0274 61.2596 81.0415Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M64.9821 79.1155C64.7738 79.225 64.6168 79.4118 64.5448 79.6358C64.4728 79.8599 64.4916 80.1032 64.597 80.3136L65.6667 82.6669C65.743 82.8555 65.8839 83.0108 66.0643 83.1049C66.2446 83.1989 66.4526 83.2257 66.6509 83.1804H66.9076C67.0288 83.1815 67.1481 83.1503 67.2534 83.0902C67.3586 83.0301 67.446 82.943 67.5066 82.8381V82.6241C67.6189 82.4603 67.6789 82.2664 67.6789 82.0679C67.6789 81.8693 67.6189 81.6754 67.5066 81.5116L65.9663 79.415C65.849 79.275 65.6935 79.1721 65.5187 79.1189C65.344 79.0657 65.1575 79.0645 64.9821 79.1155Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M68.4909 76.848C68.3044 76.9782 68.1743 77.1742 68.1266 77.3965C68.079 77.6189 68.1174 77.851 68.2341 78.0461L69.5178 80.2711C69.615 80.4501 69.7677 80.5927 69.9528 80.6776C70.138 80.7625 70.3457 80.785 70.5447 80.7418H70.8442C70.9609 80.7114 71.0703 80.6579 71.1659 80.5844C71.2614 80.5109 71.3412 80.4189 71.4005 80.3139V80.1C71.5254 79.9415 71.5934 79.7455 71.5934 79.5437C71.5934 79.3419 71.5254 79.146 71.4005 78.9875L69.689 77.062C69.5469 76.8903 69.3485 76.7745 69.1291 76.7354C68.9098 76.6962 68.6836 76.7361 68.4909 76.848Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M71.7427 74.1951C71.5922 74.3667 71.5093 74.5872 71.5093 74.8155C71.5093 75.0438 71.5922 75.2642 71.7427 75.4359L73.2403 77.5326C73.3538 77.7016 73.5239 77.8246 73.7199 77.8795C73.916 77.9344 74.1252 77.9176 74.31 77.8321H74.6095C74.8284 77.7369 75.0091 77.5712 75.123 77.3614V77.1047C75.2376 76.9499 75.2995 76.7624 75.2995 76.5698C75.2995 76.3772 75.2376 76.1897 75.123 76.035L73.1975 74.2806C73.1114 74.1738 73.0038 74.0861 72.8818 74.0233C72.7598 73.9604 72.626 73.9238 72.489 73.9158C72.352 73.9077 72.2148 73.9284 72.0863 73.9765C71.9578 74.0246 71.8407 74.099 71.7427 74.1951Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M74.6953 71.242C74.5529 71.4174 74.4751 71.6365 74.4751 71.8625C74.4751 72.0884 74.5529 72.3075 74.6953 72.4829L76.4068 74.4084C76.5477 74.5543 76.7301 74.6532 76.9292 74.6915C77.1284 74.7298 77.3345 74.7056 77.5193 74.6223H77.7761C77.9859 74.5085 78.1515 74.3277 78.2467 74.1089V73.8521C78.3322 73.6674 78.349 73.4581 78.2941 73.2621C78.2392 73.066 78.1162 72.896 77.9472 72.7824L75.8506 71.242C75.6826 71.1203 75.4804 71.0547 75.2729 71.0547C75.0655 71.0547 74.8633 71.1203 74.6953 71.242Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M77.3482 67.9909C77.1886 68.158 77.0996 68.3802 77.0996 68.6113C77.0996 68.8424 77.1886 69.0646 77.3482 69.2317L79.2309 70.9433C79.3927 71.0791 79.5972 71.1535 79.8085 71.1535C80.0198 71.1535 80.2243 71.0791 80.3862 70.9433H80.6001C80.799 70.8089 80.9491 70.6137 81.028 70.387V70.1731C81.0908 69.979 81.0822 69.7689 81.0037 69.5806C80.9252 69.3923 80.7821 69.2382 80.6001 69.1461L78.3751 67.8625C78.2101 67.7812 78.0249 67.7501 77.8424 67.7729C77.6599 67.7957 77.4881 67.8715 77.3482 67.9909Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M79.6158 64.4822C79.4965 64.6839 79.4567 64.9228 79.5041 65.1523C79.5516 65.3818 79.683 65.5853 79.8726 65.7231L81.9692 67.2207C82.1277 67.3456 82.3236 67.4135 82.5254 67.4135C82.7273 67.4135 82.9232 67.3456 83.0817 67.2207C83.1814 67.1543 83.2642 67.0656 83.3237 66.9616C83.3831 66.8576 83.4174 66.7412 83.424 66.6216V66.3649C83.461 66.1671 83.4305 65.9625 83.3374 65.7841C83.2443 65.6056 83.094 65.4636 82.9105 65.3808L80.5572 64.3111C80.3993 64.2528 80.2287 64.238 80.0631 64.2681C79.8976 64.2982 79.7431 64.3721 79.6158 64.4822Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M81.4985 60.7596C81.4207 60.9737 81.4163 61.2076 81.486 61.4245C81.5557 61.6414 81.6956 61.829 81.8836 61.9577L84.1086 63.2413C84.2766 63.3631 84.4788 63.4287 84.6862 63.4287C84.8937 63.4287 85.0959 63.3631 85.2639 63.2413C85.3547 63.1602 85.4281 63.0615 85.4796 62.9512C85.531 62.8408 85.5596 62.7212 85.5634 62.5995V62.3C85.5586 62.1032 85.4948 61.9124 85.3804 61.7522C85.266 61.592 85.1062 61.4698 84.9216 61.4014L82.6538 60.2461C82.4316 60.1824 82.194 60.1989 81.9828 60.2928C81.7716 60.3867 81.6001 60.552 81.4985 60.7596Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M82.9961 56.8656C82.9349 57.0818 82.9529 57.3128 83.0471 57.5169C83.1413 57.7209 83.3053 57.8845 83.5096 57.9781L85.863 59.0478C86.0521 59.126 86.2609 59.1433 86.4603 59.0973C86.6596 59.0513 86.8397 58.9443 86.9755 58.7911V58.5772C87.1267 58.3821 87.2027 58.1391 87.1894 57.8926V57.6358C87.1738 57.4281 87.0898 57.2313 86.9504 57.0765C86.811 56.9216 86.6242 56.8174 86.4192 56.7801L83.9375 56.2238C83.7334 56.22 83.5332 56.2797 83.3645 56.3946C83.1958 56.5096 83.0671 56.6742 82.9961 56.8656Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.1087 52.8003C84.0528 53.027 84.0831 53.2664 84.1938 53.472C84.3045 53.6776 84.4877 53.8347 84.7077 53.9128L87.1467 54.683C87.3426 54.7442 87.5531 54.7397 87.7462 54.6702C87.9393 54.6006 88.1044 54.4699 88.2164 54.2979V54.0839C88.3023 53.8638 88.3023 53.6194 88.2164 53.3993V53.057C88.196 52.8524 88.1026 52.6619 87.9534 52.5205C87.8041 52.379 87.6088 52.2961 87.4034 52.2868L84.8361 51.9873C84.6424 52.0243 84.4663 52.1239 84.3349 52.2708C84.2035 52.4177 84.124 52.6037 84.1087 52.8003Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.7506 48.7785C84.7251 49.0041 84.7859 49.231 84.9208 49.4136C85.0558 49.5961 85.2548 49.7209 85.478 49.7627L88.0025 50.3189C88.2059 50.3516 88.4143 50.3196 88.5986 50.2275C88.7828 50.1354 88.9335 49.9878 89.0294 49.8055C89.1153 49.5853 89.1153 49.341 89.0294 49.1209V48.8213C88.9681 48.6288 88.8489 48.4598 88.6881 48.3373C88.5274 48.2148 88.3328 48.1448 88.1308 48.1367H85.5635C85.3818 48.1576 85.2094 48.2286 85.0658 48.342C84.9222 48.4554 84.813 48.6066 84.7506 48.7785Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.9644 44.4999C84.9725 44.7327 85.063 44.9551 85.2197 45.1275C85.3764 45.2999 85.5891 45.411 85.8201 45.4413L88.3446 45.698C88.5508 45.7142 88.7569 45.6651 88.9335 45.5576C89.1102 45.45 89.2485 45.2896 89.3288 45.099V44.8422C89.4147 44.6221 89.4147 44.3777 89.3288 44.1576V43.9009C89.2485 43.7103 89.1102 43.5499 88.9335 43.4423C88.7569 43.3348 88.5508 43.2856 88.3446 43.3019L85.8201 43.5586C85.5891 43.5888 85.3764 43.7 85.2197 43.8724C85.063 44.0448 84.9725 44.2671 84.9644 44.4999Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.7505 40.2213C84.7807 40.4522 84.8919 40.665 85.0643 40.8217C85.2367 40.9784 85.459 41.0689 85.6918 41.077H88.2591C88.4611 41.069 88.6557 40.999 88.8164 40.8765C88.9772 40.754 89.0963 40.585 89.1577 40.3924V40.2213C89.2436 40.0011 89.2436 39.7568 89.1577 39.5366C89.0618 39.3544 88.9111 39.2068 88.7269 39.1146C88.5426 39.0225 88.3341 38.9905 88.1308 39.0232L85.6062 39.5794C85.4137 39.5806 85.2265 39.6436 85.0724 39.7591C84.9184 39.8747 84.8055 40.0367 84.7505 40.2213Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M84.1086 36.199C84.1504 36.4221 84.2751 36.6211 84.4577 36.7561C84.6402 36.891 84.8671 36.9519 85.0927 36.9264L87.66 36.6268C87.859 36.6191 88.049 36.5422 88.1974 36.4095C88.3458 36.2767 88.4433 36.0963 88.473 35.8994V35.5999C88.5589 35.3798 88.5589 35.1354 88.473 34.9153V34.7014C88.361 34.5293 88.1959 34.3986 88.0028 34.3291C87.8097 34.2596 87.5992 34.2551 87.4033 34.3163L84.9643 35.1292C84.8341 35.1331 84.7062 35.1652 84.5896 35.2233C84.4729 35.2815 84.3704 35.3643 84.2889 35.4661C84.2075 35.5679 84.1492 35.6861 84.1181 35.8127C84.0869 35.9392 84.0837 36.071 84.1086 36.199Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M82.9961 32.1338C83.0739 32.3563 83.229 32.5435 83.4332 32.6612C83.6374 32.779 83.877 32.8196 84.1086 32.7757L86.5903 32.2194C86.7953 32.1821 86.9821 32.0779 87.1215 31.923C87.2608 31.7681 87.3449 31.5714 87.3605 31.3636V31.1069C87.3738 30.8604 87.2978 30.6174 87.1465 30.4223V30.2083C87.0108 30.0552 86.8307 29.9482 86.6313 29.9022C86.4319 29.8561 86.2232 29.8734 86.034 29.9516L83.6807 31.0213C83.4448 31.0821 83.2417 31.232 83.114 31.4395C82.9863 31.647 82.9441 31.8958 82.9961 32.1338Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M81.4985 28.2399C81.6001 28.4475 81.7716 28.6128 81.9828 28.7066C82.1941 28.8005 82.4316 28.8171 82.6538 28.7533L85.0928 27.9403C85.2773 27.8719 85.4372 27.7498 85.5516 27.5896C85.666 27.4294 85.7298 27.2386 85.7346 27.0418V26.7423C85.7308 26.6206 85.7022 26.5009 85.6507 26.3906C85.5992 26.2803 85.5259 26.1816 85.4351 26.1005C85.2671 25.9787 85.0649 25.9131 84.8574 25.9131C84.6499 25.9131 84.4478 25.9787 84.2798 26.1005L82.0548 27.3841C81.8955 27.4659 81.7604 27.5879 81.6628 27.738C81.5652 27.8881 81.5086 28.0611 81.4985 28.2399Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M79.616 24.5172C79.733 24.7179 79.92 24.8683 80.1412 24.9394C80.3624 25.0105 80.602 24.9973 80.814 24.9023L83.1674 23.8326C83.3508 23.7498 83.5012 23.6077 83.5943 23.4293C83.6874 23.2508 83.7179 23.0463 83.6809 22.8485V22.5917C83.6743 22.4721 83.64 22.3557 83.5805 22.2517C83.5211 22.1477 83.4383 22.059 83.3386 21.9927C83.1801 21.8677 82.9841 21.7998 82.7823 21.7998C82.5805 21.7998 82.3846 21.8677 82.2261 21.9927L80.1294 23.4903C79.9426 23.5808 79.7909 23.7307 79.698 23.9164C79.6051 24.1021 79.5763 24.3134 79.616 24.5172Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M77.3481 21.0088C77.4843 21.1876 77.6799 21.3117 77.8996 21.3588C78.1194 21.4059 78.3487 21.3728 78.5462 21.2656L80.7712 19.9819C80.9532 19.8898 81.0963 19.7357 81.1748 19.5475C81.2532 19.3592 81.2618 19.1491 81.1991 18.955V18.8266C81.1202 18.5999 80.9701 18.4048 80.7712 18.2704H80.5572C80.3892 18.1486 80.1871 18.083 79.9796 18.083C79.7721 18.083 79.5699 18.1486 79.4019 18.2704L77.5192 19.9819C77.3876 20.1141 77.3005 20.284 77.2698 20.4681C77.2391 20.6521 77.2665 20.8411 77.3481 21.0088Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M74.6951 17.7572C74.8705 17.8996 75.0896 17.9774 75.3155 17.9774C75.5415 17.9774 75.7605 17.8996 75.9359 17.7572L78.0326 16.2168C78.2016 16.1033 78.3246 15.9332 78.3795 15.7372C78.4344 15.5411 78.4176 15.3319 78.3321 15.1471V14.8904C78.2369 14.6715 78.0712 14.4908 77.8614 14.3769H77.6047C77.4198 14.2936 77.2137 14.2695 77.0146 14.3077C76.8154 14.346 76.633 14.4449 76.4922 14.5909L74.7807 16.5163C74.6257 16.6817 74.5326 16.8955 74.517 17.1215C74.5014 17.3476 74.5643 17.5721 74.6951 17.7572Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M71.7428 14.8044C71.9182 14.9468 72.1373 15.0246 72.3632 15.0246C72.5892 15.0246 72.8083 14.9468 72.9837 14.8044L74.9092 13.05C75.0238 12.8953 75.0857 12.7078 75.0857 12.5152C75.0857 12.3226 75.0238 12.1351 74.9092 11.9803V11.7236C74.7953 11.5138 74.6146 11.3481 74.3957 11.2529H74.0962C73.9114 11.1674 73.7022 11.1506 73.5061 11.2055C73.31 11.2604 73.14 11.3834 73.0265 11.5524L71.5289 13.6491C71.4282 13.8374 71.3946 14.0543 71.4335 14.2642C71.4723 14.4741 71.5814 14.6646 71.7428 14.8044Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M68.4909 12.1514C68.6625 12.3018 68.883 12.3847 69.1113 12.3847C69.3396 12.3847 69.56 12.3018 69.7317 12.1514L71.4433 10.2259C71.5682 10.0674 71.6361 9.87144 71.6361 9.66962C71.6361 9.46781 71.5682 9.27187 71.4433 9.11337C71.384 9.00838 71.3042 8.91639 71.2086 8.84288C71.1131 8.76937 71.0037 8.71584 70.887 8.68549H70.5875C70.3884 8.64228 70.1807 8.6648 69.9956 8.74966C69.8104 8.83453 69.6578 8.97717 69.5606 9.15616L68.2769 11.3812C68.2507 11.5173 68.2564 11.6577 68.2935 11.7914C68.3306 11.925 68.3982 12.0482 68.4909 12.1514Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M64.9821 9.88341C65.1796 9.99067 65.4089 10.0237 65.6286 9.97666C65.8484 9.92957 66.044 9.80544 66.1802 9.62669L67.7206 7.53005C67.8328 7.36626 67.8929 7.17235 67.8929 6.9738C67.8929 6.77525 67.8328 6.58134 67.7206 6.41755C67.66 6.31257 67.5726 6.22556 67.4673 6.16542C67.3621 6.10528 67.2428 6.07416 67.1215 6.07524H66.8648C66.6665 6.0299 66.4585 6.05668 66.2782 6.15077C66.0979 6.24486 65.9569 6.40012 65.8807 6.58871L64.811 8.94207C64.7479 9.09917 64.7306 9.27087 64.7608 9.43741C64.7911 9.60396 64.8678 9.75856 64.9821 9.88341Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M61.2596 7.95828C61.47 8.06375 61.7133 8.0825 61.9373 8.01049C62.1614 7.93848 62.3482 7.78147 62.4577 7.57319L63.6986 5.34819C63.8235 5.18969 63.8914 4.99375 63.8914 4.79194C63.8914 4.59012 63.8235 4.39418 63.6986 4.23569C63.5292 4.05965 63.3004 3.95289 63.0567 3.93617H62.7572C62.5542 3.91038 62.3488 3.96214 62.1823 4.08112C62.0157 4.2001 61.9001 4.37754 61.8587 4.57799L61.0457 7.01694C60.9881 7.17794 60.9776 7.35203 61.0155 7.51878C61.0534 7.68552 61.1381 7.83799 61.2596 7.95828Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M57.3657 6.46078C57.5757 6.54544 57.8107 6.54345 58.0192 6.45523C58.2277 6.36702 58.3928 6.19977 58.4782 5.9901L59.5052 3.63674C59.5902 3.44879 59.6111 3.23812 59.5647 3.03712C59.5183 2.83613 59.4072 2.65593 59.2484 2.52424H59.0345C58.9528 2.43925 58.8518 2.37538 58.74 2.33811C58.6282 2.30084 58.509 2.2913 58.3927 2.3103H58.0931C57.8989 2.33212 57.7162 2.41388 57.5705 2.54423C57.4249 2.67458 57.3234 2.84705 57.2802 3.0377L56.7239 5.56222C56.7156 5.76206 56.7743 5.95896 56.8905 6.12172C57.0068 6.28448 57.174 6.4038 57.3657 6.46078Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M53.3009 5.39122C53.5214 5.43561 53.7505 5.39961 53.9467 5.28973C54.1429 5.17985 54.2933 5.00335 54.3707 4.79218L55.1836 2.31045C55.2418 2.12078 55.2356 1.91718 55.1659 1.73142C55.0963 1.54566 54.9671 1.38817 54.7985 1.28353C54.574 1.22555 54.3384 1.22555 54.1139 1.28353H53.8144C53.6087 1.20658 53.382 1.20762 53.177 1.28646C52.972 1.36529 52.803 1.51646 52.7019 1.71141L52.4452 4.23593C52.4053 4.36962 52.3971 4.51075 52.4213 4.64814C52.4454 4.78554 52.5012 4.91543 52.5842 5.02753C52.6673 5.13962 52.7753 5.23085 52.8997 5.29397C53.0241 5.3571 53.1614 5.39039 53.3009 5.39122Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                    <path
                        d="M49.1504 4.74954C49.3827 4.7869 49.6204 4.73164 49.8125 4.59563C50.0045 4.45961 50.1355 4.2537 50.1773 4.02214L50.6908 1.49762C50.7416 1.29843 50.7226 1.08783 50.637 0.900943C50.5513 0.714059 50.4042 0.562198 50.2201 0.470694H49.9634C49.7433 0.384769 49.4989 0.384769 49.2788 0.470694H49.022C48.8294 0.532045 48.6604 0.651197 48.5379 0.81196C48.4155 0.972724 48.3454 1.1673 48.3374 1.36925V3.93656C48.3641 4.14285 48.4583 4.3345 48.6054 4.48159C48.7524 4.62867 48.9441 4.72289 49.1504 4.74954Z"
                        fill="#F6E588"
                        fill-opacity="0.23"
                    />
                </svg>
                <span class="timer__num">01</span>
            </div>
            <div class="timer__caption">{{ $store.state.translation.timer.sec }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['id', 'end_at'],
        data() {
            return {
                end_tick: null
            }
        },
        methods: {
            doubleDigits (value) {
                return value < 10 ? `0${value}` : value
            },
            handleIconItems (svg, num) {
                const svgPaths = svg.querySelectorAll('path')

                svgPaths.forEach((path) => {
                    path.style.fill = '#FF00E5'
                    path.style.fillOpacity = '1'
                })

                for (let i = 0; i < svgPaths.length - num; i++) {
                    svgPaths[i].style.fill = '#F6E588'
                    svgPaths[i].style.fillOpacity = '0.23'
                }
            },
            tick () {
                // days
                const timerDaysNum = this.$el.querySelector('.timer__item--days .timer__num')
                const timerDaysIcon = this.$el.querySelector('.timer__item--days svg')

                // hours
                const timerHoursNum = this.$el.querySelector('.timer__item--hours .timer__num')
                const timerHoursIcon = this.$el.querySelector('.timer__item--hours svg')

                // mins
                const timerMinsNum = this.$el.querySelector('.timer__item--mins .timer__num')
                const timerMinsIcon = this.$el.querySelector('.timer__item--mins svg')

                // secs
                const timerSecsNum = this.$el.querySelector('.timer__item--secs .timer__num')
                const timerSecsIcon = this.$el.querySelector('.timer__item--secs svg')


                const expiresDate = new Date(this.$props.end_at)
                const currentDate = new Date()

                const difference = expiresDate - currentDate

                if (difference > 0) {
                    const parts = {
                        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                        mins: Math.floor((difference / 1000 / 60) % 60),
                        secs: Math.floor((difference / 1000) % 60),
                    }

                    // dispay days
                    timerDaysNum.innerText = this.doubleDigits(parts.days)
                    this.handleIconItems(timerDaysIcon, parts.days)


                    // dispay hours
                    timerHoursNum.innerText = this.doubleDigits(parts.hours)
                    this.handleIconItems(timerHoursIcon, parts.hours)


                    // dispay mins
                    timerMinsNum.innerText = this.doubleDigits(parts.mins)
                    this.handleIconItems(timerMinsIcon, parts.mins)


                    // dispay secs
                    timerSecsNum.innerText = this.doubleDigits(parts.secs)
                    this.handleIconItems(timerSecsIcon, parts.secs)
                } else {
                    // dispay days
                    timerDaysNum.innerText = this.doubleDigits(0)
                    this.handleIconItems(timerDaysIcon, 0)


                    // dispay hours
                    timerHoursNum.innerText = this.doubleDigits(0)
                    this.handleIconItems(timerHoursIcon, 0)


                    // dispay mins
                    timerMinsNum.innerText = this.doubleDigits(0)
                    this.handleIconItems(timerMinsIcon, 0)


                    // dispay secs
                    timerSecsNum.innerText = this.doubleDigits(0)
                    this.handleIconItems(timerSecsIcon, 0)

                    this.$emit('end_callback', this.$props.id)
                    clearInterval(this.end_tick)
                }
            }
        },
        mounted () {
            this.end_tick = setInterval(this.tick, 1000)
        }
    }
</script>