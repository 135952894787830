<template>
	<div class="modal modal--restore" id="restore">
        <div class="modal__inner">
            <button type="button" class="btn modal__btn-close" @click="togglePopup('restore')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2L12 12M22 22L12 12M12 12L22 2L2 22" stroke="#287962" stroke-width="4" />
                </svg>
            </button>
            <h2 class="modal__title">{{ $store.state.translation.modal.restore }}</h2>
            <div class="modal__content">
                <form class="form form--restore" v-on:submit.prevent="submitRestore">
                    <div class="form__group">
                        <input 
                            @input="errors.email = null"
                            v-model="data.email"
                            type="email" 
                            class="form__input" 
                            :placeholder="$store.state.translation.modal.email"
                            />
                        <span class="error_input" v-if="errors.email">{{ errors.email }}</span>
                    </div>
                    <div class="form__group">
                        <button type="submit" class="btn btn--fill-rose form__btn form__btn-submit" :disabled="loader_button">
                            <preloader v-if="loader_button"/>
                            {{ $store.state.translation.modal.restore }}
                        </button>
                    </div>

                    <button type="button" class="btn form__btn-login" @click="togglePopup('restore'), togglePopup('login')">{{ $store.state.translation.modal.auth }}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            loader_button: false,

            data: {
                email: null
            },
            errors: {
                email: false
            }
        }
    },
    methods: {
        async submitRestore () {
            if(!this.data.email) {
                this.errors.email = this.$store.state.translation.axios.filed_required
                this.$toast.error(this.$store.state.translation.modal.insert_email)
                return
            }

            this.loader_button = true
            
            this.$axios.post('/auth/restore', JSON.stringify(this.data))
            .then((response) => {
                this.loader_button = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        this.data.email = null

                        this.$toast.success(response.message)

                        this.setUser(response.user)

                        this.togglePopup('restore')

                        break
                    default:
                        this.$toast.error(this.$store.state.translation.axios.unknown_error)
                }
            }).catch((error) => {
                this.loader_button = false
                let response = error.response.data
                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                    if('field' in response) {
                        this.errors[response.field] = this.errors[response.field] = response.message
                    }
                }
            })
        }
    }
}
</script>